<template>
  <div class="">
    <!-- <iframe
      src="https://i-o-optimized-deployment-4ee84f.kb.eu-west-2.aws.cloud.es.io:9243/app/dashboards#/view/239f4960-a8e9-11eb-99f9-454acf4893dc?embed=true&_g=(filters%3A!()%2Cquery%3A(language%3Akuery%2Cquery%3A'')%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-1M%2Cto%3Anow))&show-time-filter=true"
      class="analytics-iframe"
    ></iframe> -->
    <iframe src="https://mercurylabs.retool.com/apps/Augmentive%20Mission%20Control" width="100%" height="800px"></iframe>
  </div>

</template>

<script>

export default {
  name: "Analytics",
}
</script>

<style>
  .analytics-iframe {
    height: 100vh;
    width: 100%;
  }
</style>
