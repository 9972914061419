import {
  PractitionersPromiseClient,
  AdminsPromiseClient
} from '@/plugins/grpc';

import {
  PractitionerGetByIdRequest,
  PractitionerListRequest,
  ApprovePractitionerRequest,
  PractitionerUpdateProfileRequest
} from "@mercury-labs1/augmentive-proto/practitioners.messages_pb";

import {
  SaveSortIndex,
  SaveSortIndexesRequest,
  UpdatePractitionerRequest,
  UpdateVisibilityRequest
} from "@mercury-labs1/augmentive-proto/admins.messages_pb";

import {VueAppWebServiceUrl} from "../../config";
import {get} from "lodash"
import {preparePagination} from "@/utils/preparePagination";

export async function getPractitionerByID(payload) {
  let req = new PractitionerGetByIdRequest();

  if (payload.id) {
    req.setId(payload.id)
  }

  return await PractitionersPromiseClient.getById(req, {}).then((res) => {
    return Promise.resolve(res.toObject())
  })
}

export async function getPractitionerList(payload) {
  let req = new PractitionerListRequest();

  let pagination = preparePagination(payload)
  if (pagination) {
    req.setPagination(pagination)
  }

  if (payload.searchFilter) {
    req.setSearchFilter(payload.searchFilter)
  }

  if (payload.createdAt) {
    req.setCreatedAt(payload.createdAt)
  }

  req.setShowAll(payload.show_all)
  console.log("GetPractitionersListRequest: ", payload)

  return await PractitionersPromiseClient.list(req, {}).then((res) => {
    console.log('res', res)
    res = res.toObject()
    console.log("GetPractitionerListResponse: ", res)
    return Promise.resolve(res)
  })
}

export async function setPractitionerProfile(payload) {
  let req = new PractitionerUpdateProfileRequest();

  console.log('payload', payload)
  console.log('req', req)

  if (payload.id) {
    req.setPractitionerId(payload.id)
  } else {
    console.error("Practitioner ID is required")
    return
  }

  if (payload.photo) {
    req.setPhoto(payload.photo)
  }

  if (payload.firstName) {
    req.setFirstName(payload.firstName)
  }

  if (payload.lastName) {
    req.setLastName(payload.lastName)
  }

  if (payload.gender) {
    req.setGender(payload.gender)
  }

  if (payload.honorific) {
    req.setHonorific(payload.honorific)
  }

  // if (payload.appointmentOptionsList) {
  //   req.setAppointmentOptionsList(payload.appointmentOptionsList)
  // }

  if (payload.languageList) {
    req.setLanguageList(payload.languageList)
  }

  if (payload.birthDate) {
    req.setDateOfBirth(payload.birthDate)
  }
  console.log("PractitionerUpdateProfileRequest: ", req)

  return await PractitionersPromiseClient.updateProfile(req, {}).then((res) => {
    res = res.toObject()
    console.log("PractitionerUpdateProfileResponse: ", res)
    return Promise.resolve(res)
  })
}

export async function saveSortIndex(payload) {
  let req = new SaveSortIndexesRequest();

  console.log(payload.sortIndexes)
  if (payload.sortIndexes) {
    let qlist = []

    payload.sortIndexes.forEach(item => {
      const uq = new SaveSortIndex()
      uq.setPractitionerId(item.practitionerId);
      uq.setSortIdx(item.sortIdx);
      qlist.push(uq)
    })

    req.setSortIndexesList(qlist)
  }

  return await AdminsPromiseClient.savePractitionerSortIndexes(req, {}).then((res) => {
    return Promise.resolve(res.toObject())
  })
}

export async function uploadPhoto(file) {
  try {
    const formData = new FormData()
    formData.append('target', 'user-photo')
    formData.append('file', file)


    let photo = fetch(`${VueAppWebServiceUrl}/upload`, {
      method: 'post',
      body: formData,
    })
      .then(response => response.json())
      .then(function (data) {
        return get(data, 'claims.url')
      })

    return photo
  } catch (err) {
    console.error('Error: Api.Practitioner.uploadPhoto - ', err)
  }
}

export async function approvePractitioner(payload) {
  let req = new ApprovePractitionerRequest();
  req.setPractitionerId(payload.practitionerId)
  req.setDecision(payload.decision)
  console.log("ApprovePractitionerRequest: ", req)

  return await AdminsPromiseClient.approvePractitioner(req, {}).then((res) => {
    res = res.toObject()
    console.log("ApprovePractitionerResponse: ", res)
    return Promise.resolve(res)
  })
}

export async function updatePractitionerRole(payload) {
  console.log("🚀 ~ makePsychiatrist ~ payload:", payload)
  let req = new UpdatePractitionerRequest();
  console.log('req', req)
  req.setPractitionerId(payload.practitionerId)
  if('isPsychiatrist' in payload) req.setIsPsychiatrist(payload.isPsychiatrist)
  if('isClinician' in payload) req.setIsClinician(payload.isClinician)
  if('sembleAccessGroupName' in payload) req.setSembleAccessGroup(payload.sembleAccessGroupName)
  console.log("UpdatePractitionerRequest: ", req)

  return await AdminsPromiseClient.updatePractitionerInfo(req, {}).then((res) => {
    res = res.toObject()
    console.log("ApprovePractitionerResponse: ", res)
    return Promise.resolve(res)
  })
}


export async function makePractitionerHidden(payload) {
  console.log("🚀 ~ file: practitioner.js:188 ~ makePractitionerHidden ~ payload:", payload)
  let req = new UpdateVisibilityRequest();
  console.log('req', req)
  req.setPractitionerId(payload.practitionerId)
  req.setHidden(payload.hidden)
  console.log("UpdatePractitionerRequest: ", req)

  return await AdminsPromiseClient.updateVisibility(req, {}).then((res) => {
    res = res.toObject()
    console.log("ApprovePractitionerResponse: ", res)
    return Promise.resolve(res)
  })
}


export async function getPractitionerBySlug(payload) {
  let req = new PractitionerGetByIdRequest();
  console.log('req', req)
  if(payload.slug) req.setSlug(payload.slug)
  if(payload.id) req.setId(payload.id)
  return await PractitionersPromiseClient.getById(req, {}).then((res) => {
    res = res.toObject()
    return Promise.resolve(res)
  })
}
