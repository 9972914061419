import { getAppointmentsList } from '../../api/appointment';
import { markAppointmentPaid } from '@/api/admin';
import moment from 'moment';

const state = {
  appointments: null,
};

const getters = {
  appointments(state) {
    return state.appointments ? state.appointments.appointmentsList : [];
  },

  appointmentsThisWeek(state, getters) {
    if (!(getters.appointments || []).length) return [];

    let startDate = moment()
      .day(0)
      .format(moment.HTML5_FMT.DATE + ' 00:00:00');
    let endDate = moment()
      .day(6)
      .format(moment.HTML5_FMT.DATE + ' 23:59:59');
    return getters.appointments.filter((item) => {
      let appointmentTime = moment(item.appointment.startTime).format('YYYY-MM-DD HH:mm:ss');
      return moment(appointmentTime).isBetween(startDate, endDate);
    });
  },

  paidAppointmentsThisWeek(state, getters) {
    if (!(getters.appointmentsThisWeek || []).length) return [];
    return getters.appointmentsThisWeek.filter((item) => {
      return item.payment.status == 'succeeded';
    });
  },

  scheduledAppointmentThisWeek(state, getters) {
    if (!(getters.appointmentsThisWeek || []).length) return [];
    return getters.appointmentsThisWeek.filter((item) => {
      return item.humanStatus == 'Scheduled';
    });
  },

  pracNotRespondedToAppointments(state, getters) {
    if (!(getters.appointments || []).length) return [];
    return getters.appointments.filter((item) => {
      const date1 = new Date(item.appointment.approvedByClient);
      const date2 = new Date();
      const diffTime = Math.abs(date2 - date1);
      let twoDaysMili = 2 * 24 * 60 * 60 * 1000;
      return item.humanStatus == 'Awaiting Practitioner Accept' && diffTime > twoDaysMili;
    });
  },

  weakPractitioner(state, getters) {
    if (!(getters.appointments || []).length) return [];
    let weakPract = [];
    // Looping through all appointmentList
    getters.appointments.forEach((item) => {
      // Check if appointment is free
      if (item.appointment.price === 0) {
        // looping through the appointment list to check if that practitioner had an paid appointment
        let index = getters.appointments.findIndex((child) => child.appointment.price > 0 && child.practitioner.id === item.practitioner.id);
        // if the practitioner doesn't have any paid appointment then push it in the array
        if (index === -1) weakPract.push(item);
      }
    });
    return weakPract;
  },

  clientNotResponded(state, getters) {
    // if (
    //   this.appointments &&
    //   this.appointments.appointmentsList &&
    //   this.appointments.appointmentsList.length
    // ) {
    if (!(getters.appointments || []).length) return [];
    return getters.appointments.filter((item) => {
      const date1 = new Date(item.appointment.approvedByPractitioner);
      const date2 = new Date();
      const diffTime = Math.abs(date2 - date1);
      let threeDaysMili = 3 * 24 * 60 * 60 * 1000;
      return item.humanStatus == 'Awaiting Client Accept' && diffTime > threeDaysMili;
    });
    // return paidAppoitment;
    // } else {
    //   return [];
    // }
  },
};

const actions = {
  async GetAppointmentsList({ commit }, payload) {
    return getAppointmentsList(payload)
      .then((res) => {
        commit('setAppointments', res);
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  async MarkAppointmentAsPaid({ commit }, payload) {
    return markAppointmentPaid(payload)
      .then((res) => {
        // commit('setInviteList', res);
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
};

const mutations = {
  setAppointments(state, payload) {
    state.appointments = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
