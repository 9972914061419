import {
  ClientsPromiseClient
} from '@/plugins/grpc';

import { ListClientsRequest, ClientGetByIdRequest } from '@mercury-labs1/augmentive-proto/clients.messages_pb'
import {preparePagination} from "@/utils/preparePagination";

export async function getClientList(payload) {
  let req = new ListClientsRequest();
  console.log('req', req)

  let pagination = preparePagination(payload)
  if (pagination) {
    req.setPagination(pagination)
  }

  return await ClientsPromiseClient.list(req, {}).then((res) => {
    console.log('res', res)
    res = res.toObject()
    console.log("GetClientListResponse: ", res)
    return Promise.resolve(res)
  })
}


export async function getSingleClient(payload) {
  let req = new ClientGetByIdRequest();
  console.log('req', req)
  if(payload) req.setId(payload)

  return await ClientsPromiseClient.getById(req, {}).then((res) => {
    console.log('res', res)
    res = res.toObject()
    console.log("GetSingleClientResp: ", res)
    return Promise.resolve(res)
  })
}


