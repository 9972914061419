import {createRouter, createWebHistory} from "vue-router";
import DefaultLayout from "./../views/layouts/Default";
import EmptyLayout from "./../views/layouts/Empty";

const routes = [
  {
    path: "/",
    redirect: "/overview",
    component: EmptyLayout,
    children: [
      {
        path: "/login",
        name: "Login",
        component: () => import("./../views/Login"),
      },
      {
        path: "/logout",
        name: "Logout",
        component: () => import("./../views/Logout"),
      },
    ]
  },
  {
    path: "/",
    name: "Home",
    redirect: "/overview",
    component: DefaultLayout,
    children: [
      {
        path: "overview",
        name: "Overview",
        component: () => import("./../views/Overview"),
      },
      {
        path: "landing-page",
        name: "Landing Page",
        component: () => import("./../views/Content"),
      },
      {
        path: "terms-condition",
        name: "terms-condition",
        component: () => import("./../views/TermsConditions"),
      },
      {
        path: "clients",
        name: "Clients",
        component: () => import("./../views/clients/Index"),
      },
      {
        path: "practitioners",
        name: "Practitioners",
        component: () => import("./../views/practitioners/Index"),
        children: [
          {
            path: ":id(\\d+)",
            name: "ShowPractitioner",
            props: true,
            component: () => import("./../views/practitioners/_id"),
          },
        ]
      },
      {
        path: "/practitioners/:slug",
        name: "ShowPractitioner",
        props: true,
        component: () => import("./../views/practitioners/_slug"),
      },
      {
        path: "ranking",
        name: "Ranking",
        component: () => import("./../views/Ranking"),
      },

      {
        path: "appointments",
        name: "Appointments",
        component: () => import("./../views/appointments/Index"),
        children: [
          {
            path: ":id(\\d+)",
            name: "ShowAppointment",
            props: true,
            component: () => import("./../views/appointments/_id"),
          },
        ]
      },
      {
        path: "group-services",
        name: "Group Services",
        component: () => import("./../views/group-services/index.vue"),
      },
      {
        path: "users",
        name: "Users",
        component: () => import("./../views/Users"),
      },
      {
        path: "/users/:id",
        name: "ShowUserProfile",
        props: true,
        component: () => import("./../views/users/_id"),
      },
      {
        path: "analytics",
        name: "Analytics",
        component: () => import("./../views/Analytics"),
      },

      {
        path: "real-time",
        name: "Notifications",
        component: () => import("./../views/Notifications"),
      },

      {
        path: "warehouse",
        name: "Warehouse",
        component: () => import("./../views/Warehouse"),
      },

    ]
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
