<template>
  <!-- <thead class="bg-gray-50">
    <tr>
      <th
        scope="col"
        class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
      >
        Practitioner
      </th>
      <th
        scope="col"
        class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
      >
        Client ID
      </th>
      <th
        scope="col"
        class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
      >
        When
      </th>
      <th
        scope="col"
        class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
      >
        Price
      </th>
      <th
        scope="col"
        class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
      >
        Fee
      </th>
      <th
        scope="col"
        class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
      >
        Status
      </th>
      <th
        scope="col"
        class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
      >
        Appointments/Capacity
      </th>
      <th
        scope="col"
        class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
      >
        More
      </th>
    </tr>
  </thead> -->
  <thead class="bg-gray-50">
  <tr>
    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Practitioner
    </th>
    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Client ID
    </th>
    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      When
    </th>
    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Price
    </th>
    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Fee
    </th>
    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Status
    </th>
    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Appointments/Capacity
    </th>
    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      More
    </th>
  </tr>
  </thead>
</template>

<script>
export default {
  name: "AppointmentsTableHeader",
};
</script>
