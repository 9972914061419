<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed inset-0 overflow-hidden" @close="closeSlider">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="w-screen max-w-md">
              <div class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                <div class="px-4 py-6 sm:px-6">
                  <div class="flex items-start justify-between">
                    <h2 id="slide-over-heading" class="text-lg font-medium text-gray-900">
                      Group Services
                    </h2>
                    <div class="ml-3 h-7 flex items-center">
                      <button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500" @click="closeSlider">
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <!-- Main -->
                <div>
                  <div class="pb-1 sm:pb-6">
                    <div>
                      <div class="relative h-40 sm:h-56">
                        <img class="absolute h-full w-full object-cover" :src="service.groupService.photoSm ? service.groupService.photoSm : `https://images.unsplash.com/photo-1501031170107-cfd33f0cbdcc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&h=600&q=80`" alt="" />
                      </div>
                      <div class="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6">
                        <div class="sm:flex-1">
                          <div>
                            <div class="flex items-center">
                              <h3 class="font-bold text-xl text-gray-900 sm:text-2xl max-w-xs overflow-hidden overflow-ellipsis whitespace-nowrap">{{ service.groupService.title }}</h3>
                            </div>
                          </div>
                          <p class="text-sm text-gray-500">{{ service.practitioner.firstName }} {{ service.practitioner.lastName }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
                    <dl class="space-y-8 px-4 sm:px-6 sm:space-y-6">
                      <div>
                        <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                          Description
                        </dt>
                        <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                          <p>
                           {{ service.groupService.description }}
                          </p>
                        </dd>
                      </div>
                      <div>
                        <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                          Events
                        </dt>
                        <dd class="mt-3 text-sm text-gray-900 sm:col-span-2">
                          <div class="bg-white shadow overflow-hidden sm:rounded-md">
                            <ul role="list" class="divide-y divide-gray-200" v-if="(service.groupService.eventsList || []).length">
                              <li v-for="(item,i) in service.groupService.eventsList" :key="i">
                                <a href="#" class="block hover:bg-gray-50">
                                  <div class="px-4 py-4 sm:px-6">
                                    <div class="flex items-center justify-between">
                                      <p class="text-sm font-medium text-indigo-600 truncate">
                                        {{ item.title }}
                                      </p>
                                      <div class="ml-2 flex-shrink-0 flex">
                                        <p class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                          {{ item.priceFormatted }}
                                        </p>
                                      </div>
                                    </div>
                                    <div class="mt-1 sm:flex sm:justify-between">
                                      <div class="sm:flex">
                                        <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0" v-if="item.date">
                                          <CalendarIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                          {{ item.date }}
                                        </p>
                                        <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-4" v-if="item.time">
                                          <ClockIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                          {{ item.time }}
                                        </p>
                                      </div>
                                    </div>
                                    <div class="mt-2 sm:flex sm:justify-between">
                                      <div class="sm:flex">
                                        <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0" v-if="item.date">
                                          <p class="font-medium">Appointments/Capacity:</p>
                                          <p class="ml-2">
                                            {{ item.appointments }}/{{ item.capacity }}
                                          </p>

                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </li>
                            </ul>
                            <p v-else class="text-sm text-gray-400 px-4 py-4 sm:px-6">
                              There is no Events created
                            </p>
                          </div>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'
import {  CalendarIcon , ClockIcon  } from '@heroicons/vue/solid'

export default {
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    XIcon,
    CalendarIcon,
    ClockIcon,
  },

  props: ['open', 'service'],
  // setup() {
  //   const open = ref(true)

  //   return {
  //     open,
  //   }
  // },

  methods: {
    closeSlider() {
      this.$emit('close')
    },
  },
}
</script>
