<template>
  <div class="px-4 py-5 bg-white shadow cursor-pointer rounded-lg overflow-hidden sm:p-6 hover:bg-green-100 flex flex-col justify-between" :class="{'bg-green-100': active === index}" :title="title">
    <dt class="text-sm font-medium text-gray-500">
      {{ title }}
    </dt>
    <dd class="mt-1 text-3xl font-semibold text-gray-900">
      {{ value }}
    </dd>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    value: {
      type: Number,
      default: 0
    },
    active: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style>

</style>
