<template>
  <tbody class="bg-white divide-y divide-gray-200">
    <tr v-for="item in items" :key="item.id" class="hover:bg-gray-100 cursor-pointer" @click="goTo(item.id)">
      <!-- <td v-if="item.id" class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 w-11">
        {{ item.id }}
      </td> -->
      <td class="px-4 py-1">
        <div class="flex items-center">
          <div class="flex-shrink-0 h-10 w-10 mr-4">
            <div class="h-10 w-10 rounded-full user-img-custom" :style="item.image ? `background-image: url('${item.image}')` : ''"></div>
          </div>
          <div v-if="item.name">
            <div class="text-sm font-medium text-gray-900 max-w-md truncate">
              {{ item.name }}
            </div>
            <div class="text-sm text-gray-500">
              {{ item.email }}
            </div>
          </div>
          <div class="ml-4" v-else>-</div>
        </div>
      </td>


      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500" v-if="item.lastActiveTime">
        <span v-if="item.lastActiveTime">
          {{ inactiveFrom(item.lastActiveTime) }}
        </span>
        <span v-else>Active</span>
      </td>

      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500" v-if="item.unreadMessages">
        <span v-if="item.unreadMessages">
          {{ item.unreadMessages }}
        </span>
      </td>

      <!-- <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500" v-else>
        <button type="button" @click.prevent="toggleAdmin(item)"  :class="[hasAdmin(item.rolesList) ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']"   role="switch" aria-checked="false">
          <span class="sr-only">Use setting</span>
          <span aria-hidden="true" :class="[hasAdmin(item.rolesList) ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" ></span>
        </button>
      </td> -->

      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 capitalize">
        <span v-if="item.rolesList">
          {{ item.rolesList.join(', ').toLowerCase() }}
        </span>
      </td>

      <!-- <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 capitalize">
        <button type="button" class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">View Profile</button>
      </td> -->
      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <Menu as="div" class="relative inline-block text-right">
          <div>
            <MenuButton class="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
              <span class="sr-only">Open options</span>
              <DotsVerticalIcon class="h-5 w-5" aria-hidden="true" />
            </MenuButton>
          </div>

          <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a @click="copy_json(item.practitioner)" href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Copy as JSON</a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a @click="copy_profile(item.practitioner.slug)" href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Copy Profile Link</a>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </td>
    </tr>
  </tbody>
</template>

<script>
import { approvePractitioner } from '@/api/practitioner';
import DateTimeFilterMixin from '@/mixins/DateTimeFilter';
import moment from 'moment';

import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';

import { DotsVerticalIcon } from '@heroicons/vue/solid';

export default {
  name: 'PractitionersTableItems',
  mixins: [DateTimeFilterMixin],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    DotsVerticalIcon,
  },
  methods: {
    async copy_json(s) {
      const t = JSON.stringify(s);
      await navigator.clipboard.writeText(t);
    },
    async copy_profile(s) {
      const t = window.location.host + '/specialists/' + s;
      await navigator.clipboard.writeText(t);
    },

    showRoles(data) {
      return data.join(',');
    },

    hasAdmin(data) {
      return data.includes('ADMIN')
    },

    toggleAdmin(data) {
      this.$emit('open', data)
    },

    goTo(data) {
      this.$router.push(`/users/${data}`);
    },
    // async approve(p) {
    //   console.log(p);
    //   p.verified = !p.verified;
    //   let req = {
    //     practitionerId: p.id,
    //     decision: p.verified,
    //   };

    //   approvePractitioner(req)
    //     .then((res) => {
    //       console.log(res);
    //       this.$store.dispatch('app/validateError', res, { root: true });
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       this.$store.dispatch('app/validateError', err, { root: true });
    //     });
    // },

    // open(item) {
    //   this.$emit('openSlider', item);
    //   console.log('Working');
    // },
    // inactiveFrom(data) {
    //   let startTime = moment();
    //   console.log('data', data);
    //   let lastActiveTime = moment(data.toString().slice(0, 19));
    //   let dayDiff = startTime.diff(lastActiveTime, 'days');
    //   return `${dayDiff} days`;
    // },
  },
  computed: {

  },
  mounted() {
    console.log('this.items', this.items);
  },
};
</script>
