<template>
  <div></div>
</template>

<script>
export default {
  name: "Logout",
  created: function () {
    this.$store.dispatch('FedLogOut').then(() => {
      this.$router.push({path: '/login'})
    })
  }
}
</script>