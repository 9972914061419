<template>
  <div class="pb-5">
    <h3 class="text-lg leading-6 font-medium text-gray-900 mb-4">
      Practitioner Registrations
    </h3>
    <Table :columns="columns">
      <WeakPractitionerItems
        :items="practitioners"
        @getPractitioners="getPractitioners"
        @openSlider="openProfileSlider"
      ></WeakPractitionerItems>
    </Table>
  </div>
</template>

<script>
import Table from "@/components/shared/Table";
import WeakPractitionerItems from "@/components/tables/practitioners/WeakPractitionerItems";

export default {
  name: "PractitionerSignupsToday",
  components: { Table, WeakPractitionerItems },
  data: () => ({
    columns: ['ID', 'Name', 'Approved', 'Fee From', 'Timezone', 'Verified', 'Payouts', 'Appointments', 'Individual', 'Group', 'Availability', 'More']
  }),
  props: {
    practitioners: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
