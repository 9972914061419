import { AdminsPromiseClient } from '@/plugins/grpc';
import { GetStreamFeedRequest } from '@mercury-labs1/augmentive-proto/admins.messages_pb';


export async function getStreamFeed(payload) {
  let req = new GetStreamFeedRequest();
  // console.log('StatisticsRequest: ', payload);

  return await AdminsPromiseClient.getStreamFeed(req, {}).then((res) => {
    res = res.toObject();
    console.log('StatisticsResponse: ', res);
    return Promise.resolve(res);
  });
}
