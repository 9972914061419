<template>
  <div class="relative z-10" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"></div>

    <div class="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
      <div class="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
        <div class="relative">
          <svg class="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
          </svg>
          <input type="text" class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm" placeholder="Search..." v-model="query" ref="input" />
        </div>

        <div class="flex divide-x divide-gray-100" v-if="(people || []).length">
          <!-- Preview Visible: "sm:h-96" -->
          <div class="max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4 sm:h-96">
            <!-- Default state, show/hide based on command palette state. -->
            <h2 class="mt-2 mb-4 text-xs font-semibold text-gray-500">Recent searches</h2>
            <ul class="-mx-2 text-sm text-gray-700" id="recent" role="listbox">
              <!-- Active: "bg-gray-100 text-gray-900" -->
              <li class="group flex cursor-default select-none cursor-pointer items-center rounded-md p-2 hover:bg-gray-200" id="recent-1" role="option" tabindex="-1" v-for="(user, i) in people" :key="i" @mouseover="setActiveUser(user)">
                <img :src="require('@/assets/default-user.png')" alt="" class="h-6 w-6 flex-none rounded-full" />
                <span class="ml-3 flex-auto truncate">{{ user.name }}</span>
                <!-- Not Active: "hidden" -->
                <!-- Heroicon name: solid/chevron-right -->
                <svg class="ml-3 h-5 w-5 flex-none text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                </svg>
              </li>
            </ul>
          </div>

          <!-- Active item side-panel, show/hide based on active state -->
          <div class="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex" v-if="activeUser">
            <div class="flex-none p-6 text-center">
              <img :src="require('@/assets/default-user.png')" alt="" class="mx-auto h-16 w-16 rounded-full object-cover" />
              <h2 class="mt-3 font-semibold text-gray-900">{{ activeUser.name }}</h2>
              <p class="text-sm leading-6 text-gray-500">
                {{ activeUser.email }}
              </p>
            </div>
            <div class="flex flex-auto flex-col justify-between p-6">
              <dl class="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                <dt class="col-end-1 font-semibold text-gray-900">Email Verified:</dt>
                <dd>{{ activeUser.emailVerified ? 'Verified' : 'Unverified' }}</dd>
                <!-- <dt class="col-end-1 font-semibold text-gray-900">URL</dt>
                <dd class="truncate"> -->
                  <!-- <a href="https://example.com" class="text-indigo-600 underline">https://example.com/{{ activePract.slug }}</a> -->
                <!-- </dd> -->
              </dl>
              <button type="button" class="mt-6 w-full rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click.prevent="goTo(activeUser)" v-if="activeUser.rolesList.includes('PRACTITIONER') || activeUser.rolesList.includes('CLIENT')">View More Details</button>
            </div>
          </div>
        </div>

        <!-- Empty state, show/hide based on command palette state -->
        <div class="py-14 px-6 text-center text-sm sm:px-14" v-else>
          <!-- Heroicon name: outline/users -->
          <svg class="mx-auto h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
          <p class="mt-4 font-semibold text-gray-900">No people found</p>
          <p class="mt-2 text-gray-500">We couldn’t find anything with that term. Please try again.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { watch, ref, defineProps, computed, defineEmits, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

const store = useStore();
const router = useRouter();
const emit = defineEmits(['close']);

defineProps({
  open: {
    type: Boolean,
    default: false,
  },
});

// const open = ref(true);
const query = ref('');
const activeUser = ref(null);
const input = ref(null);

const people = computed(() => store.getters['user/allUsers']);
// const activePractAddress = computed(() => activePract.value.address);

const getUsers = async (data) => {
  try {
    let req = {
      page: 1,
      perPage: 20,
      query: data,
    };
    await store.dispatch('user/GetUserList', req);
  } catch (err) {
    console.log('err', err)
  }
};

// const getPractitioners = (data) => {
//   let req = {
//     page: 1,
//     perPage: -1,
//     searchFilter: data,
//   };
//   store.dispatch('practitioner/GetPractitionerList', req);
// };

const setActiveUser = (data) => {
  activeUser.value = data;
};

const goTo = (data) => {
  console.log('data', data)
  router.push(`/users/${data.id}`);
  emit('close');
};

onMounted(async () => {
  await getUsers()
  if (input.value) input.value.focus();
});
watch(query, async (v) => {
  if (v.length) await getUsers(v);
  else getUsers()
});
</script>
