<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" static class="fixed z-10 inset-0 overflow-y-auto" @close="open = false" :open="open">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                         leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300"
                         enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                         enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                         leave-from="opacity-100 translate-y-0 sm:scale-100"
                         leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div>
              <div class="mt-3 text-center sm:mt-0">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                  Grant Admin Permissions
                </DialogTitle>
                <div class="mt-2">
                  <Listbox as="div" v-model="selected">
                    <ListboxLabel class="block text-sm font-medium text-gray-700 text-left">
                      Assignee to
                    </ListboxLabel>
                    <div class="mt-1 relative">
                      <ListboxButton
                        class="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                        <span class="w-full inline-flex truncate">
                          <span class="truncate">{{ selected !== -1 ? selected.name : "" }}</span>
                          <span class="ml-2 truncate text-gray-500">{{
                              selected !== -1 ? selected.rolesList.join(', ').toLocaleLowerCase() : ""
                            }}</span>
                        </span>
                        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true"/>
                        </span>
                      </ListboxButton>

                      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
                                  leave-to-class="opacity-0">
                        <ListboxOptions
                          class=" z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                          <ListboxOption as="template" v-for="user in users" :key="user.id" :value="user"
                                         v-slot="{ active, selected }">
                            <li
                              :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                              <div class="flex">
                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'truncate']">
                                  {{ user.name }}
                                </span>
                                <span :class="[active ? 'text-indigo-200' : 'text-gray-500', 'ml-2 truncate']">
                                  {{ user.rolesList.join(', ').toLocaleLowerCase() }}
                                </span>
                              </div>

                              <span v-if="selected"
                                    :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                <CheckIcon class="h-5 w-5" aria-hidden="true"/>
                              </span>
                            </li>
                          </ListboxOption>
                        </ListboxOptions>
                      </transition>
                    </div>
                  </Listbox>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button type="button"
                      class="btn-primary w-full sm:col-start-2 sm:text-sm"
                      @click="giveAdminPermissions">
                Grant
              </button>
              <button type="button"
                      class="btn-white mt-3 w-full sm:mt-0 sm:col-start-1 sm:text-sm"
                      @click="open = false" ref="cancelButtonRef">
                Cancel
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {ref} from 'vue'
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import {CheckIcon, SelectorIcon} from '@heroicons/vue/solid'
import {changeRoles, getUserList} from "@/api/user";

export default {
  name: "GiveAdminPermissions",
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon,
  },
  setup() {
    const users = ref([])
    const open = ref(false)
    const selected = ref(-1)

    return {
      users,
      open,
      selected,
    }
  },
  emits: ["update-list"],
  methods: {
    getUsers() {
      let req = {
        perPage: -1,
        page: 1,
      }

      getUserList(req).then((res) => {
        this.users = res.usersList.filter(function (obj) {
          if (obj.rolesList.indexOf("ADMIN") === -1) {
            return true
          }
        })
      }).catch(() => {
        this.users = []
      })
    },
    giveAdminPermissions() {
      let payload = {
        id: this.selected.id,
        roles: [...this.selected.rolesList, "ADMIN"]
      }

      let self = this
      changeRoles(payload).then(function () {
        self.$emit('update-list')
        self.open = false
      })
    }
  },
  watch: {
    open(val) {
      if (val) {
        this.getUsers()
      } else {
        this.selected = -1
      }
    }
  },
}
</script>
