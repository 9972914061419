<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed inset-0 overflow-hidden" @close="closeSlider">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="w-screen max-w-2xl">
              <div class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                <div class="px-4 py-6 sm:px-6">
                  <div class="flex items-start justify-between">
                    <h2 id="slide-over-heading" class="text-lg font-medium text-gray-900">Appointments</h2>
                    <div class="ml-3 h-7 flex items-center">
                      <button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500" @click="closeSlider">
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <!-- Main -->
                <div>
                  <div class="pb-1 sm:pb-6">
                    <div>
                      <div class="relative h-40 sm:h-56">
                        <img class="absolute h-full w-full object-cover" src="../../../assets/international.png" alt="" />
                      </div>
                      <div class="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6" v-if="appointment">
                        <div class="sm:flex-1">
                          <div>
                            <div class="flex items-center">
                              <h3 class="font-bold text-xl text-gray-900 sm:text-2xl" v-if="appointment.event && appointment.event.title">{{ appointment.event.title }}</h3>
                            </div>
                            <p class="text-sm text-gray-500">{{ appointment.practitioner.firstName }} {{ appointment.practitioner.lastName }}</p>
                          </div>
                        </div>
                        <Menu as="div" class="relative inline-block text-left" v-if="appointmentStatus == 'Awaiting Payment'">
                          <MenuButton class="inline-flex items-center p-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-400 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <span class="sr-only">Open options menu</span>
                            <DotsVerticalIcon class="h-5 w-5" aria-hidden="true" />
                          </MenuButton>
                          <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                            <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div class="py-1">
                                <MenuItem>
                                  <p :class="['text-gray-700', 'block px-4 py-2 text-sm cursor-pointer']" @click="markAsPaid(appointment)">Mark as Paid</p>
                                </MenuItem>
                              </div>
                            </MenuItems>
                          </transition>
                        </Menu>
                      </div>
                    </div>
                  </div>
                  <div class="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
                    <div class="px-4 sm:px-6">
                      <div class="flex items-center mb-4">
                        <p class="text-sm font-medium text-gray-500 sm:w-20">Client Id:</p>
                        <p class="text-sm text-gray-900">
                          {{ appointment.client.id }}
                        </p>
                      </div>

                      <div class="flex items-center mb-4">
                        <p class="text-sm font-medium text-gray-500 sm:w-20">Status:</p>
                        <div v-if="appointment.humanStatus == 'Ready'">
                          <span class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800">
                            {{ appointment.humanStatus }}
                          </span>
                        </div>
                        <div v-else-if="appointment.humanStatus == 'Cancelled'">
                          <span class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-pink-100 text-pink-800">
                            {{ appointment.humanStatus }}
                          </span>
                        </div>
                        <div v-else-if="appointment.humanStatus == 'Expired'">
                          <span class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-yellow-100 text-yellow-800">
                            {{ appointment.humanStatus }}
                          </span>
                        </div>
                        <div v-else-if="appointment.humanStatus == 'Finished'">
                          <span class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800">
                            {{ appointment.humanStatus }}
                          </span>
                        </div>
                        <div v-else>
                          <span class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-purple-100 text-purple-800">
                            {{ appointment.humanStatus }}
                          </span>
                        </div>
                      </div>

                      <div class="flex items-center mb-4">
                        <p class="text-sm font-medium text-gray-500 sm:w-20">Price:</p>
                        <p class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800">
                          {{ appointment.event.priceFormatted }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot, Menu, MenuButton, MenuItem, MenuItems  } from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';
import { DotsVerticalIcon } from '@heroicons/vue/solid';

export default {
  props: ['open', 'appointment'],
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    XIcon,
    DotsVerticalIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems
  },

  computed: {
    appointmentStatus() {
      console.log('this.appointment.humanStatus', this.appointment.humanStatus)
      return this.appointment.humanStatus;
    }
  },

  methods: {
    closeSlider() {
      this.$emit('close');
    },

    markAsPaid(appointment) {
      this.$emit('markPaid', appointment);
    }
  },
};
</script>
