import moment from "moment";

export default {
  methods: {
    DateTimeFilter: function (val) {
      if (!val) return ''
      return moment(val).format("dddd, MMMM Do YYYY, HH:mm:ss A")
    }
  }
}
