<template>
  <tbody class="bg-white divide-y divide-gray-200">
    <tr v-for="(item, i) in clients" :key="i">
      <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">{{ item.firstName }} {{ item.lastName }}</td>
      <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
        {{ item.id }}
      </td>
      <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
        {{ item.invitationId }}
      </td>
      <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
        <button type="button" @click.prevent="toggleCallback(item, i)" :id="`toggle-${i}`"  :class="[item.receivedCallback ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']" role="switch" aria-checked="false">
          <span class="sr-only">Use setting</span>
          <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
          <span aria-hidden="true" :id="`togglespan-${i}`" :class="[item.receivedCallback ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"></span>
        </button>
      </td>
      <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
        <div class="" v-if="active !== i">
          <div class="flex items-center">
            <span v-if="item.publicNotes">{{ item.publicNotes }}</span>
            <span v-else>No Data</span>
            <PencilIcon class="w-5 ml-3 cursor-pointer" @click.prevent="showTextArea(i)" />
          </div>
        </div>
        <div class="" v-else>
          <form action="#" class="relative" @submit.prevent="submitNotes(item, i)">
            <div class="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
              <textarea rows="4" name="note" v-model="item.publicNotes" class="block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm" placeholder="Add your comment..." />

              <!-- Spacer element to match the height of the toolbar -->
              <div class="py-2" aria-hidden="true">
                <!-- Matches height of button in toolbar (1px border + 36px content height) -->
                <div class="py-px">
                  <div class="h-9" />
                </div>
              </div>
            </div>

            <div class="absolute bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-end">
              <button type="submit" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Post</button>
            </div>
          </form>
        </div>
      </td>
      <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
        <Menu as="div" class="relative inline-block text-right">
          <div>
            <MenuButton class="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
              <span class="sr-only">Open options</span>
              <DotsVerticalIcon class="h-5 w-5" aria-hidden="true" />
            </MenuButton>
          </div>

          <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="origin-top-right absolute right-0 z-10 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a @click="deleteUser(item)" href="#" :class="[active ? 'bg-gray-100 text-red-600 text-gray-900' : 'text-red-600', 'block px-4 py-2 text-sm']">Delete User</a>
                </MenuItem>
                <!-- <MenuItem v-slot="{ active }">
                  <a @click="copy_profile(item.practitioner.slug)" href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Copy Profile Link</a>
                </MenuItem> -->
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </td>
    </tr>
  </tbody>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted, watch } from 'vue';
import { useStore } from 'vuex';

import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { DotsVerticalIcon, PencilIcon } from '@heroicons/vue/solid';

const props = defineProps({
  clients: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['openDeleteModal'])

const store = useStore();

const clientList = ref(props.clients);
const active = ref(null);
const toggleBtn = ref(null);
const toggleCallback = async (client, index) => {

  try {
    let el = document.getElementById(`toggle-${index}`)
    if(el.classList.contains('bg-indigo-600')) {
      el.classList.replace('bg-indigo-600', 'bg-gray-200')
      document.getElementById(`togglespan-${index}`).classList.replace('translate-x-5', 'translate-x-0')
    }else if(el.classList.contains('bg-gray-200')) {
      el.classList.replace('bg-gray-200', 'bg-indigo-600')
      document.getElementById(`togglespan-${index}`).classList.replace('translate-x-0', 'translate-x-5')
    }
    let req = {
      id: client.id,
      receivedCallback: !client.receivedCallback,
      publicNotes: client.publicNotes
    };

    await store.dispatch('clients/UpdateClientInfo', req);
  } catch (err) {
    console.log('err', err);
  }
};

const showTextArea = (index) => {
  active.value = index;
};

const deleteUser = async (client) => {
  try {
    // let req = {
    //   id: client.id,
    // };
    // await store.dispatch('clients/DeleteClient', req);
    console.log('client', client)
    emit('openDeleteModal', client)

  } catch (err) {
    console.log('err', err);
  }
};

const submitNotes = async (client, index) => {
  // console.log('evnt', evnt);
  try {
    console.log('client', client)
    // let el = document.getElementById(`note-${index}`)
    let req = {
      id: client.id,
      publicNotes: client.publicNotes,
      receivedCallback: client.receivedCallback
    };
    console.log('req', req);
    await store.dispatch('clients/UpdateClientInfo', req);
  } catch (err) {
    console.log('err', err);
  } finally {
    active.value = null;
  }
};
</script>
