<template>
  <tbody class="divide-y divide-gray-200 bg-white">
  <tr v-for="appointment in items" :key="appointment.appointment.id">
    <td class="px-4 py-2 whitespace-nowrap">
      <a
        href="#"
        v-if="appointment.practitioner"
        @click.prevent="$router.push({ name: 'ShowAppointment', params: { id: appointment.practitioner.id } })"
        class="flex items-center user-hover-state"
        :title="'appointmentId: ' + ((appointment && appointment.appointment && appointment.appointment.id) ? appointment.appointment.id : '-')"
      >
        <div class="flex-shrink-0 h-10 w-10 mr-4">
          <div
            class="h-10 w-10 rounded-full user-img-custom"
            :style="appointment.practitioner && appointment.practitioner.photoSm ? `background-image: url('${appointment.practitioner.photoSm}')` : ''"
          ></div>
        </div>
        <div class="" v-if="appointment.practitioner" @click="$emit('open', appointment)">
          <div class="text-sm font-medium text-gray-900">
            {{ appointment.practitioner.firstName }} {{ appointment.practitioner.lastName }}
          </div>
          <div class="text-sm text-gray-500" v-if="appointment.service">{{ appointment.service.title }}</div>
        </div>
        <div class="ml-4" v-else>-</div>
      </a>
    </td>
    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
      {{ appointment.client ? appointment.client.id : '-' }}
    </td>
    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
      {{ formatDate(appointment.appointment.startTime) }}
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      <span v-if="appointment.appointment.priceFormatted">
        {{ appointment.appointment.priceFormatted }}
      </span>
      <span v-else>-</span>
    </td>
    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
      <span v-if="appointment.appointment.feeFormatted" class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800">
        {{ appointment.appointment.feeFormatted }}
      </span>
      <span v-else>-</span>
    </td>
    <td class="px-6 py-2 whitespace-nowrap">
      <div v-if="appointment.humanStatus == 'Ready'">
        <span class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800">
          {{ appointment.humanStatus }}
        </span>
      </div>
      <div v-else-if="appointment.humanStatus == 'Cancelled'">
        <span class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-pink-100 text-pink-800">
          {{ appointment.humanStatus }}
        </span>
      </div>
      <div v-else-if="appointment.humanStatus == 'Expired'">
        <span class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-yellow-100 text-yellow-800">
          {{ appointment.humanStatus }}
        </span>
      </div>
      <div v-else-if="appointment.humanStatus == 'Finished'">
        <span class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800">
          {{ appointment.humanStatus }}
        </span>
      </div>
      <div v-else>
        <span class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-purple-100 text-purple-800">
          {{ appointment.humanStatus }}
        </span>
      </div>
    </td>
    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
      {{ appointment.event ? `${appointment.event.appointments}/${appointment.event.capacity}` : '0/0' }}
    </td>
    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
      <Menu as="div" class="relative inline-block text-left">
        <div>
          <MenuButton class="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span class="sr-only">Open options</span>
            <DotsVerticalIcon class="h-5 w-5" aria-hidden="true" />
          </MenuButton>
        </div>

        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <MenuItems class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div class="py-1">
              <MenuItem v-slot="{ active }">
                <a @click='copy(appointment)' href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Copy as JSON</a>
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <a @click='markPaid(appointment)' href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Mark as Paid</a>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </td>
  </tr>
  </tbody>
</template>

<script>
import DateTimeFilterMixin from "@/mixins/DateTimeFilter";
import moment from 'moment'

import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { DotsVerticalIcon } from '@heroicons/vue/solid'
import { mapActions } from 'vuex'

export default {
  name: "AppointmentsTableItems",
  mixins: [DateTimeFilterMixin],
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    DotsVerticalIcon,
  },
  methods: {
    ...mapActions({
      markAppoointmentAsPaid: 'appointments/MarkAppointmentAsPaid',
    }),
    async copy(s) {
      const t = JSON.stringify(s)
      await navigator.clipboard.writeText(t);
    },
    formatDate(date) {
      return moment(date).format('Do-MMMM, h:mm:ss a');
    },
    async markPaid(data) {
      // console.log(`markPaid`, appointment)
      await this.markAppoointmentAsPaid(data.appointment.id)
      await this.$emit('getAppointments')
    }
  },
  mounted() {
    console.log(`appointments`, this.items)
  }
}
</script>
