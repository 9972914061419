<template>
  <div class="h-screen flex overflow-hidden bg-white">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        static
        class="fixed inset-0 flex z-40 lg:hidden"
        @close="sidebarOpen = false"
        :open="sidebarOpen"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div
            class="relative flex-1 flex flex-col max-w-xs w-full pt-2 pb-0 bg-white"
          >
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>

            <sidebar></sidebar>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden lg:flex lg:flex-shrink-0">
      <div
        class="flex flex-col w-64 border-r border-gray-200 pt-2 pb-0 bg-white"
      >
        <sidebar></sidebar>
      </div>
    </div>

    <!-- Main column -->
    <div class="flex flex-col w-0 flex-1 overflow-hidden bg-gray-100">
      <!-- Search header -->
      <div class="lg:hidden">
        <div
          class="flex items-center justify-between bg-white border-b border-gray-200 px-4 py-1.5"
        >
          <div>
            <img
              class="h-8 w-auto"
              src="@/assets/augmentive.png"
              alt="Augmentive"
            />
          </div>
          <div>
            <button
              type="button"
              class="-mr-3 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pink-600"
              @click="sidebarOpen = true"
            >
              <span class="sr-only">Open sidebar</span>
              <MenuIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
      <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none" id="main">
        <router-view />
      </main>
    </div>

    <notifications></notifications>
    <search-modal :open="search" @close="closeSearch" v-if="search"></search-modal>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useStore } from 'vuex'
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XIcon, MenuIcon } from "@heroicons/vue/outline";
import Sidebar from "../../components/layouts/Sidebar";
import Notifications from "@/components/layouts/Notifications";
import SearchModal from "@/components/layouts/SearchModalNew";
// data: () => ({
//   sidebarOpen: false,
//   search: false,
// }),

const store = useStore();

const sidebarOpen = ref(false);
const search = ref(false);

const closeSearch = () => {
  search.value = false
};

onMounted(async () => {
  window.addEventListener("keydown", (e) => {
    if (e.keyCode == 75 && (e.ctrlKey || e.metaKey)) {
      search.value = true;
    } else if (e.keyCode == 17 && e.keyCode == 18 && e.ctrlKey) {
      search.value = true;
    } else if (e.keyCode == 27) {
      search.value = false;
    }
  });

  await store.dispatch('stream/GetStreamFeed')
  await store.dispatch('stream/GetNotificationFeed')
  // await store.dispatch('stream/GetNotificationList', { limit: 20 })
});
// }
// setup() {
//   const sidebarOpen = ref(false)
//   let search = ref(false)
//   onMounted(() => {
//     // console.log('Mounted')
//     window.addEventListener('keydown', (e) => {
//       if(e.keyCode == 75 && (e.ctrlKey || e.metaKey)) {
//         search = true
//         console.log(search)
//       }
//       // console.log(e.keyCode)
//     })
//   })
//   return {
//     sidebarOpen,
//   }
// },
</script>
