<template>
  <div class="px-4 mt-6 sm:px-6 lg:px-8">
    <appointments></appointments>
  </div>
</template>

<script>
import Appointments from '@/components/pages/appointments/Appointments'

export default {
  name: "AppointmentsIndex",
  components: {Appointments}
}
</script>