<template>
  <tbody class="bg-white divide-y divide-gray-200">
  <tr v-for="item in items" :key="item.practitioner.id">
    <td v-if="item.practitioner" class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      {{ item.practitioner.id }}
    </td>
    <td class="px-6 py-4 whitespace-nowrap">
      <a
        href="#"
        v-if="item.practitioner"
        @click.prevent="$router.push({ name: 'ShowPractitioner', params: { id: item.practitioner.id } })"
        class="flex items-center"
      >
        <div class="flex-shrink-0 h-10 w-10 mr-4">
          <div
            class="h-10 w-10 rounded-full user-img-custom"
            :style="item.practitioner && item.practitioner.photoSm ? `background-image: url('${item.practitioner.photoSm}')` : ''"
          ></div>
        </div>
        <div v-if="item.practitioner" @click="open(item)">
          <div class="text-sm font-medium text-gray-900 truncate max-w-xs">
            {{ item.practitioner.firstName }} {{ item.practitioner.lastName }}
          </div>
          <div v-if="item.user" class="text-sm text-gray-500" v-text="item.user.email"></div>
        </div>
        <div class="ml-4" v-else>-</div>
      </a>
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      <span v-if="item.practitioner.lastActiveTime">
        <!-- {{ item.practitioner.lastActiveTime }} -->
        {{ inactiveFrom(item.practitioner.lastActiveTime) }}
      </span>
      <span v-else>Active</span>
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      <span v-if="item.practitioner.timezone">
        {{ item.practitioner.timezone }}
      </span>
      <span v-else>-</span>
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      <span v-if="item.practitioner.verified" class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800">
        {{ item.practitioner.verified }}
      </span>
      <span v-else class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-pink-100 text-pink-800">
        No
      </span>
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      <span v-if="item.nextAvailable" class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800">
        {{ item.nextAvailable }}
      </span>
      <span v-else class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-pink-100 text-pink-800">
        Not Set
      </span>
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      <Menu as="div" class="relative inline-block text-right">
        <div>
          <MenuButton class="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span class="sr-only">Open options</span>
            <DotsVerticalIcon class="h-5 w-5" aria-hidden="true" />
          </MenuButton>
        </div>

        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <MenuItems class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div class="py-1">
              <MenuItem v-slot="{ active }">
                <a @click='copy_json(item.practitioner)' href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Copy as JSON</a>
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <a @click='copy_profile(item.practitioner.slug)' href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Copy Profile Link</a>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </td>
  </tr>
  </tbody>
</template>

<script>
import {approvePractitioner} from "@/api/practitioner";
import DateTimeFilterMixin from "@/mixins/DateTimeFilter";
import moment from "moment"

import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/vue'

import {
  DotsVerticalIcon
} from '@heroicons/vue/solid'

export default {
  name: "PractitionersTableItems",
  mixins: [DateTimeFilterMixin],
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    DotsVerticalIcon,
  },
  methods: {
    async copy_json(s) {
      const t = JSON.stringify(s)
      await navigator.clipboard.writeText(t);
    },
    async copy_profile(s) {
      const t = window.location.host + "/specialists/" + s
      await navigator.clipboard.writeText(t);
    },
    async approve(p) {
      console.log(p)
      p.verified = !p.verified
      let req = {
        "practitionerId": p.id,
        "decision": p.verified
      }

      approvePractitioner(req).then((res) => {
        console.log(res)
        this.$store.dispatch("app/validateError", res, { root: true })
      }).catch((err) => {
        console.log(err)
        this.$store.dispatch("app/validateError", err, { root: true })
      })
    },

    open(item) {
      this.$emit('openSlider', item)
      console.log('Working')
    },
    inactiveFrom(data) {
      let startTime = moment()
      let lastActiveTime = moment(data.toString().slice(0, 19));
      let dayDiff = startTime.diff(lastActiveTime, 'days');
      return `${dayDiff} days`
    }
  },
  computed: {

  },
}
</script>
