<template>
  <tbody class="bg-white divide-y divide-gray-200">
  <tr v-for="(services, i) in items" :key="i">
    <td class="px-6 py-4 whitespace-nowrap">
      <a
        href="#"
        v-if="services.groupService"
        class="flex items-center user-hover-state"
      >
        <div class="flex-shrink-0 h-10 w-10 mr-4">
          <div
            class="h-10 w-10 rounded-full user-img-custom"
            :style="services.groupService && services.groupService.photoSm ? `background-image: url('${services.groupService.photoSm}')` : ''"
          ></div>
        </div>
        <div class="" v-if="services.groupService" @click="$emit('open', services)">
          <div class="text-sm font-medium text-gray-900 max-w-xs overflow-hidden overflow-ellipsis">
            {{ services.groupService.title }}
          </div>
        </div>
        <div class="ml-4" v-else>-</div>
      </a>
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      {{ services.practitioner ? `${services.practitioner.firstName} ${services.practitioner.lastName}` : '-' }}
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      {{ services.practitioner ? `${services.practitioner.id}` : '-' }}
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      <Menu as="div" class="relative inline-block text-left">
        <div>
          <MenuButton class="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span class="sr-only">Open options</span>
            <DotsVerticalIcon class="h-5 w-5" aria-hidden="true" />
          </MenuButton>
        </div>

        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <MenuItems class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div class="py-1">
              <MenuItem v-slot="{ active }">
                <a @click='copy(appointment)' href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Copy as JSON</a>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </td>
  </tr>
  </tbody>
</template>

<script>
import DateTimeFilterMixin from "@/mixins/DateTimeFilter";
import moment from 'moment'

import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { DotsVerticalIcon } from '@heroicons/vue/solid'

export default {
  name: "GroupServicesTableItems",
  mixins: [DateTimeFilterMixin],
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    DotsVerticalIcon,
  },
  methods: {
    async copy(s) {
      const t = JSON.stringify(s)
      await navigator.clipboard.writeText(t);
    },
    formatDate(date) {
      return moment(date).format('Do-MMMM, h:mm:ss a');
    }
  },
  mounted() {
    console.log(`appointments`, this.items)
  }
}
</script>
