<template>
  <div class="">
    <div class="mb-5 grid grid-cols-2 sm:grid-cols-4 gap-4 items-center">
      <h3 class="col-span-1 sm:col-span-3 text-lg leading-6 font-medium text-gray-900">Landing Page</h3>
      <div class="text-right">
        <button type="button" class="btn-primary" @click.prevent="openForm(null)">
          <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Add Content
        </button>
      </div>
    </div>
    <div class="">
      <Table :columns="columns">
        <ContentTableItems :content="allContentList" @openEdit="openForm" @deleteContent="openDeleteModal" />
      </Table>
    </div>
    <delete-content-modal :open="deleteModal" :content="content" @deleteContent="deleteContent" @close="deleteModal = false"></delete-content-modal>
    <content-form :open="openSlide" :activeQuery="activeQuery" @close="openSlide = false"></content-form>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { ref, reactive, onMounted, computed } from 'vue';

import { PlusIcon } from '@heroicons/vue/outline';
import ContentTableItems from '@/components/tables/content/ContentTableItems';
import Table from '@/components/shared/Table';
import DeleteContentModal from './DeleteContentModal.vue';
import ContentForm from './ContentForm.vue'


const store = useStore();

const columns = ['ID', 'Title', 'Category', 'Keywords', 'More'];
const deleteModal = ref(false);
const openSlide = ref(false);
const content = ref({});
const activeQuery = reactive({});

const allContentList = computed(() => store.getters['content/allContentList']);

const getContentList = async () => {
  try {
    await store.dispatch('content/GetLandingPageList');
  } catch (err) {
    console.log('err', err);
  }
};

const openDeleteModal = (data) => {
  deleteModal.value = true;
  content.value = data
};

const openForm = (data) => {
  console.log('data', data)
  if(data) activeQuery.value = data
  openSlide.value = true;
}

const deleteContent = async (data) => {
  try {
    await store.dispatch('content/DeleteLandingContent', data);
    await getContentList();
    deleteModal.value = false;
  } catch (err) {
    console.log('err', err);
  }
};

onMounted(async () => {
  await getContentList();
});
</script>
