import {AuthPromiseClient} from '../plugins/grpc'
import {CurrentUserInfoRequest, LoginRequest} from "@mercury-labs1/augmentive-proto/auth.messages_pb";

export async function login(payload) {
    let req = new LoginRequest();
    req.setEmail(payload.email);
    req.setPassword(payload.password);

    return await AuthPromiseClient.login(req, {}).then((res) => {
        return Promise.resolve(res.toObject())
    })
}

export async function getCurrentUser() {
    let req = new CurrentUserInfoRequest();
    return await AuthPromiseClient.currentUserInfo(req, {}).then((res) => {
        return Promise.resolve(res.toObject())
    }).catch((err) => {
        return Promise.reject(err)
    })
}