import { getClientList, getSingleClient } from '@/api/clients';
import { updateClientInfo } from '@/api/admin';

const state = {
  clientsList: [],
  client: null,
};

const getters = {
  clients: (state) => state.clientsList.clientsList,
  clientsTotal: (state) => state.clientsList.total,
  clientProfile: (state) => state.client && state.client.client ? state.client.client : {},
  allClientDetails: (state, getters) => {
    if (!(getters.clients || []).length) return [];
    let newClientList = JSON.parse(JSON.stringify(getters.clients.map(item => item.client)))
    return newClientList
  },
  registeredClientThisWeek: (state, getters) => {
    if (!(getters.clients || []).length) return [];
    let allDeatails = getters.clients.filter((item) => {
      const date1 = new Date(item.user.createdAt);
      const date2 = new Date();
      const diffTime = Math.abs(date2 - date1);
      let weekMili = 7 * 24 * 60 * 60 * 1000;
      if (diffTime < weekMili) return item.client;
    });

    return JSON.parse(JSON.stringify(allDeatails.map(item => item.client)))
  },
};

const actions = {
  async GetClientList({ commit }, payload) {
    return getClientList(payload)
      .then((res) => {
        commit('setClientsList', res);
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  async UpdateClientInfo({ commit, dispatch }, payload) {
    return updateClientInfo(payload)
      .then(async (res) => {
        return Promise.resolve(res);

      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  async GetSingleClient({ commit, dispatch }, payload) {
    return getSingleClient(payload)
      .then(async (res) => {
        commit('setSingleClient', res);
        return Promise.resolve(res);

      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
};

const mutations = {
  setClientsList(state, payload) {
    state.clientsList = payload;
  },
  setSingleClient(state, payload) {
    state.client = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
