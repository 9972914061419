import { getStreamFeed } from '@/api/stream.js';
import { connect } from 'getstream';
import moment from 'moment';

var notificationFeed;

const state = {
  streamFeed: null,
  notificationList: null,
  newNotification: null,
  loading: false,
};

const getters = {
  streamFeedName(state) {
    return state.streamFeed.adminFeedName;
  },

  streamFeedToken(state) {
    return state.streamFeed.adminFeedToken;
  },

  allNotifications(state) {
    return state.notificationList;
  },

  allNotificationActivities(state, getters) {
    let results = JSON.parse(JSON.stringify(getters.allNotifications)) ;
    if (!(results || []).length) return [];
    let activities = [];
    console.log('results', results)
    results.forEach((item) => {
      activities = activities.concat(item.activities);
    });
    activities.forEach(item => {
      if(item.recipient) item.recipient = JSON.parse(item.recipient)
    });
    return activities
  },

  arrivedNotification(state) {
    return state.newNotification;
  },

  getStreamLoading(state) {
    return state.loading;
  },
  // todaysNotification(state) {
  //   if(!(state.notificationList || []).length) return []

  //   // return state.notificationList.filter(noti => {

  //   // })
  // }
};

const mutations = {
  setStreamFeed(state, payload) {
    state.streamFeed = payload;
  },

  setNotificationList(state, payload) {
    if (payload.concat) state.notificationList = state.notificationList.concat(payload.results);
    else state.notificationList = payload.results;
  },

  setNewNotification(state, payload) {
    state.newNotification = payload;
  },

  setStreamLoading(state, payload) {
    state.loading = payload;
  },
};

const actions = {
  async GetStreamFeed({ commit }) {
    return getStreamFeed()
      .then((res) => {
        commit('setStreamFeed', res);
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  async GetNotificationFeed({ commit, getters, rootGetters }) {
    function callback(data) {
      console.log('Stream A new activity: ', data);
      console.log('Stream A new activity Object: ', data.new[0]);
      let newNotification = data.new[0];
      if (newNotification) {
        commit('setNewNotification', newNotification);
      }
      // if(newNotification && newNotification.account_type === rootGetters[UserGetters.currentMode]) {
      //   commit(ChatMutations.SetNewNotification.name, newNotification)

      //   if(newNotification.kind !== 'VIDEO_ROOM_OFF' && newNotification.kind !== 'AGREEMENT_SIGNED' && newNotification.kind !== 'PAYMENT_REQUIRED') self.$notificationToast(data)

      //   if(newNotification.kind == 'NEW_APPOINTMENT' || newNotification.kind == 'UPDATE_APPOINTMENT') {
      //     // fetch appointment list
      //     dispatch(BookingActions.getAppointments, { pendingUpcoming: true, reset: false, limit: 7, page: 1 }, { root: true })
      //     dispatch(BookingActions.getAppointmentsPerDay, {}, { root: true })
      //   }
      // }
    }

    function successCallback() {
      console.log('Now listening to changes in realtime. Add an activity to see how realtime works.');
    }

    function failCallback(data) {
      console.log('Something went wrong, check the console logs');
      console.log(data);
    }
    try {
      let authenticated = rootGetters['user/isAuthenticated'];
      if (!authenticated) {
        console.log('You are not authenticated');
        return;
      }
      commit('setStreamLoading', true);
      console.log('process.env.VUE_APP_STREAM_KEY', process.env.VUE_APP_STREAM_KEY)
      console.log('process.env.VUE_APP_STREAM_APP_ID', process.env.VUE_APP_STREAM_APP_ID)
      const client = connect(process.env.VUE_APP_STREAM_KEY, null, process.env.VUE_APP_STREAM_APP_ID, { timeout: 15000 });
      // console.log('client', getters.streamFeedToken)
      notificationFeed = client.feed('Notifications', getters.streamFeedName, getters.streamFeedToken);
      console.log('notificationFeed', notificationFeed);

      var self = this;

      notificationFeed.subscribe(callback).then(successCallback, failCallback);
    } catch (err) {
      console.log('GetNotificationFeedError', err);
    } finally {
      commit('setStreamLoading', false);
    }
  },

  /**
   * sort channel by last message
   * @param {void}
   */
  async GetNotificationList({ state, commit, dispatch }, { limit, offset }) {
    try {
      if (!notificationFeed) return;
      let results = await notificationFeed.get({ limit: limit, mark_seen: true, offset: offset || 0 });
      if (offset) results.concat = true;
      commit('setNotificationList', results);
      console.log(`%c GetStream notification list:`, 'background: #222; color: #bada55', results);
      return results;
    } catch (err) {
      // dispatch(AppActions.validateError, err, { root: true })
      console.log('err', err);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
