<template>
  <div class="h-screen flex overflow-hidden bg-white">
    <div v-show="true" class="flex flex-col min-w-0 flex-1 overflow-hidden bg-gray-100">
      <div class="flex-1 relative z-0 flex overflow-hidden">
        <main class="flex-1 relative z-0 overflow-y-scroll focus:outline-none xl:order-last">
          <!-- Breadcrumb -->
          <nav class="flex items-start px-4 py-3 sm:px-6 lg:px-8 xl:hidden" aria-label="Breadcrumb">
            <a @click.prevent="$router.push({ name: 'Appointments' })" href="#"
               class="inline-flex items-center space-x-3 text-sm font-medium text-gray-900">
              <ChevronLeftIcon class="-ml-2 h-5 w-5 text-gray-400" aria-hidden="true"/>
              <span>Appointments</span>              
            </a>
          </nav>

          <article>
            <!-- Profile header -->
            <div>
              <div class="h-32 w-full object-cover lg:h-48" style="background-color: #e5e7eb"></div>
              <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                <div class="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                  <div class="flex">
                    <div
                      class="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32 user-img-custom"
                      :style="practitioner && practitioner.photoSm ? `background-image: url('${practitioner.photoSm}')` : ''"
                    >
                    </div>
                  </div>
                  <div v-if="practitioner"
                       class="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                    <div class="sm:hidden 2xl:block mt-6 min-w-0 flex-1">
                      <h1 class="text-2xl font-bold text-gray-900 truncate">
                        {{ practitioner.firstName }} {{ practitioner.lastName }}
                      </h1>
                    </div>
                  </div>
                </div>
                <div class="hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1">
                  <h1 class="text-2xl font-bold text-gray-900 truncate" v-if="practitioner">
                    {{ practitioner.firstName }} {{ practitioner.lastName }}
                  </h1>
                </div>
              </div>
            </div>

            423432423

            <div class="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 mb-5">
              <div class="grid gap-4 grid-cols-6">
                <dl class="col-span-6 sm:col-span-2">
                  <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                    <dt class="text-sm font-medium text-gray-500 truncate">
                      APPOINTMENT ID
                    </dt>
                    <dd class="mt-1 text-3xl font-semibold text-gray-900">
                      {{ appointment.id }}
                    </dd>
                  </div>
                </dl>
                <dl class="col-span-6 sm:col-span-2">
                  <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                    <dt class="text-sm font-medium text-gray-500 truncate">
                      PRACTITIONER ID
                    </dt>
                    <dd class="mt-1 text-3xl font-semibold text-gray-900">
                      {{ practitioner.id }}
                    </dd>
                  </div>
                </dl>
                <dl class="col-span-6 sm:col-span-2">
                  <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                    <dt class="text-sm font-medium text-gray-500 truncate">
                      CLIENT ID
                    </dt>
                    <dd class="mt-1 text-3xl font-semibold text-gray-900">
                      {{ client.id }}
                    </dd>
                  </div>
                </dl>

                <div class="col-span-6">
                  <h3>Appointment</h3>
                </div>

                <div class="col-span-6 sm:col-span-2">
                  <label for="service_name" class="block text-sm font-medium text-blue-gray-900">Service Name:</label>
                  <input type="text" name="service_name" id="service_name" :value="service.title" readonly
                        class="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"/>
                </div>
                <div class="col-span-6 sm:col-span-2">
                  <label for="start_time" class="block text-sm font-medium text-blue-gray-900">Start Time:</label>
                  <input type="text" name="start_time" id="start_time" :value="DateTimeFilter(appointment.startTime)" readonly
                        class="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"/>
                </div>
                <div class="col-span-6 sm:col-span-2">
                  <label for="end_time" class="block text-sm font-medium text-blue-gray-900">End Time:</label>
                  <input type="text" name="end_time" id="end_time" :value="DateTimeFilter(appointment.endTime)" readonly
                        class="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"/>
                </div>
                <div class="col-span-6 sm:col-span-2">
                  <label for="status" class="block text-sm font-medium text-blue-gray-900">Status:</label>
                  <input type="text" name="status" id="status" :value="appointment.status" readonly
                        class="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"/>
                </div>
                <div class="col-span-6 sm:col-span-2">
                  <label for="event_id" class="block text-sm font-medium text-blue-gray-900">Event Id:</label>
                  <input type="text" name="event_id" id="event_id" :value="event.id" readonly
                        class="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"/>
                </div>
                <div class="col-span-6 sm:col-span-2">
                  <label for="last_room_ended" class="block text-sm font-medium text-blue-gray-900">Last Room Ended:</label>
                  <input type="text" name="last_room_ended" id="last_room_ended" :value="DateTimeFilter(appointment.lastRoomEnded)" readonly
                        class="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"/>
                </div>

                <div class="col-span-6">
                  <h3>Client</h3>
                </div>

                <div class="col-span-6 sm:col-span-2">
                  <label for="client_id" class="block text-sm font-medium text-blue-gray-900">ID:</label>
                  <input type="text" name="client_id" id="client_id" :value="client.id" readonly
                        class="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"/>
                </div>
                <div class="col-span-6 sm:col-span-2">
                  <label for="client_timezone" class="block text-sm font-medium text-blue-gray-900">Timezone:</label>
                  <input type="text" name="client_timezone" id="client_timezone" :value="client.timezone" readonly
                        class="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"/>
                </div>

                <div class="col-span-6">
                  <h3>Practitioner</h3>
                </div>

                <div class="col-span-6 sm:col-span-2">
                  <label for="practitioner_name" class="block text-sm font-medium text-blue-gray-900">Name:</label>
                  <input type="text" name="practitioner_name" id="practitioner_name" :value="practitioner.firstName + ' ' + practitioner.lastName" readonly
                        class="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"/>
                </div>
                <div class="col-span-6 sm:col-span-2">
                  <label for="practitioner_timezone" class="block text-sm font-medium text-blue-gray-900">Timezone:</label>
                  <input type="text" name="practitioner_timezone" id="practitioner_timezone" :value="practitioner.timezone" readonly
                        class="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"/>
                </div>
              </div>
            </div>
          </article>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import {ChevronLeftIcon} from '@heroicons/vue/solid'
import {getAppointmentById} from "@/api/appointment";
import DateTimeFilterMixin from "@/mixins/DateTimeFilter";

export default {
  name: "AppointmentItem",
  components: {
    ChevronLeftIcon,
  },
  mixins: [DateTimeFilterMixin],
  data: () => {
    return {
      appointment: {},
      practitioner: {},
      client: {},
      service: {},
      event: {},
    }
  },
  props: {
    id: {
      type: String
    },
  },
  mounted() {
    this.getAppointment()
  },
  methods: {
    getAppointment() {
      getAppointmentById(this.id).then((res) => {
        console.log("getAppointmentById: ", res)
        if (!res) {
          return this.clearData()
        }
        this.appointment = res.appointment || {}
        this.practitioner = res.practitioner || {}
        this.client = res.client || {}
        this.service = res.service || {}
        this.event = res.event || {}
      }).catch(() => {
        this.clearData()
      })
    },
    clearData() {
      this.appointment = {}
      this.practitioner = {}
      this.client = {}
      this.service = {}
      this.event = {}
    }
  },
  watch: {
    id: function () {
      this.getAppointment()
    }
  }
}
</script>
