const state = {
  notifications: [],
}

const getters = {
  notifications: state => state.notifications
}

const actions = {
  /***
   * Add Notification and Show
   * @param commit
   * @param payload
   */
  addNotification({commit}, payload) {
    commit("app/setNotification", payload, {root: true})

    setTimeout(function () {
      commit("shiftNotification", {root: true})
    }, 5000);
  },
  /**
   * Error validation
   * @param commit
   * @param {Object|String} error
   * @returns {void}
   */
  validateError({dispatch}, error) {
    try {
      let message = error.message || error || ''
      if (message) {
        message = message.charAt(0).toUpperCase() + message.slice(1)
        switch (true) {
          case error.code === 16:
            dispatch("addNotification", {
              type: 'warning',
              visible: true,
              title: message,
            })
            break
          case error.code === 200:
            dispatch("addNotification", {
              type: 'success',
              visible: true,
              title: message,
            })
            break
          default:
            dispatch("addNotification", {
              type: 'error',
              visible: true,
              title: message,
            })
            break
        }
      }
    } catch (err) {
      console.error('Error: App - validateError: ', err)
    }
  },
}

const mutations = {
  setNotification(state, payload) {
    state.notifications.push(payload)
  },
  shiftNotification(state) {
      state.notifications.shift()
  },
  removeNotificationByIndex(state, index) {
    state.notifications.splice(index, 1)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
