<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="">
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="fixed inset-0 overflow-hidden" @close="closeSlide">
        <div class="absolute inset-0 overflow-hidden">
          <DialogOverlay class="absolute inset-0" />

          <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <div class="w-96 max-w-2xl">
                <div class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div class="px-4 py-6 sm:px-6">
                    <div class="flex items-start justify-between">
                      <h2 id="slide-over-heading" class="text-lg font-medium text-gray-900">Terms & Condition Form</h2>
                      <div class="ml-3 h-7 flex items-center">
                        <button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500" @click="closeSlide">
                          <span class="sr-only">Close panel</span>
                          <XIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- Main -->
                  <div class="px-4 sm:px-6">
                    <div class="pb-1 sm:pb-6">
                      <form>
                        <div class="mb-4">
                          <label for="category" class="block text-sm font-medium text-gray-700">User Type</label>
                          <select id="category" v-model="form.kind" name="category" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                            <option value="null" disabled selected>Please select a user type</option>
                            <option v-for="(item, i) in types" :key="i" :value="item.value">{{ item.name }}</option>
                          </select>
                        </div>

                        <div class="mb-4">
                          <label for="desc" class="block text-sm font-medium text-gray-700">Description</label>
                          <div class="mt-1">
                            <QuillEditor theme="snow" v-model:content="form.text" class="terms-editor" contentType="html" />
                          </div>
                        </div>
                        <div class="flex justify-end">
                          <button type="button" class="btn-primary" @click.prevent="submit">{{ form.id ? 'Update' : 'Submit' }}</button>
                        </div>
                      </form>
                      <!-- {{ landingContent }} -->
                    </div>
                  </div>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits, onMounted, watch } from 'vue';
import { useStore } from 'vuex';

import { Dialog, DialogOverlay, TransitionChild, TransitionRoot, Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';
import { ClockIcon, UserIcon, CalendarIcon, DotsVerticalIcon, ExclamationCircleIcon, CheckIcon, SelectorIcon } from '@heroicons/vue/solid';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  activeQuery: {
    type: Object,
    default: null,
  },
});

const store = useStore();
const emit = defineEmits(['close']);

const types = [
  { id: 1, name: 'PRACTITIONER', value: 1 },
  { id: 2, name: 'CLIENT', value: 0 },
];

const landingContent = computed(() => store.getters['content/landingContent'] || {});

let blankForm = {
  kind: null,
  text: '',
};

const form = ref(blankForm);

const closeSlide = () => {
  emit('close');
  form.value = blankForm;
};

const submit = async () => {
  if (form.value.id) await store.dispatch('user/UpdateUsersTC', form.value);
  else await store.dispatch('user/CreateUsersTC', form.value);
  closeSlide();
  await store.dispatch('user/GetUserTcList');
};


watch(
  () => props.open,
  () => {
		if (props.open) {
			if (props.activeQuery) {
				form.value = Object.assign({}, props.activeQuery);
			}
		}
  }
);

</script>

<style>
.vs__search {
  height: 30px !important;
  border: none !important;
}
.vs__search:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.terms-editor .ql-editor {
  min-height: 300px;
}
</style>
