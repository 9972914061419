<template>
  <div class="px-4 my-6 sm:px-6 lg:px-8">
    <div class="mb-5 grid grid-cols-4 sm:grid-cols-4 gap-4 items-center">
      <h3 class="col-span-1 sm:col-span-3 text-lg leading-6 font-medium text-gray-900">Users</h3>
    </div>

    <div class="flex items-center justify-between">
      <div class="flex items-center">
        <div class="sm:mt-0 w-72 max-w-sm">
          <div class="mt-1 relative flex items-center">
            <input v-model="reqParams.query" @keyup.enter="performSearch" placeholder="Search Users" type="text" name="search" id="search" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md" />
            <div class="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
              <kbd class="inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400"> ⌘K </kbd>
            </div>
          </div>
        </div>

        <div class="ml-5">
          <nav class="-mb-px flex space-x-4">
            <button type="button" v-for="tab in filters" :key="tab.name" @click.prevent="reqParams.type = tab.slug" :class="[reqParams.type === tab.slug ? 'bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500' : 'text-indigo-600 border border-indigo-600 hover:bg-indigo-600 hover:text-white']" class="inline-flex items-center px-4 py-2 border border-transparent text-xs font-medium rounded-full shadow-sm">
              <CheckIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" v-if="reqParams.type === tab.slug" />
              {{ tab.name }}
            </button>
          </nav>
        </div>
      </div>
      <div class="">
        <span class="relative z-0 inline-flex shadow-sm rounded-md cmr-2">
          <button type="button" :class="[reqParams.sort === 'asc' ? 'bg-gray-200' : '']" @click.prevent="reqParams.sort = 'asc'" class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">Asc</button>
          <button type="button" :class="[reqParams.sort === 'desc' ? 'bg-gray-200' : '']" @click.prevent="reqParams.sort = 'desc'" class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">Desc</button>
        </span>
        <Menu as="div" class="relative inline-block text-left ml-2">
          <div>
            <MenuButton class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
              Sort By
              <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </MenuButton>
          </div>

          <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div class="py-1">
                <MenuItem v-slot="{ active }" v-for="(item, i) in orderByList" :key="i">
                  <a href="#" @click.prevent="reqParams.orderBy = item.value" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Sort By {{ item.name }}</a>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </div>
    <div class="" v-if="(people || []).length">
      <Table :columns="columns">
        <UsersTableItems :items="people" @open="openPermissionModal" />
      </Table>
      <table-pagination @to-page="changePage"></table-pagination>
    </div>
    <div class="" v-else>
      <SkeletonTable />
    </div>
    <permission-modal :open="openModal" :active="activeUser" @changePermission="changePermission" @close="closePermissionModal"></permission-modal>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { ref, computed, provide, onMounted, watch } from 'vue';
import moment from 'moment';

import { TrashIcon, PlusIcon, CheckIcon } from '@heroicons/vue/outline';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/solid';
import { getUserList } from '@/api/user';
import GiveAdminPermissions from '@/components/pages/users/GiveAdminPermissions';
import PermissionModal from '@/components/pages/users/PermissionModal';
import Table from '@/components/shared/Table';
import TablePagination from '@/components/tables/TablePagination';
import UsersTableItems from '@/components/tables/users/UsersTableItems';
import SkeletonTable from '@/components/shared/SkeletonTable';

const store = useStore();
const pageNumber = ref(1);
const reqParams = ref({
  type: 'all',
  query: '',
  orderBy: '',
  sort: 'asc',
});
const orderByList = [
  {
    name: 'Id',
    value: 'id',
  },
  {
    name: 'Created At',
    value: 'created_at',
  },
];
const filters = [
  {
    name: 'All',
    slug: 'all',
    params: {
      show_all: true,
    },
  },
  {
    name: 'Practitioner',
    slug: 'practitioner',
    params: {
      createdAt: moment().format(moment.HTML5_FMT.DATE),
      show_all: true,
    },
  },
  {
    name: 'Client',
    slug: 'client',
    params: {
      createdAt: moment().format(moment.HTML5_FMT.DATE),
      show_all: true,
    },
  },
  {
    name: 'Admin',
    slug: 'admin',
    params: {
      createdAt: moment().format(moment.HTML5_FMT.DATE),
      show_all: true,
    },
  },
];
const columns = ['User Name', 'Roles', 'More'];
const activeTab = ref('all');
const openModal = ref(false);
const activeUser = ref({});
// const page = ref(2);

const users = ref([]);
const people = computed(() => store.getters['user/allUsers']);
const userCount = computed(() => store.getters['user/userTotal']);
provide('currentPage', pageNumber);
provide('total', userCount);

const getUsers = async () => {
  try {
    let req = {
      page: pageNumber.value,
      perPage: 20,
      ...reqParams.value,
    };
    console.log('req', req);
    await store.dispatch('user/GetUserList', req);
  } catch (err) {
    console.log('err', err);
  }
};

const changePage = (nmbr) => {
  pageNumber.value = nmbr;
  getUsers();
};

const changePermission = async () => {
  try {
    if (!activeUser.value || !(activeUser.value.rolesList || []).length) return;
    let payload = {};
    if (activeUser.value.rolesList.includes('ADMIN')) {
      payload = {
        id: activeUser.value.id,
        roles: activeUser.value.rolesList.filter((obj) => obj !== 'ADMIN'),
      };
    } else {
      console.log('Grant Permission');
      payload = {
        id: activeUser.value.id,
        roles: [...activeUser.value.rolesList, 'ADMIN'],
      };
    }

    await store.dispatch('user/ChangeUserRoles', payload);
    await getUsers();
    closePermissionModal();
  } catch (err) {
    console.log('err', err);
  }
};

const openPermissionModal = (data) => {
  activeUser.value = data;
  openModal.value = true;
};

const closePermissionModal = () => {
  openModal.value = false;
};

onMounted(async () => {
  await getUsers();
});

watch(reqParams.value, async (v) => {
  if (v) {
    pageNumber.value = 1;
    await getUsers();
  }
});
</script>
