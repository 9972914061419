<template>
  <router-view />
  <notifications></notifications>
</template>

<script>
import Notifications from "@/components/layouts/Notifications";
export default {
  name: "EmptyLayout",
  components: {Notifications},
  displayName: "Empty"
}
</script>
