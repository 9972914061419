<template>
  <div class="px-4 mt-6 sm:px-6 lg:px-8">
    <group-services></group-services>
  </div>
</template>

<script>
import GroupServices from '@/components/pages/group-services/GroupServices'

export default {
  name: "GroupServicesIndex",
  components: {GroupServices}
}
</script>
