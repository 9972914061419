<template>
    <tbody class="bg-white divide-y divide-gray-200">
      <template v-if="(termList || []).length">
        <tr v-for="(item, i) in termList" :key="i">
          <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
            {{ i + 1 }}
          </td>
          <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
            <p v-html="getTruncateText(item.tc.text)"></p>
          </td>
          <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
            {{ item.tc.kind == 1 ? 'PRACTITIONER' : 'CLIENT' }}
          </td>
          <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
            {{ item.consents }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            <button type="button" @click.prevent="changeStatus(item)" :class="[item.tc.published ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']" role="switch" aria-checked="false">
              <span class="sr-only">Use setting</span>
              <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
              <span aria-hidden="true" :class="[item.tc.published ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"></span>
            </button>
          </td>
          <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 flex">
            <PencilAltIcon class="w-5 mr-4 cursor-pointer text-indigo-600" @click="openFormEdit(item)" />
            <!-- <TrashIcon class="w-5 cursor-pointer text-primary text-indigo-600" @click="deleteLandingPage(item)" /> -->
          </td>
        </tr>
      </template>
      <template v-else>
        <tr>
          <td colspan="5" class="px-6 py-2 whitespace-nowrap">
            <div class="h-48 flex flex-col justify-center items-center py-8">
              <InboxIcon class="w-12 text-gray-400" />
              <p class="text-gray-400 mt-3">Sorry there are currently no data in the table.</p>
            </div>
          </td>
        </tr>
      </template>
    </tbody>
  </template>
  
  <script setup>
  import { useStore } from 'vuex';
  import { defineProps, defineEmits, ref, onMounted, watch } from 'vue';
  
  import { PencilAltIcon, TrashIcon, InboxIcon  } from '@heroicons/vue/outline';

  const store = useStore();
  
  const props = defineProps({
    terms: {
      type: Array,
      default: () => [],
    },
  });

  const termList = ref([]);
  
  const emit = defineEmits(['openEdit']);

  const getTruncateText = (text) => {
    if (text.length > 100) {
      return text.substring(0, 100) + '...';
    }
    return text;
  }
  
  const deleteLandingPage = (data) => {
    // emit('deleteContent', data);
  };

  const changeStatus = (data) => {
    store.dispatch('user/updateTcListStatus', data.tc);
  }
  
  const openFormEdit = (data) => {
    console.log("🚀 ~ openFormEdit ~ data:", data)
    emit('openEdit', data.tc);
  }

  onMounted(() => {
    termList.value = [...props.terms];
  });

  watch(() => props.terms, (newVal) => {
    termList.value = [...newVal];
  });
  </script>
  
  <style></style>
  