import {AppointmentsPromiseClient} from '@/plugins/grpc'
import {ListAppointmentsRequest, AppointmentCategory} from "@mercury-labs1/augmentive-proto/appointments.messages_pb";
import {preparePagination} from "@/utils/preparePagination";

export async function getAppointmentsList(payload) {
  console.log("🚀 ~ file: appointment.js:6 ~ getAppointmentsList ~ payload:", payload)
  let req = new ListAppointmentsRequest();

  console.log('payload', payload)

  switch (payload.category) {
    case "all":
      req.setCategory(AppointmentCategory.ALL)
      break
    case "upcoming":
      req.setCategory(AppointmentCategory.UPCOMING)
      break
    case "pendingUpcoming":
      req.setCategory(AppointmentCategory.PENDING_UPCOMING)
      break
    case "history":
      req.setCategory(AppointmentCategory.HISTORY)
      break
  }

  let pagination = preparePagination(payload)
  if (pagination) {
    req.setPagination(pagination)
  }

  if (payload.dateStart) {
    req.setDateStart(payload.dateStart)
  }
  if (payload.dateEnd) {
    req.setDateEnd(payload.dateEnd)
  }
  if (payload.practitionerId) {
    req.setPractitionerId(payload.practitionerId)
  }
  if (payload.isGroup) {
    req.setIsGroup(payload.isGroup)
  }
  if (payload.isIndividual) {
    req.setIsIndividual(payload.isIndividual)
  }
  if (payload.orderBy) {
    req.setOrderBy(payload.orderBy)
  }
  if (payload.orderSort) {
    req.setOrderSort(payload.orderSort)
  }
  console.log("ListAppointmentsRequest: ", payload)


  return await AppointmentsPromiseClient.list(req, {}).then((res) => {
    res = res.toObject()
    console.log("ListAppointmentsResponse: ", res)
    return Promise.resolve(res)
  })
}

export async function getAppointmentById(id) {
  const req = new ListAppointmentsRequest()

  if (id) {
    req.setAppointmentId(id);
  }
  console.log("ListAppointmentsRequest(Id): ", id)

  return await AppointmentsPromiseClient.list(req, {}).then((res) => {
    res = res.toObject()
    if (res.appointmentsList.length > 0) {
      return Promise.resolve(res.appointmentsList[0])
    }
    return Promise.resolve(null)
  }).catch((err) => {
    console.log('err', err)
  })
}
