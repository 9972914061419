import {
  PublicServiceListRequest
} from "@mercury-labs1/augmentive-proto/practitioners.messages_pb";
import {preparePagination} from "@/utils/preparePagination";

import {
  PractitionersPromiseClient,
} from '@/plugins/grpc';


export async function getServicesList(payload) {
  let req = new PublicServiceListRequest();

  let pagination = preparePagination(payload)
  if (pagination) {
    req.setPagination(pagination)
  }

  if (payload.isGroup) {
    req.setIsGroup(payload.isGroup)
  }
  if (payload.isIndividual) {
    req.setIsIndividual(payload.isIndividual)
  }

  return await PractitionersPromiseClient.publicServiceList(req, {}).then((res) => {
    res = res.toObject()
    console.log("ListServiceResponse: ", res)
    return Promise.resolve(res)
  })
}
