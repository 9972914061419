<template>
  <div class="px-4 py-5 bg-white h-48 shadow rounded-lg overflow-hidden sm:p-6 cursor-not-allowed flex flex-col justify-between animate-pulse">
    <div class="animate-pulse">
      <div class="h-4 bg-gray-200 rounded"></div>
      <div class="h-4 mt-4 bg-gray-200 rounded"></div>
    </div>

    <div class="h-8 w-8 bg-gray-300 rounded"></div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
