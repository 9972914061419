<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',



  // async mounted() {
  //   console.log(this.axios)
  //   try {
  //     const { data } = await this.axios.get(
  //       "https://jsonplaceholder.typicode.com/users"
  //     );
  //     this.users = data;
  //     console.log(data)
  //   } catch (error) {
  //     console.log(error)
  //   }
  //   console.log('working')


  //   console.log('---------------------------')

  //   // console.log(data)
  // },
}
</script>
