<template>
  <tbody id="ranking-tbody" class="bg-white divide-y divide-gray-200">
    <tr v-for="item in items" :key="item.practitioner.id" class="bg-white ranking-tr" :data-sort-idx="item.practitioner.sortIdx" :data-practitioner-id="item.practitioner.id">
      <td v-if="item.practitioner" class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {{ item.practitioner.id }}
      </td>
      <td class="px-6 py-4 whitespace-nowrap">
        <a
          href="#"
          v-if="item.practitioner"
          @click.prevent="$router.push({ name: 'ShowPractitioner', params: { id: item.practitioner.id } })"
          class="flex items-center user-hover-state"
        >
          <div class="flex-shrink-0 h-10 w-10 mr-4">
            <div
              class="h-10 w-10 rounded-full user-img-custom"
              :style="item.practitioner && item.practitioner.photoSm ? `background-image: url('${item.practitioner.photoSm}')` : ''"
            ></div>
          </div>
          <div v-if="item.practitioner">
            <div class="text-sm font-medium text-gray-900">
              {{ item.practitioner.firstName }} {{ item.practitioner.lastName }}
            </div>
            <div v-if="item.user" class="text-sm text-gray-500" v-text="item.user.email"></div>
          </div>
          <div class="ml-4" v-else>-</div>
        </a>
      </td>
      <td class="px-6 py-4 whitespace-nowrap">{{ item.practitioner.sortIdx == 0 ? '-' : item.practitioner.sortIdx }}</td>
      <td class="px-6 py-4 whitespace-nowrap">{{ item.practitioner.timezone }}</td>
      <td class="px-6 py-4 whitespace-nowrap"></td>
      <td class="px-6 py-4 whitespace-nowrap"></td>
    </tr>
  </tbody>
</template>

<script>
import {saveSortIndex} from "@/api/practitioner";
import Sortable from 'sortablejs';

export default {
  name: "PractitionersTableItems",
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    makeSortableTable() {
      let tbody = document.getElementById('ranking-tbody');
      var sortable = Sortable.create(tbody, {
        dataIdAttr: 'data-sort-idx',
        // onEnd: function (evt) {
        //   console.log(evt)
        //   console.log(evt.to)
        //   console.log(evt.from)
        // },
        onUpdate: function (evt) {
          console.log('Sortable Table: onUpdate');
          const oldIndexArray = [];
          const newIndexArray = [];
          [].forEach.call(evt.target.getElementsByClassName('ranking-tr'), function (el) {
              oldIndexArray.push({
                practitionerId: el.getAttribute("data-practitioner-id"),
                sortIdx: el.getAttribute("data-sort-idx"),
              })
          });
          [].forEach.call(evt.from.getElementsByClassName('ranking-tr'), function (el,index) {
              el.setAttribute("data-sort-idx", index + 1);
          });
          [].forEach.call(evt.target.getElementsByClassName('ranking-tr'), function (el) {
              newIndexArray.push({
                practitionerId: el.getAttribute("data-practitioner-id"),
                sortIdx: el.getAttribute("data-sort-idx"),
              })
          });
          const sortIndexes = newIndexArray.filter((newIndexItem, index) => {
            const oldIndexItem = oldIndexArray[index];
            if (newIndexItem.practitionerId == oldIndexItem.practitionerId && newIndexItem.sortIdx == oldIndexItem.sortIdx) return false;
            return true;
          })

          saveSortIndex({sortIndexes}).then((res) => {
            console.log("saveSortIndex: ", res)
          })
        },
      });
      console.log('Sortable Table Created: ', !!sortable)
    }
  },
  mounted() {
    this.makeSortableTable()
  }
}
</script>
