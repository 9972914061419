<template>
  <div class="pb-5">
    <h3 class="text-lg leading-6 font-medium text-gray-900 mb-4">
      Users with unread messages
    </h3>
    <Table :columns="columns">
      <UsersTableItems :items="users" />
    </Table>
  </div>
</template>

<script>
import Table from "@/components/shared/Table";
import UsersTableItems from "@/components/tables/users/UsersTableItems";

export default {
  name: "PractitionerSignupsToday",
  components: { Table, UsersTableItems },
  data: () => ({
    columns: [ 'Name','ID', 'Inactive For', 'Unread Messages', 'type', 'More']
  }),
  props: {
    users: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
