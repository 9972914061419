import {getCurrentUser} from "../../api/auth";
import {getUserList, deleteFullUser, createTC, getTC, updateTC} from "../../api/admin";
import {changeRoles, getUserById} from "@/api/user";

const state = {
  user: null,
  userList: null,
  userDetails: null,
  tcList: [],
}

const getters = {
  isAuthenticated: state => !!state.user,
  stateUser: state => state.user,
  roles: state => state.user ? state.user.rolesList : [],
  allUsers: state => state.userList && state.userList.usersList ? state.userList.usersList : [],
  userTotal: state => state.userList && state.userList.totalCount ? state.userList.totalCount : 0,
  userPractitioner: state => state.userDetails && state.userDetails.practitioner ? state.userDetails.practitioner : null,
  userClients: state => state.userDetails && state.userDetails.client ? state.userDetails.client : null,
  userInfo: state => state.userDetails && state.userDetails.userInfo ? state.userDetails.userInfo : null,
  allTCList: state => state.tcList,
}

const actions = {
  async GetCurrentUser({commit}) {
    return getCurrentUser().then((res) => {
      commit('setUser', res.user)
      return Promise.resolve(res)
    }).catch((err) => {
      return Promise.reject(err)
    })
  },

  async CreateUsersTC({commit}, payload) {
    return createTC(payload).then((res) => {
      return Promise.resolve(res)
    }).catch((err) => {
      return Promise.reject(err)
    })
  },

  async UpdateUsersTC({commit}, payload) {
    return updateTC(payload).then((res) => {
      return Promise.resolve(res)
    
    }).catch((err) => {
      return Promise.reject(err)
    
    })
  },

  async GetUserTcList({commit}, payload) {
    return getTC(payload).then((res) => {
      console.log("🚀 ~ returngetTC ~ res:", res)
      commit('setTCList', res.itemsList)
      return Promise.resolve(res)
    }).catch((err) => {
      return Promise.reject(err)
    })
  },

  async updateTcListStatus({commit}, payload) {
    commit('updateTcStatus', payload)
    //call UpdateUsersTC function here
    payload.published = !payload.published
    await this.dispatch('user/UpdateUsersTC', payload)
  },

  async GetUserList({commit}, payload) {
    console.log('payload', payload)
    return getUserList(payload).then((res) => {
      commit('setUserList', res)
      return Promise.resolve(res)
    }).catch((err) => {
      return Promise.reject(err)
    })
  },

  async GetUserById({commit}, payload) {
    console.log('payload', payload)
    return getUserById(payload).then((res) => {
      commit('setUserDetails', res)
      return Promise.resolve(res)
    }).catch((err) => {
      return Promise.reject(err)
    })
  },

  async ChangeUserRoles({commit}, payload) {
    return changeRoles(payload).then((res) => {
      return Promise.resolve(res)
    }).catch(err => {
      return Promise.reject(err)
    })
  },

  async DeleteUser({commit}, payload) {
    return deleteFullUser(payload).then((res) => {
      return Promise.resolve(res)
    }).catch(err => {
      return Promise.reject(err)
    })
  }
}


const mutations = {
  setUser(state, user) {
    state.user = user
  },
  setUserList(state, payload) {
    state.userList = payload
  },
  setUserDetails(state, payload) {
    state.userDetails = payload
  },
  setTCList(state, payload) {
    state.tcList = payload
  },
  LogOut(state) {
    state.user = null
  },
  updateTcStatus(state, payload) {
    state.tcList = state.tcList.map((item) => {
      console.log("🚀 ~ state.tcList=state.tcList.map ~ item:", item)
      if (item.tc.id === payload.id) {
        return {
          ...item,
          tc: {
            ...item.tc,
            published: !item.tc.published
          }
        }
      }
      return item
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
