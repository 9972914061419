<template>
  <div class="flow-root">
    <ul role="list" class="-mb-8">
      <template v-if="!loading">
        <li v-for="(item, i) in notifications" :key="i">
          <div class="relative pb-7 md:pb-8">
            <span v-if="i !== notifications.length - 1" class="absolute hidden md:block top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
            <div class="relative flex items-start space-x-3">
              <!-- <template> -->
              <!-- <div class=""> -->
              <div class="relative hidden md:block">
                <img class="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white" :src="item.pic ? item.pic : require('@/assets/default-user.png')" alt="" />
              </div>
              <div class="min-w-0 flex-1">
                <div class="noti-des">
                  <div class="block md:flex items-center">
                    <div class="text-sm flex items-center mt-1 md:mt-0" v-if="item.kind === 'VIDEO_ROOM_ON' || item.kind === 'VIDEO_ROOM_OFF'">
                      <div @click="goTo(item.actor.id)" class="relative cursor-pointer inline-flex items-center bg-transparent rounded-full border border-gray-300 px-3 py-0.5 text-sm mr-2" v-if="item.object !== 'login-form'">
                        <span class="absolute flex-shrink-0 flex items-center justify-center">
                          <span class="h-1.5 w-1.5 rounded-full bg-yellow-500" aria-hidden="true" />
                        </span>
                        <span class="ml-3.5 font-medium text-black" v-if="item.actor && item.actor.data && item.actor.data.name">{{ item.actor.data.name }}</span>
                      </div>
                    </div>
                    <p class="text-sm font-medium leading-3 noti-para" v-html="item.description"></p>
                    <div class="text-sm flex items-center mt-1 md:mt-0">
                      <div @click="goTo(item.recipient.id)" class="relative cursor-pointer inline-flex items-center bg-transparent rounded-full border border-gray-300 px-3 py-0.5 text-sm md:ml-4" v-if="item.object !== 'login-form'">
                        <span class="absolute flex-shrink-0 flex items-center justify-center">
                          <span class="h-1.5 w-1.5 rounded-full bg-yellow-500" aria-hidden="true" />
                        </span>
                        <span class="ml-3.5 font-medium text-black" v-if="item.recipient && item.recipient.name">{{ item.recipient.name }}</span>
                      </div>
                    </div>
                  </div>

                  <p class="mt-0.5 text-sm text-gray-500">{{ formatedDate(item.time) }}</p>
                </div>
              </div>

              <div class="mt-1">
                <dropdown-view :list="dropDownList" :jsonData="item" @fire-action="dropDownAction"></dropdown-view>
              </div>
            </div>
          </div>
        </li>
      </template>


      <template v-else>
        <li v-for="i in 40" :key="i">
          <div class="px-4 py-5 bg-white rounded-lg overflow-hidden sm:p-6 cursor-not-allowed flex flex-col justify-between">
            <div class="flex">
              <div class="h-8 w-8 bg-gray-300 rounded animate-pulse"></div>
              <div class="w-full ml-4">

                <div class="h-4 bg-gray-200 rounded animate-pulse"></div>
                <div class="h-4 w-28 mt-1 bg-gray-200 rounded animate-pulse"></div>
              </div>
            </div>
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onUnmounted, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import moment from 'moment';

import { ChatAltIcon, TagIcon, UserCircleIcon, BellIcon } from '@heroicons/vue/solid';
import DropdownView from '@/components/shared/DropdownView.vue';

const store = useStore();
const router = useRouter();
const route = useRoute();

const loading = ref(false);
const offset = ref(0);
const completed = ref(false);
const limit = 10;
const dropDownList = [{ label: 'Copy JSON', value: 'copy' }];
const notifications = computed(() => store.getters['stream/allNotificationActivities']);
const newNotification = computed(() => store.getters['stream/arrivedNotification']);
const streamLoading = computed(() => store.getters['stream/getStreamLoading']);

const formatedDate = (date) => {
  let utcTime = moment.tz(date, "UTC")
  let convertedTime = utcTime.tz('Europe/London')
  return moment(convertedTime).fromNow();
};

const fetchNotifications = async () => {
  try {
    let el = document.getElementById('main');
    if (completed.value) {
      el.removeEventListener('scroll', onScroll);
      return;
    }
    if(!(notifications.value || []).length) loading.value = true;
    console.log('offset.value', offset.value);
    let res = await store.dispatch('stream/GetNotificationList', { limit: 10, offset: offset.value });
    console.log('res', res);
    if (!res) return;
    if (!res.next) completed.value = true;
    offset.value = offset.value + limit;
    console.log('offset.value', offset.value);
  } catch (err) {
    console.log('err', err);
  } finally {
    loading.value = false;
  }
};

const onScroll = async (e) => {
  let el = document.getElementById('noti');
  console.log('el.offsetHei', el.offsetHeight - 1000);
  if (e.target.scrollTop >= el.offsetHeight - 813) {
    await fetchNotifications();
  }
};

const dropDownAction = async (data) => {
  console.log('data', data);
  if (data.action === 'copy') {
    const t = JSON.stringify(data.notification);
    console.log('t', t);
    await navigator.clipboard.writeText(t);
  }
};

const isAppointment = (data) => {
  let notiKind = ['NEW_APPOINTMENT', 'UPDATE_APPOINTMENT', 'PAYMENT_REQUIRED', 'PAYMENT_RECEIVED', 'AGREEMENT_SIGNED'];
  return notiKind.includes(data.kind);
};

const goTo = (id) => {
  router.push(`/users/${id}`);
  // if (process.env.NODE_ENV === 'development') window.open(`https://augmentive.dev/data.`);
};

const clickEvents = () => {
  let appointmentEl = [...document.querySelectorAll('.noti-para a')]
  let actorEl = document.getElementsByClassName('actor_name')
  let userEl = document.getElementsByClassName('user_name')
  let allUserEl = [...actorEl, ...userEl]
  for (let i = 0; i < appointmentEl.length; i++) {
    appointmentEl[i].addEventListener('click', (e) => {
      e.preventDefault();
      let id = e.target.getAttribute('href').split('/')[3];
      router.push(`/appointments/${id}`);
    });
  }

  for (let i = 0; i < allUserEl.length; i++) {
    allUserEl[i].addEventListener('click', (e) => {
      e.preventDefault();
      let id = e.target.getAttribute('data-user-id')
      console.log('id', id)
      router.push(`/users/${id}`);
    });
  }
}

onMounted(async () => {
  // onScroll()
  await nextTick();

  await fetchNotifications();
  if (route.name === 'Notifications') {
    console.log(route.name);
    let el = document.getElementById('main');
    el.addEventListener('scroll', onScroll);
  }
  clickEvents()

});

onUnmounted(() => {
  let el = document.getElementById('main');
  el.removeEventListener('scroll', onScroll);
});
watch(newNotification, async (v) => {
  if (v) {
    offset.value = 0;
    await fetchNotifications();
  }
});

watch(
  () => streamLoading.value,
  async (v) => {
    if (!v) {
      await fetchNotifications();
      clickEvents()
    }
  },
  { deep: true }
);
</script>

<style>
/* .noti-des span a, .appointment {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  background-color: black;
  color: white;
  border-radius: 25px;
}

.noti-des .actor_name {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 25px;
  cursor: pointer;


} */
.noti-para a {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  background-color: black;
  color: white;
  border-radius: 25px;
  display: inline-flex;
  align-items: center;
  position: relative;
  margin-bottom: 5px;
}

.noti-para a::before {
  content: '';
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: white;
  border-radius: 50%;
  margin-right: 5px;
}

.noti-para span {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  /* background-color: black; */
  /* color: white; */
  border-radius: 25px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  cursor: pointer;
  margin-bottom: 5px;
}

.noti-para span::before {
  content: '';
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: black;
  border-radius: 50%;
  margin-right: 5px;
}
</style>
