<template>
  <div class="">
    <div class="pb-5">
      <h3 class="text-lg leading-6 font-medium text-gray-900">Stats Today</h3>
      <dl class="mt-5 grid grid-cols-2 gap-5 xl:grid-cols-4 2xl:grid-cols-6">
        <template v-if="!loading">
          <StatsTodayItem v-for="(item, i) in statsList" :key="i" :title="item.name" :value="item.value" :active="active" :index="i" @click="changeTab(i)" />
        </template>
        <template v-else>
          <SkeletonStatCard v-for="i in 12" :key="i" />
        </template>
      </dl>
    </div>
    <div class="mt-8" v-if="!loading">
      <div class="" v-if="active == 0">
        <AppiointmentThisWeek v-if="appointmentThisWeek.length" :appointments="appointmentThisWeek" />
      </div>
      <div class="" v-else-if="active == 1">
        <PractitionerSignUpThisWeek :practitioners="pracRegThisWeek" />
      </div>
      <div class="" v-else-if="active == 2">
        <ClientRegistration :clients="registeredClientThisWeek" />
        <!-- {{ registeredClientThisWeek }} -->
      </div>
      <div class="" v-else-if="active == 3">
        <AppiointmentThisWeek v-if="paidAppointmentsThisWeek" :appointments="paidAppointmentsThisWeek" />
      </div>
      <div class="" v-else-if="active == 4">
        <PractitionerNotActive :practitioners="pracNotActive" />
      </div>
      <div class="" v-else-if="active == 5">
        <PractitionerNotResponed v-if="pracNotRespondedToAppointments" :appointments="pracNotRespondedToAppointments" />
      </div>
      <div class="" v-else-if="active == 6">
        <WeakPerfomancePractitioner :practitioners="weakPractitioner" />
      </div>
      <div class="" v-else-if="active == 7">
        <AppiointmentThisWeek v-if="clientNotResponded" :appointments="clientNotResponded" />
      </div>
      <div class="" v-else-if="active == 8">
        <PractitionerNotActive :practitioners="pracInvitedClients" />
      </div>
      <div class="" v-else-if="active == 9">
        <PractitionerNotActive :practitioners="practNotInvited" />
      </div>
      <div class="" v-else-if="active == 10">
        <UnreadUsers v-if="unreadUsersList" :users="unreadUsersList" />
      </div>
      <div class="" v-else-if="active == 11">
        <AppiointmentThisWeek v-if="scheduledAppointmentThisWeek" :appointments="scheduledAppointmentThisWeek" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

import StatsTodayItem from './StatsTodayItem.vue';
import AppiointmentThisWeek from './AppiointmentThisWeek.vue';
import PractitionerSignUpThisWeek from '@/components/pages/index/PractitionerSignUpThisWeek';
import PractitionerNotActive from '@/components/pages/index/PractitionerNotActive';
import ClientRegistration from '@/components/pages/index/ClientRegistration';
import SkeletonStatCard from '@/components/shared/SkeletonStatCard';
import PractitionerNotResponed from '@/components/pages/index/PractitionerNotResponed';
import UnreadUsers from '@/components/pages/index/UnreadUsers';
import WeakPerfomancePractitioner from '@/components/pages/index/WeakPerfomancePractitioner';

export default {
  name: 'StatsToday',
  components: {
    StatsTodayItem,
    AppiointmentThisWeek,
    PractitionerSignUpThisWeek,
    PractitionerNotActive,
    ClientRegistration,
    PractitionerNotResponed,
    UnreadUsers,
    WeakPerfomancePractitioner,
    SkeletonStatCard,
  },
  data: () => {
    return {
      stats: {},
      active: 0,
      loading: false,
      appointments: null,
      practitioners: null,
      clients: null,
      inviteList: null,
    };
  },

  computed: {
    ...mapGetters({
      appointmentThisWeek: 'appointments/appointmentsThisWeek',
      paidAppointmentsThisWeek: 'appointments/paidAppointmentsThisWeek',
      scheduledAppointmentThisWeek: 'appointments/scheduledAppointmentThisWeek',
      pracNotRespondedToAppointments: 'appointments/pracNotRespondedToAppointments',
      clientNotResponded: 'appointments/clientNotResponded',
      weakPractitioner: 'appointments/weakPractitioner',
      pracRegThisWeek: 'practitioner/pracRegThisWeek',
      pracNotActive: 'practitioner/pracNotActive',
      allInviteList: 'practitioner/allInviteList',
      practNotInvited: 'practitioner/practNotInvited',
      pracInvitedClients: 'practitioner/pracInvitedClients',
      unreadUsersList: 'practitioner/unreadUsersList',
      registeredClientThisWeek: 'clients/registeredClientThisWeek',
    }),

    statsList() {
      return [
        {
          name: 'Appointments this week',
          value: this.appointmentThisWeek ? this.appointmentThisWeek.length : 0,
        },
        {
          name: 'Practitioner registrations this week',
          value: this.pracRegThisWeek ? this.pracRegThisWeek.length : 0,
        },
        {
          name: 'Client registrations this week',
          value: this.registeredClientThisWeek ? this.registeredClientThisWeek.length : 0,
        },
        {
          name: 'New paid appointments this week',
          value: this.paidAppointmentsThisWeek ? this.paidAppointmentsThisWeek.length : 0,
        },
        {
          name: 'Practitioners not active for > 7 days',
          value: this.pracNotActive ? this.pracNotActive.length : 0,
        },
        {
          name: 'Practitioners not responded to an appointment request for > 2 days',
          value: this.pracNotRespondedToAppointments ? this.pracNotRespondedToAppointments.length : 0,
        },
        {
          name: 'Practitioners that had a free session with a client > 7 days ago, but no paid appointment scheduled',
          value: this.weakPractitioner ? this.weakPractitioner.length : 0,
        },
        {
          name: 'Client not responded to Practitioner appointment request > 3 days',
          value: this.clientNotResponded ? this.clientNotResponded.length : 0,
        },
        {
          name: 'Practitioners that invited Client in last 7 days',
          value: this.pracInvitedClients ? this.pracInvitedClients.length : 0,
        },
        {
          name: 'Practitioners that have never invited a Client to the platform',
          value: this.practNotInvited ? this.practNotInvited.length : 0,
        },
        {
          name: 'Users with unread messages > 5 days',
          value: this.unreadUsersList ? this.unreadUsersList.length : 0,
        },
        {
          name: 'Appointments scheduled this week',
          value: this.scheduledAppointmentThisWeek ? this.scheduledAppointmentThisWeek.length : 0,
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      getAppointmentsList: 'appointments/GetAppointmentsList',
      getPractitionerList: 'practitioner/GetPractitionerList',
      getInviteList: 'practitioner/GetPractitionersInviteList',
      getClientList: 'clients/GetClientList',
    }),
    async getAppointments() {
      let req = {
        page: this.page,
        perPage: -1,
        category: 'all',
        orderBy: 'start_time',
        orderSort: 'desc',
        isIndividual: true,
      };
      await this.getAppointmentsList(req);
    },
    async getPractitioners() {
      let req = {
        page: 1,
        perPage: -1,
      };

      await this.getPractitionerList(req);
    },

    async getClients() {
      let req = {
        page: 1,
        perPage: -1,
      };
      await this.getClientList(req);
    },

    async getAllInviteList() {
      let req = {
        groupBy: true,
      };
      await this.getInviteList(req);
    },

    async fetchStatData() {
      try {
        this.loading = true;
        let p = [this.getAppointments(), this.getPractitioners(), this.getClients(), this.getAllInviteList()];
        await Promise.all(p);
      } catch (error) {
        console.log('error', error);
      } finally {
        this.loading = false;
      }
    },

    changeTab(index) {
      this.active = index;
    },
  },

  async mounted() {
    this.fetchStatData();
  },
};
</script>
