<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="">
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="fixed inset-0 overflow-hidden" @close="closeSlide">
        <div class="absolute inset-0 overflow-hidden">
          <DialogOverlay class="absolute inset-0" />

          <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <div class="w-96 max-w-2xl">
                <div class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div class="px-4 py-6 sm:px-6">
                    <div class="flex items-start justify-between">
                      <h2 id="slide-over-heading" class="text-lg font-medium text-gray-900">Content Form</h2>
                      <div class="ml-3 h-7 flex items-center">
                        <button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500" @click="closeSlide">
                          <span class="sr-only">Close panel</span>
                          <XIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- Main -->
                  <div class="px-4 sm:px-6">
                    <div class="pb-1 sm:pb-6">
                      <form>
                        <div class="mb-4">
                          <label for="title" class="block text-sm font-medium text-gray-700">Title</label>
                          <div class="mt-1">
                            <input type="text" v-model="form.title" name="title" id="title" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Title" />
                          </div>
                        </div>

                        <div class="mb-4">
                          <label for="title" class="block text-sm font-medium text-gray-700">Name</label>
                          <div class="mt-1">
                            <input type="text" v-model="form.name" name="name" id="name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Name" />
                          </div>
                        </div>

                        <div class="mb-4">
                          <label for="desc" class="block text-sm font-medium text-gray-700">Description</label>
                          <div class="mt-1">
                            <textarea rows="4" v-model="form.description" name="desc" id="desc" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Description" />
                          </div>
                        </div>

                        <div class="mb-4">
                          <label for="category" class="block text-sm font-medium text-gray-700">Category</label>
                          <select id="category" v-model="form.category" name="category" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                            <option value="" disabled selected>Please Select a category</option>
                            <option v-for="(item, i) in category" :key="i" :value="item.value">{{ item.name }}</option>
                          </select>
                        </div>
                        <div class="mb-4">
                          <label for="practitioner" class="block text-sm font-medium text-gray-700">Practitioner</label>
                          <v-select v-model="form.practitioner" :options="practitionerList" multiple label="label" :reduce="(pract) => pract.value" />
                          <!-- <Multiselect v-model="form.practitioner" mode="tags" placeholder="Add Practitioners" :close-on-select="false" :searchable="true" :create-option="true" :options="practitionerList" /> -->
                        </div>

                        <div class="mb-4">
                          <label for="keywords" class="block text-sm font-medium text-gray-700">Keywords</label>
                          <div class="mt-1">
                            <input type="text" v-model="form.keywords" name="keywords" id="keywords" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Keywords" />
                          </div>
                        </div>

                        <div class="mb-4">
                          <label for="content" class="block text-sm font-medium text-gray-700">Content</label>
                          <div class="mt-1">
                            <input type="text" v-model="form.content" name="content" id="content" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Content" />
                          </div>
                        </div>
                        <div class="flex justify-end">
                          <button type="button" class="btn-primary" @click.prevent="submit">{{ form.id ? 'Update' : 'Submit' }}</button>
                        </div>
                      </form>
                      <!-- {{ landingContent }} -->
                    </div>
                  </div>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits, onMounted, watch } from 'vue';
import { useStore } from 'vuex';

import { Dialog, DialogOverlay, TransitionChild, TransitionRoot, Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';
import { ClockIcon, UserIcon, CalendarIcon, DotsVerticalIcon, ExclamationCircleIcon, CheckIcon, SelectorIcon } from '@heroicons/vue/solid';

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  activeQuery: {
    type: Object,
    default: null,
  },
});

const store = useStore();
const emit = defineEmits(['close']);

const category = [
  { id: 1, name: 'Category', value: 'category' },
  { id: 2, name: 'Location', value: 'location' },
];

const value = ref([{ country: 'Canada', code: 'CA' }]);

const options = [
  { country: 'Canada', code: 'CA' },
  { country: 'New York', code: 'NY' },
];

const practitioner = ref([]);

const landingContent = computed(() => store.getters['content/landingContent'] || {});
const practitionerList = computed(() => {
  let list = store.getters['practitioner/practList'];
  let newArray = [];

  list.map((item) => {
    let obj = {};
    obj.label = item.firstName + ' ' + item.lastName;
    obj.value = item.id;

    newArray.push(obj);
  });
  return newArray;
});

let blankForm = {
  title: '',
  name: '',
  keywords: '',
  content: '',
  description: '',
  category: '',
  practitioner: [],
};

const form = ref(blankForm);

const closeSlide = () => {
  emit('close');
  form.value = blankForm;
};

const submit = async () => {
  if (form.value.id) await store.dispatch('content/UpdateLandingContent', form.value);
  else await store.dispatch('content/CreateLandingContent', form.value);
  closeSlide();
  await store.dispatch('content/GetLandingPageList');
};

const fetchPractitioner = async () => {
  try {
    let req = {
      page: 1,
      perPage: -1,
    };
    await store.dispatch('practitioner/GetPractitionerList', req);
  } catch (err) {
    console.log('err', err);
  }
};

onMounted(async () => {
  await fetchPractitioner();
});

watch(props.activeQuery, async (v) => {
  console.log('v', v);
  if (v) await store.dispatch('content/GetSingleLandingPage', v.value);
});

watch(landingContent, async (v) => {
  form.value = Object.assign({}, v);
  console.log('form', form.value);
  let practitioner = store.getters['content/practitionerList'];
  console.log('practitioner', practitioner);
  form.value.practitioner = [];
  practitioner.map((item) => {
    let obj = {};
    obj.value = item.id;
    obj.label = item.firstName + ' ' + item.lastName;

    form.value.practitioner.push(obj);
    console.log('form', form.value);
  });
});
</script>

<style>
.vs__search {
  height: 30px !important;
  border: none !important;
}
.vs__search:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
</style>
