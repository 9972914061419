<template>
  <div class="px-4 my-6 sm:px-6 lg:px-8">
    <h1 class="text-lg leading-6 font-medium text-gray-900 mb-5">Profile</h1>

    <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3  gap-8">
      <div class="col-span-1 xl:col-span-2">
        <practitioner-details :profile="profile"></practitioner-details>
      </div>
      <div class="">
        <practitioner-activity></practitioner-activity>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { ref, computed, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import PractitionerDetails from './PractitionerDetails.vue';
import PractitionerActivity from './PractitionerActivity.vue';

const store = useStore();
const route = useRoute();

const profile = computed(() => store.getters['practitioner/profile']);



const getSinglePractitioner = async () => {
  try {
    console.log('route.params.slug', route.params.slug);
    await store.dispatch('practitioner/GetPractitionerBySlug', route.params.slug);
  } catch (err) {
    console.log('err', err);
  }
};

onMounted(async () => {
  await getSinglePractitioner();
});
</script>

<style></style>
