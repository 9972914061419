import {getContentList, getSingleLandingPage, createContent, updateContent, deleteContentByID} from "@/api/content";

const state = {
  contentList: [],
  landingPage: null,
}

const getters = {
  allContentList(state) {
    return state.contentList
  },

  landingContent(state) {
    if(!state.landingPage || !state.landingPage.landingPage) return {}
    return state.landingPage.landingPage
  },

  practitionerList(state) {
    return state.landingPage.practitionersList
  }
}

const mutations = {
  setContentList(state, payload) {
    state.contentList = payload
  },

  setSingleContent(state, payload) {
    state.landingPage = payload
  }
}

const actions = {
  async GetLandingPageList({commit}) {
    return getContentList().then((res) => {
      commit('setContentList', res.landingPagesList)
      return Promise.resolve(res)
    }).catch((err) => {
      return Promise.reject(err)
    })
  },

  async GetSingleLandingPage({commit}, payload) {
    console.log('payload', payload)
    return getSingleLandingPage(payload).then((res) => {
      commit('setSingleContent', res.landingPage)
      return Promise.resolve(res)
    }).catch((err) => {
      return Promise.reject(err)
    })
  },

  async CreateLandingContent({commit}, payload) {
    console.log('payload', payload)
    return createContent(payload).then((res) => {
      // commit('setContentList', res.landingPagesList)
      console.log('res', res)
      return Promise.resolve(res)
    }).catch((err) => {
      return Promise.reject(err)
    })
  },

  async UpdateLandingContent({commit}, payload) {
    console.log('payload', payload)
    return updateContent(payload).then((res) => {
      // commit('setContentList', res.landingPagesList)
      console.log('res', res)
      return Promise.resolve(res)
    }).catch((err) => {
      return Promise.reject(err)
    })
  },

  async DeleteLandingContent({commit}, payload) {
    console.log('payload', payload)
    return deleteContentByID(payload).then((res) => {
      return Promise.resolve(res)
    }).catch((err) => {
      return Promise.reject(err)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
