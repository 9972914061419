<template>
  <div class="pdetails">
    <div class="pdetails__card">
      <div class="pdetails__card__head p-5 bg-gray-200 rounded">
        <h1 class="text-base font-medium">Practitioner Profile</h1>
      </div>
      <div class="pdetails__card__body bg-white p-5">
        <div class="image overflow-hidden flex">
          <img class="h-40 w-36 object-cover" :src="profile.photoMd ? profile.photoMd : '@/assets/home-background.jpg'" alt="" />
          <div class="ml-5">
            <h1 class="text-gray-900 font-medium text-lg leading-8 mb-1">{{ profile.firstName }} {{ profile.lastName }}</h1>
            <div class="text-gray-400 text-sm capitalize mb-1">{{ profile.gender }}</div>
            <div class="text-gray-400 text-sm">{{ formatedBirthdate }}</div>
          </div>
        </div>

        <div class="about mt-5">
          <h1 class="text-medium font-medium pb-3">About</h1>
          <hr />
          <p class="text-sm text-gray-500 hover:text-gray-600 leading-6 mt-5" v-html="profile.biography"></p>
        </div>

        <div class="qual mt-5">
          <h1 class="text-medium font-medium pb-3">Practise Area</h1>
          <hr />
          <ul class="list-inside list-disc space-y-2 mt-5 col-count-2">
            <li v-for="(type, i) in profile.practiceTypeList" :key="i">
              {{ type }}
            </li>
          </ul>
        </div>

        <div class="qual mt-5">
          <h1 class="text-medium font-medium pb-3">Assosiations</h1>
          <hr />
          <ul class="list-inside list-disc space-y-2 mt-5 col-count-2">
            <li v-for="(asso, i) in profile.associationsList" :key="i">
              {{ asso }}
            </li>
          </ul>
        </div>

        <div class="qual mt-5">
          <h1 class="text-medium font-medium pb-3">Qualitification</h1>
          <hr />
          <ul class="list-inside list-disc space-y-2 mt-5 col-count-2">
            <li v-for="(qual, i) in profile.qualificationsList" :key="i">
              {{ qual.qualification }} ({{ qual.institution }})
            </li>
          </ul>
        </div>

        <div class="qual mt-5">
          <h1 class="text-medium font-medium pb-3">Languages</h1>
          <hr />
          <ul class="list-inside list-disc space-y-2 mt-5 col-count-2">
            <li v-for="(lang, i) in profile.languageList" :key="i">
              {{ lang }}
            </li>
          </ul>
        </div>

        <div class="qual mt-5">
          <h1 class="text-medium font-medium pb-3">Insurance</h1>
          <hr />
          <ul class="list-inside list-disc space-y-2 mt-5 col-count-2">
            <li v-for="(ins, i) in profile.insuranceList" :key="i" class="capitalize">
              {{ ins }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import moment from 'moment';

const props = defineProps({
  profile: {
    type: Object,
    default: () => ({}),
  },
});

const addressData = computed(() => {
  if (!props.profile || !props.profile.address) return 'N/A';
  return props.profile.address;
});

const formatedBirthdate = computed(() => {
  if (!props.profile || !props.profile.birthDate) return 'N/A';
  return moment(props.profile.birthDate).format('MMM DD, YYYY');
});
</script>

<style>
.col-count-2 {
  column-count: 2;
}
</style>
