<template>
  <div class="pb-5">
    <h3 class="text-lg leading-6 font-medium text-gray-900 mb-5">Group Services</h3>
    <!-- <div class="mt-4 sm:mt-0 sm:ml-10">
      <nav class="-mb-px flex space-x-8">
        <a v-for="tab in filters" :key="tab.name" @click.prevent="activeTab = tab.slug" :class="[activeTab === tab.slug ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm']" :aria-current="tab.current ? 'page' : undefined">
          {{ tab.name }}
        </a>
      </nav>
    </div> -->
    <group-services-table
      :services="groupServices.servicesList"
      @to-page="changePageTo"
      @openSlide="openServicesSlide"
    ></group-services-table>

    <group-services-slide :open="openServices" :service="singleServices" @close="closeSlide"></group-services-slide>
    <!-- -->
  </div>
</template>

<script>
import {computed} from 'vue'
import moment from 'moment'
import { getServicesList } from "@/api/services";
import GroupServicesTable from "@/components/tables/group-services/GroupServicesTable";
import GroupServicesSlide from "@/components/pages/group-services/GroupServicesSlide";

const filters = [
  {
    name: 'Today',
    slug: "today",
    params: {
      dateStart: moment().format(moment.HTML5_FMT.DATE + ' 00:00:00'),
      dateEnd: moment().format(moment.HTML5_FMT.DATE + ' 23:59:59'),
      category: "all",
      orderBy: 'start_time',
      orderSort: 'asc',
      isGroup: true,
    }
  },
  {
    name: 'Tomorrow',
    slug: "tomorrow",
    params: {
      dateStart: moment().add(1, 'days').format(moment.HTML5_FMT.DATE + ' 00:00:00'),
      dateEnd: moment().add(1, 'days').format(moment.HTML5_FMT.DATE + ' 23:59:59'),
      category: "all",
      orderBy: 'start_time',
      orderSort: 'asc',
      isGroup: true,
    }
  },
  {
    name: 'Next 7 Days',
    slug: "this-week",
    params: {
      dateStart: moment().format(moment.HTML5_FMT.DATE + ' 00:00:00'),
      dateEnd: moment().add(7, 'days').format(moment.HTML5_FMT.DATE + ' 23:59:59'),
      category: "all",
      orderBy: 'start_time',
      orderSort: 'asc',
      isGroup: true,
    }
  },
  {
    name: 'One to One',
    slug: "one-to-one",
    params: {
      category: "upcoming",
      orderBy: 'start_time',
      orderSort: 'asc',
      isGroup: true
    }
  },
  {
    name: 'Group',
    slug: "group",
    params: {
      category: "upcoming",
      orderBy: 'start_time',
      orderSort: 'asc',
      isGroup: true,
    }
  },
  {
    name: 'Historical',
    slug: "historical",
    params: {
      category: "history",
      orderBy: 'start_time',
      orderSort: 'asc',
    }
  }
]

export default {
  name: "AppointmentsList",
  components: {GroupServicesTable, GroupServicesSlide},
  setup() {
    return {
      filters,
    }
  },
  data: () => {
    return {
      page: 1,
      activeTab: "today",
      groupServices: [],
      openServices: false,
      singleServices: null,
    }
  },
  provide() {
    return {
      currentPage: computed(() => this.page),
      total: computed(() => this.groupServices.total)
    }
  },
  mounted() {
    this.getGroupServices()
  },
  methods: {
    getGroupServices() {

      console.log(this.activeTab)

      let req = {
        page: this.page,
        isGroup: true
      }

      getServicesList(req).then((res) => {
        this.groupServices = res
      }).catch(() => {
        this.groupServices = []
      })

      // for (let i = 0; i < this.filters.length; i++) {
      //   if (this.filters[i].slug === this.activeTab) {
      //     this.filters[i].params.page = this.page
      //     getServicesList(this.filters[i].params).then((res) => {
      //       this.groupServices = res
      //     }).catch(() => {
      //       this.groupServices = []
      //     })
      //   }
      // }
    },
    openServicesSlide(data) {
      this.openServices = true
      this.singleServices = data
      console.log(data)

    },
    closeSlide() {
      this.openServices = false
    },

    changePageTo(page) {
      this.page = page
      this.getGroupServices()
    },
  },
  watch: {
    activeTab: function () {
      this.page = 1
      this.getGroupServices()
    }
  }
}
</script>
