<template>
  <div class="px-4 mt-6 sm:px-6 lg:px-8">
    <stats-today></stats-today>
  </div>
</template>

<script>
import StatsToday from "../components/pages/index/StatsToday";

export default {
  name: "Today",
  components: { StatsToday },
};
</script>
