import { getCurrentUser } from '../../api/auth';
import { getPractitionerBySlug, getPractitionerList } from '../../api/practitioner';
import { getInviteList, addAnonymousRecomendation, getImpersonateToken } from '@/api/admin';
import moment from 'moment';

const state = {
  user: null,
  practitionerList: [],
  practitioner: null,
  inviteList: [],
};

const getters = {
  isAuthenticated: (state) => !!state.user,
  stateUser: (state) => state.user,
  roles: (state) => (state.user ? state.user.rolesList : []),
  profile: (state) => (state.practitioner && state.practitioner.practitioner ? state.practitioner.practitioner : {}),

  userProfile: (state) => (state.practitioner && state.practitioner.user ? state.practitioner.user : {}),
  pracDetailsList: (state) => state.practitionerList,
  allInviteList: (state) => state.inviteList.invitationsList,
  practList: (state) => {
    return state.practitionerList && state.practitionerList.length ? state.practitionerList.map((item) => item.practitioner) : [];
  },

  pracRegThisWeek: (state, getters) => {
    if (!(getters.pracDetailsList || []).length) return [];
    return getters.pracDetailsList.filter((item) => {
      const date1 = new Date(item.user.createdAt);
      const date2 = new Date();
      const diffTime = Math.abs(date2 - date1);
      let weekMili = 7 * 24 * 60 * 60 * 1000;
      if (diffTime < weekMili) {
        return item.practitioner;
      }
    });
  },

  pracNotActive: (state, getters) => {
    if (!(getters.pracDetailsList || []).length) return [];
    return getters.pracDetailsList.filter((item) => {
      if (item.practitioner.lastActiveTime) {
        let currentTime = moment();
        let lastActiveTime = moment(item.practitioner.lastActiveTime.toString().slice(0, 19));
        let dayDiff = currentTime.diff(lastActiveTime, 'days');

        return dayDiff >= 7;
      }
    });
  },

  practNotInvited: (state, getters) => {
    if (!(getters.pracDetailsList || []).length) return [];
    if (!(getters.allInviteList || []).length) return [];
    return getters.pracDetailsList.filter((item) => getters.allInviteList.every((invite) => item.practitioner.id !== invite.inviterPractitionerId));
  },

  pracInvitedClients: (state, getters) => {
    if (!(getters.pracDetailsList || []).length) return [];
    if (!(getters.allInviteList || []).length) return [];
    let x = [];
    let practitionerInvitedThisWeek = getters.allInviteList.filter((invt) => {
      let currentTime = moment();
      let invitedTime = moment(invt.createdAt.toString().slice(0, 19));
      let dayDiff = currentTime.diff(invitedTime, 'days');
      return dayDiff <= 7;
    });
    // let practitionerInvitedThisWeek

    getters.pracDetailsList.forEach((item) => {
      let index = practitionerInvitedThisWeek.findIndex((invite) => item.practitioner.id === invite.inviterPractitionerId);

      if (index !== -1) x.push(item);
    });
    return x;
  },

  unreadUsersList: (state, getters, rootState, rootGetters) => {
    let unreadPractitioners = [];
    let unreadClients = [];

    if (!(getters.pracDetailsList || []).length) return [];
    if (!(rootGetters['clients/clients'] || []).length) return [];
    // if (this.practitioners && (this.practitioners.practitionersList || []).length) {
    let allClients = rootGetters['clients/clients'];
    getters.pracDetailsList.map((item) => {
      let obj = {};

      if (item.practitioner.unreadMessages > 0) {
        obj.name = `${item.practitioner.firstName} ${item.practitioner.lastName}`;
        obj.image = item.practitioner.photoXs;
        obj.id = item.practitioner.id;
        obj.unreadMessages = item.practitioner.unreadMessages;
        obj.lastActiveTime = item.practitioner.lastActiveTime;
        obj.type = 'Practitioner';
        unreadPractitioners.push(obj);
      }
    });
    // }

    // if (this.clients && (this.clients.clientsList || []).length) {
    allClients.map((item) => {
      let objClient = {};

      if (item.client.unreadMessages > 0) {
        objClient.name = `${item.client.firstName.charAt(0).toUpperCase()} ${item.client.lastName.charAt(0).toUpperCase()}`;
        objClient.image = item.client.photoXs;
        objClient.id = item.client.id;
        objClient.unreadMessages = item.client.unreadMessages;
        objClient.lastActiveTime = item.client.lastActiveTime;
        objClient.type = 'Client';

        unreadClients.push(objClient);
      }
    });
    // }

    let newArray = unreadPractitioners.concat(unreadClients);

    return newArray;
  },
};

const actions = {
  async GetCurrentUser({ commit }) {
    return getCurrentUser()
      .then((res) => {
        commit('setUser', res.user);
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  async AddRecommendation({ commit }, payload) {
    return addAnonymousRecomendation(payload)
      .then((res) => {
        // commit('setPractitionerList', res.practitionersList);
        // return Promise.resolve(res);
        console.log('res', res)
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  async GetPractitionerList({ commit }, payload) {
    return getPractitionerList(payload)
      .then((res) => {
        commit('setPractitionerList', res.practitionersList);
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  async GetPractitionerBySlug({ commit }, payload) {
    return getPractitionerBySlug(payload)
      .then((res) => {
        commit('setPractitioner', res.practitioner);
        console.log('res', res)
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  async GetPractitionersInviteList({ commit }, payload) {
    return getInviteList(payload)
      .then((res) => {
        commit('setInviteList', res);
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  async GetImpersonateToken({ commit }, payload) {
    return getImpersonateToken(payload)
      .then((res) => {
        commit('setInviteList', res);
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  // async UploadPicture(ctx, file) {
  //   try {
  //     console.log(file)
  //     const formData = new FormData()
  //     formData.append('target', 'user-photo')
  //     formData.append('file', file)

  //     console.log(formData)
  //     const { data } = await this.axios.post(`${process.env.VUE_APP_API_URL || 'https://augmentive.dev'}/upload`, formData)
  //     console.log('data', data)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  // async ApprovePractitioner(payload) {
  //   return approvePractitioner(payload).then((res) => {
  //     console.log("approve")
  //     return Promise.resolve(res)
  //   }).catch((err) => {
  //     console.log("ttt")
  //     console.log(err)
  //     return Promise.reject(err)
  //   })
  // },
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setPractitionerList(state, payload) {
    state.practitionerList = payload;
  },
  setPractitioner(state, payload) {
    state.practitioner = payload;
  },
  setInviteList(state, payload) {
    state.inviteList = payload;
  },
  LogOut(state) {
    state.user = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
