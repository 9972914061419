<template>
  <div class="pb-5">
    <h3 class="text-lg leading-6 font-medium text-gray-900 mb-4">
      Practitioner not active for 7 days
    </h3>
    <Table :columns="columns">
      <PractitionerNotActiveTableItems :items="practitioners" />
    </Table>
  </div>
</template>

<script>
import Table from "@/components/shared/Table";
import PractitionerNotActiveTableItems from "@/components/tables/practitioners/PractitionerNotActiveTableItems";

export default {
  name: "PractitionerSignupsToday",
  components: { Table, PractitionerNotActiveTableItems },
  data: () => ({
    columns: ['ID', 'Name', 'Inactive For', 'Timezone', 'Verified', 'Availability', 'More']
  }),
  props: {
    practitioners: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
