<template>
  <div class="pb-5">
    <h3 class="text-lg leading-6 font-medium text-gray-900">
      Appointments This Week
    </h3>
    <Table :columns="columns">
      <appointments-table-items :items="appointments"></appointments-table-items>
    </Table>
  </div>
</template>

<script>
// import {getAppointmentsList} from "@/api/appointment";
// import AppointmentsTable from "@/components/tables/appointments/AppointmentsTable";
import Table from "@/components/shared/Table";
import AppointmentsTableItems from "@/components/tables/appointments/AppointmentsTableItems";

export default {
  name: "AppointmentsToday",
  components: { Table, AppointmentsTableItems },
  data: () => ({
    columns: [
      "Practitioner",
      "Client ID",
      "When",
      "Price",
      "Fee",
      "Status",
      "Appointments/Capacity",
      "More",
    ],
  }),
  props: {
    appointments: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
