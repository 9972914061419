<template>
  <div class="pb-5">
    <h3 class="text-lg leading-6 font-medium text-gray-900 mb-5">Clients</h3>

    <div class="">
      <Table :columns="columns">
        <!-- <appointments-table-items :items="appointments"></appointments-table-items> -->
        <clients-table-items :clients="clients" @openDeleteModal="openDeleteModal"></clients-table-items>
      </Table>
      <table-pagination @to-page="changePageTo"></table-pagination>
    </div>
    <UserDeleteModal
      :open="userDeleteModal"
      :user="user"
      @close="closeDeleteModal"
    ></UserDeleteModal>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, provide } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';

import Table from '@/components/shared/Table';
import ClientsTableItems from '@/components/tables/clients/ClientsTableItems';
import TablePagination from '@/components/tables/TablePagination';
import UserDeleteModal from '@/components/pages/users/UserDeleteModal';
const page = ref(1);
const user = ref({});
const userDeleteModal = ref(false);
const columns = ['Client Initials', 'Client ID', 'Invitation Id', 'Received callback', 'Public Notes', 'More'];
const store = useStore();
const clients = computed(() => store.getters['clients/allClientDetails']);
const clientsTotal = computed(() => store.getters['clients/clientsTotal']);

const getClients = async () => {
  try {
    let req = {
      page: page.value,
      perPage: 20,
    };
    console.log('req', req)
    await store.dispatch('clients/GetClientList', req);
  } catch (err) {
    console.log('err', err);
  }
};

provide('currentPage', page)
provide('total', clientsTotal)

const changePageTo = (pageNmbr) => {
  page.value = pageNmbr
  getClients();
};

const openDeleteModal = (user) => {
  userDeleteModal.value = true;
  user.value = user;

};

const closeDeleteModal = () => {
  userDeleteModal.value = false;
};

onMounted(() => {
  getClients();
});
</script>
