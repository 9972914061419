import {createLogger, createStore} from 'vuex'
import app from "./modules/app";
import appointments from "./modules/appointments";
import auth from "./modules/auth";
import user from "./modules/user";
import practitioner from "./modules/practitioner";
import clients from "./modules/clients";
import content from "./modules/content";
import stream from "./modules/stream";

const debug = process.env.NODE_ENV !== 'production'


export default createStore({
  modules: {
    app,
    appointments,
    auth,
    user,
    practitioner,
    clients,
    content,
    stream
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})


