import {removeToken, setToken} from '../../utils/auth'
import {login} from "../../api/auth";

const state = {
    //
    user: null,
    practitioner: null
}

const getters = {
    //
}

const actions = {
    async LogIn({commit}, payload) {
        return login(payload).then((res) => {
            if (res.tokenpair) {
                console.log(res)
                setToken(res.tokenpair)
                // if(res.practitioner)  commit('setPractitioner', res.practitioner)
                commit('setUser', res.user)
            }

            return Promise.resolve(res)
        })
    },
    async FedLogOut({commit}) {
        removeToken()
        commit('LogOut')
    },
}

const mutations = {
    //
    // setPractitioner(state, payload) {
    //   state.practitioner = payload.practitioner
    // },
    setUser(state, payload) {
      state.user = payload
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
