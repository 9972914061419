<template>
  <div class="px-4 mt-6 sm:px-6 lg:px-8">
    <practitioner-ranking></practitioner-ranking>
  </div>
</template>

<script>
import PractitionerRanking from '@/components/pages/ranking/PractitionerRanking'

export default {
  name: "Ranking",
  components: {PractitionerRanking}
}
</script>
