import {UsersPromiseClient} from '@/plugins/grpc'
import {
  ListUsersRequest,
  ChangeRolesRequest,
  GetUserByIDRequest
} from "@mercury-labs1/augmentive-proto/users.messages_pb";
import {preparePagination} from "@/utils/preparePagination";

export async function getUserList(payload) {
  let req = new ListUsersRequest();

  let pagination = preparePagination(payload)
  if (pagination) {
    req.setPagination(pagination)
  }

  if (payload.query) {
    req.setQuery(payload.query)
  }
  if (payload.roles) {
    req.setRolesList(payload.roles)
  }
  console.log("ListUsersRequest: ", payload)

  return await UsersPromiseClient.list(req, {}).then((res) => {
    res = res.toObject()
    console.log("ListUsersResponse: ", res)
    return Promise.resolve(res)
  })
}

export async function changeRoles(payload) {
  let req = new ChangeRolesRequest();

  if (payload.id) {
    req.setId(payload.id)
  }
  if (payload.roles) {
    req.setRolesList(payload.roles)
  }
  console.log("ChangeRolesRequest: ", payload)

  return await UsersPromiseClient.changeRoles(req, {}).then((res) => {
    res = res.toObject()
    console.log("ChangeRolesResponse: ", res)
    return Promise.resolve(res)
  })
}


export async function getUserById(payload) {
  let req = new GetUserByIDRequest();
  console.log("ListUsersRequest: ", req)
  if(payload.id) req.setId(payload.id)

  return await UsersPromiseClient.getUserByID(req, {}).then((res) => {
    res = res.toObject()
    console.log("ListUsersResponse: ", res)
    return Promise.resolve(res)
  })
}
