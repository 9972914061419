<template>
  <div class="pb-5">
    <h3 class="text-lg leading-6 font-medium text-gray-900 mb-5">Appointments</h3>
    <div class="mt-4 sm:mt-0 sm:ml-10">
      <nav class="-mb-px flex space-x-8">
        <a v-for="tab in filters" :key="tab.name" @click.prevent="activeTab = tab.slug" :class="[activeTab === tab.slug ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm']" :aria-current="tab.current ? 'page' : undefined">
          {{ tab.name }}
        </a>
      </nav>
    </div>
    <appointments-table v-if="appointments?.appointmentsList?.length" :appointments="appointments.appointmentsList" @to-page="changePageTo" @openSlide="openAppointmentsSlide" @getAppointmentsList="getAppointments"></appointments-table>
    <appointments-slide :open="openAppointment" :appointment="singleAppointment" @markPaid="markPaid" @close="closeSlide"></appointments-slide>

    <AppointmentPaidModal :modalShow="modalShow" :appointment="selectedAppointment" @getAppointments="getAppointments" @close="closeModal"></AppointmentPaidModal>
  </div>
</template>

<script>
import { computed } from 'vue';
import moment from 'moment';
import { getAppointmentsList } from '@/api/appointment';
import AppointmentsTable from '@/components/tables/appointments/AppointmentsTable';
import AppointmentsSlide from '@/components/pages/appointments/AppointmentsSlide';
import AppointmentPaidModal from '@/components/pages/appointments/AppointmentPaidModal';

const filters = [
  {
    name: 'Today',
    slug: 'today',
    params: {
      dateStart: moment().format(moment.HTML5_FMT.DATE + ' 00:00:00'),
      dateEnd: moment().format(moment.HTML5_FMT.DATE + ' 23:59:59'),
      category: 'all',
      orderBy: 'start_time',
      orderSort: 'asc',
      isIndividual: true,
    },
  },
  {
    name: 'Tomorrow',
    slug: 'tomorrow',
    params: {
      dateStart: moment()
        .add(1, 'days')
        .format(moment.HTML5_FMT.DATE + ' 00:00:00'),
      dateEnd: moment()
        .add(1, 'days')
        .format(moment.HTML5_FMT.DATE + ' 23:59:59'),
      category: 'all',
      orderBy: 'start_time',
      orderSort: 'asc',
      isIndividual: true,
    },
  },
  {
    name: 'Next 7 Days',
    slug: 'this-week',
    params: {
      dateStart: moment().format(moment.HTML5_FMT.DATE + ' 00:00:00'),
      dateEnd: moment()
        .add(7, 'days')
        .format(moment.HTML5_FMT.DATE + ' 23:59:59'),
      category: 'all',
      orderBy: 'start_time',
      orderSort: 'asc',
      isIndividual: true,
    },
  },
  {
    name: 'Upcoming Appointments',
    slug: 'upcoming-appointments',
    params: {
      // dateStart: moment().format(moment.HTML5_FMT.DATE) + ' ' + moment().add(2, 'minutes').format(moment.HTML5_FMT.TIME_SECONDS),
      // dateEnd: moment()
      //   .add(7, 'days')
      //   .format(moment.HTML5_FMT.DATE + ' 23:59:59'),
      category: 'pendingUpcoming',
      orderBy: 'start_time',
      orderSort: 'asc'
    },
  },
  {
    name: 'One to One',
    slug: 'one-to-one',
    params: {
      category: 'upcoming',
      orderBy: 'start_time',
      orderSort: 'asc',
      isIndividual: true,
    },
  },
  {
    name: 'Group',
    slug: 'group',
    params: {
      category: 'upcoming',
      orderBy: 'start_time',
      orderSort: 'asc',
      isGroup: true,
    },
  },
  {
    name: 'Historical',
    slug: 'historical',
    params: {
      category: 'history',
      orderBy: 'start_time',
      orderSort: 'asc',
    },
  },
];

export default {
  name: 'AppointmentsList',
  components: { AppointmentsTable, AppointmentsSlide, AppointmentPaidModal },
  setup() {
    return {
      filters,
    };
  },
  data: () => {
    return {
      page: 1,
      activeTab: 'today',
      appointments: null,
      openAppointment: false,
      singleAppointment: false,
      modalShow: false,
      selectedAppointment: null,
    };
  },
  provide() {
    return {
      currentPage: computed(() => this.page),
      total: computed(() => this.appointments.total),
    };
  },
  async mounted() {
    await this.getAppointments();
  },
  methods: {
    async getAppointments() {
      for (let i = 0; i < this.filters.length; i++) {
        if (this.filters[i].slug === this.activeTab) {
          this.filters[i].params.page = this.page;
          await getAppointmentsList(this.filters[i].params)
            .then((res) => {
              console.log('This is running')
              console.log("🚀 ~ file: Appointments.vue:149 ~ .then ~ res:", res)
              this.appointments = res;

            })
            .catch(() => {
              this.appointments = null;
            });
        }
      }
    },
    openAppointmentsSlide(data) {
      this.openAppointment = true;
      this.singleAppointment = data;
      console.log(data);
    },
    closeSlide() {
      this.openAppointment = false;
    },

    changePageTo(page) {
      this.page = page;
      this.getAppointments();
    },
    markPaid(appt) {
      this.selectedAppointment = appt;
      this.modalShow = true;
      this.closeSlide();
    },
    closeModal() {
      this.modalShow = false;
    },
  },
  watch: {
    activeTab: function () {
      this.page = 1;
      this.getAppointments();
    },
  },
};
</script>
