<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true" v-if="modalShow">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

      <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
        <form action="">
          <div class="mb-4">
            <label for="title" class="block text-sm font-medium text-gray-700">Semble Group Access Name</label>
            <div class="mt-1">
              <input type="text" v-model="form.sembleAccessGroupName" name="title" id="title" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Semble Group Access Name" />
            </div>
          </div>
        </form>
        <!-- <div>
            <div class="flex w-full flex-col items-center bg-grey-lighter">
                <div class="flex flex-col justify-center items-center" v-if="practitioner && practitioner.photo">
                  <img :src="practitioner.photo ? practitioner.photo : ''" alt="" id="blah" class="w-40 h-40 rounded-full shadow-md object-cover mb-5"/>
                  <button type="button" class="inline-flex w-10 h-10 justify-center items-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm mb-3" @click="removePhoto">
                    <TrashIcon class="flex-shrink-0 w-4" aria-hidden="true"/>
                  </button>
                </div>
  
                <label class="w-64 flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer">
                    <UploadIcon class="flex-shrink-0 mr-1.5 h-9 w-9" aria-hidden="true"/>
                    <span class="mt-2 text-base leading-normal">Upload Profile Picture</span>
                    <input type='file' class="hidden" @input="onUpload" ref="file"/>
                </label>
            </div>
          </div> -->
        <div class="mt-5 sm:mt-6 flex">
          <button type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm mr-3" @click="saveData">Save</button>
          <button type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-500 text-base font-medium text-white hover:bg-gray-600 sm:text-sm" @click="close">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { uploadPhoto } from '@/api/practitioner';
import { UploadIcon, TrashIcon } from '@heroicons/vue/solid';

export default {
  name: 'PractitionerModalShow',
  components: {},
  props: ['modalShow', 'practitioner'],
  emits: ['closeModal'],
  setup() {
    const form = ref({
      sembleAccessGroupName: '',
    });
    return {
      form,
    };
  },

  computed: {
  },

  methods: {
    close() {
      this.$emit('closeModal');
    },

    saveData() {
        this.$emit('saveData', this.form, this.practitioner);
        this.form.sembleAccessGroupName = '';
    },
  },
};
</script>
