<template>
  <nav v-if="pagesCount > 1" class="px-4 flex items-center justify-between sm:px-0">
    <div class="-mt-px w-0 flex-1 flex">
      <a v-if="selectedPage !== 1" @click.prevent="changePageTo(selectedPage - 1)" href="#" class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
        <ArrowNarrowLeftIcon class="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
        Previous
      </a>
    </div>
    <div class="hidden md:-mt-px md:flex">
      <template v-if="pagesCount < 3">
        <a v-for="i in pagesCount" :key="'pagination-' + i" @click.prevent="changePageTo(i)" href="#" :class="selectedPage === i ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500'" class="hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">
          {{ i }}
        </a>
      </template>
      <template v-else>
        <span v-if="selectedPage >= 3" class="border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">...</span>
        <a v-if="selectedPage >= 2" @click.prevent="changePageTo(selectedPage - 1)" href="#" class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">
          {{ selectedPage - 1 }}
        </a>
        <a @click.prevent="" href="#" class="border-indigo-500 text-indigo-600 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">
          {{ selectedPage }}
        </a>
        <a v-if="selectedPage <= pagesCount - 1" @click.prevent="changePageTo(selectedPage + 1)" href="#" class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">
          {{ selectedPage + 1 }}
        </a>
        <span v-if="selectedPage <= pagesCount - 2" class="border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">...</span>
      </template>
    </div>
    <div class="-mt-px w-0 flex-1 flex justify-end">
      <a v-if="selectedPage !== pagesCount" @click.prevent="changePageTo(selectedPage + 1)" href="#" class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
        Next
        <ArrowNarrowRightIcon class="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
      </a>
    </div>
  </nav>
</template>

<script>
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/vue/solid';

export default {
  name: 'TablePagination',
  components: {
    ArrowNarrowLeftIcon,
    ArrowNarrowRightIcon,
  },
  emits: ['to-page'],
  inject: ['currentPage', 'total'],
  data: () => {
    return {
      perPage: 10,
    };
  },
  methods: {
    changePageTo(page) {
      console.log("🚀 ~ file: TablePagination.vue:57 ~ changePageTo ~ page:", page)
      this.$emit('to-page', page);
    },
  },
  computed: {
    pagesCount() {
      console.log("🚀 ~ file: TablePagination.vue:63 ~ pagesCount ~ pagesCount:", this.total.value)
      console.log("🚀 ~ file: TablePagination.vue:65 ~ pagesCount ~ currentPage:", this.currentPage.value)
      let total = this.total?.value ? this.total.value : this.total;
      const pagesCount = Math.ceil(total / this.perPage);
      console.log("🚀 ~ file: TablePagination.vue:66 ~ pagesCount ~ pagesCount:", pagesCount)
      
      const currentPage = this.currentPage?.value ? this.currentPage.value : this.currentPage;
      
      if (pagesCount < currentPage) this.changePageTo(pagesCount);
      return pagesCount;
    },

    selectedPage() {
      return this.currentPage?.value ? this.currentPage.value : this.currentPage;
    },
  },

  mounted() {
    // console.log('---------------------Alllll-------------------')
    // console.log('this.currentPage', this.currentPage)
  },
};
</script>
