<template>
  <div class="flex flex-col flex-grow border-r border-gray-200 pt-0 pb-0 overflow-y-auto">
    <div class="flex items-center flex-shrink-0 px-0 space-y-5">
      <img class="h-12 w-auto" src="@/assets/augmentive.png" alt="Augmentive" />
    </div>
    <div class="mt-2 flex-grow flex flex-col">
      <nav class="flex-1 space-y-1" aria-label="Sidebar">
        <template v-for="item in navigation" :key="item.name">
          <hr v-if="item.name === 'Divider'" class="border-t border-gray-200 my-5" aria-hidden="true" />
          <a v-else @click.prevent="$router.push(item.href)" href="#" :class="[$route.path === item.href ? 'bg-indigo-50 border-indigo-600 text-indigo-600' : 'border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-3 py-2 text-sm font-medium border-l-4']">
            <component :is="item.icon" :class="[$route.path === item.href ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-3 h-6 w-6']" aria-hidden="true" />
            {{ item.name }}
          </a>
        </template>
      </nav>
    </div>
    <div class="flex-shrink-0 flex border-t border-gray-200 p-4">
      <a @click.prevent="" href="#" class="flex-shrink-0 w-full group block">
        <div class="flex items-center">
          <div class="mr-2 inline-block h-9 w-9 rounded-full user-img-custom"></div>
          <div>
            <p class="text-sm font-medium text-gray-700 group-hover:text-gray-900">
              {{ stateUser.name }}
            </p>
            <p class="text-xs font-medium text-gray-500 group-hover:text-gray-700">
              {{ stateUser.email }}
            </p>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { HomeIcon, LogoutIcon, CollectionIcon, ClipboardListIcon, PresentationChartBarIcon, UserCircleIcon, SwitchVerticalIcon, UserGroupIcon, BookmarkIcon, BellIcon, DocumentIcon } from '@heroicons/vue/outline';
import { mapGetters } from 'vuex';

const navigation = [
  { name: 'Overview', icon: HomeIcon, href: '/overview' },
  { name: 'Real Time', icon: BellIcon, href: '/real-time' },
  { name: 'Practitioners', icon: CollectionIcon, href: '/practitioners' },
  { name: 'Clients', icon: CollectionIcon, href: '/clients' },
  { name: 'Ranking', icon: SwitchVerticalIcon, href: '/ranking' },
  { name: 'Appointments', icon: ClipboardListIcon, href: '/appointments' },
  // {name: 'Group Services', icon: UserGroupIcon, href: '/group-services'},
  { name: 'Users', icon: UserCircleIcon, href: '/users' },
  { name: 'Analytics', icon: PresentationChartBarIcon, href: '/analytics' },
  { name: 'Warehouse', icon: PresentationChartBarIcon, href: '/warehouse' },
  { name: 'Landing Page', icon: BookmarkIcon, href: '/landing-page' },
  { name: 'Terms & Condition', icon: DocumentIcon, href: '/terms-condition' },

  { name: 'Divider' },
  { name: 'Logout', icon: LogoutIcon, href: '/logout' },
];

export default {
  name: 'Sidebar',
  setup() {
    return {
      navigation,
    };
  },
  computed: {
    ...mapGetters({
      stateUser: 'user/stateUser',
    }),
  },
};
</script>
