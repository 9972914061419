import {
  GetLandingPageListRequest,
  GetLandingPageRequest,
  CreateLandingPageRequest,
  UpdateLandingPageRequest,
  DeleteLandingPageRequest
} from "@mercury-labs1/augmentive-proto/content.messages_pb.js";
import {
  ContentPromiseClient,
} from '@/plugins/grpc';

export async function getContentList(payload) {
  let req = new GetLandingPageListRequest();

  return await ContentPromiseClient.getLandingPageList(req, {}).then((res) => {
    res = res.toObject()
    console.log("ListLandingPage: ", res)
    return Promise.resolve(res)
  })
}

export async function getSingleLandingPage(payload) {
  let req = new GetLandingPageRequest();
  if(payload.category) req.setCategory(payload.category)
  if(payload.name) req.setName(payload.name)

  return await ContentPromiseClient.getLandingPage(req, {}).then((res) => {
    res = res.toObject()
    console.log("SingleLandingPage: ", res)
    return Promise.resolve(res)
  })
}

export async function createContent(payload) {
  let req = new CreateLandingPageRequest();

  console.log('req', req)
  console.log('api payload', payload)

  if(payload.title) req.setTitle(payload.title)
  if(payload.name) req.setName(payload.name)
  if(payload.description) req.setDescription(payload.description)
  if(payload.category) req.setCategory(payload.category)
  if(payload.keywords) req.setKeywords(payload.keywords)
  if(payload.content) req.setContent(payload.content)
  if((payload.practitioner || []).length) req.setPractitionerIdsList(payload.practitioner)

  return await ContentPromiseClient.createLandingPage(req, {}).then((res) => {
    res = res.toObject()
    console.log("Create Landing Page: ", res)
    return Promise.resolve(res)
  })
}

export async function updateContent(payload) {
  let req = new UpdateLandingPageRequest();

  console.log('req', req)
  console.log('api payload', payload)

  if(payload.id) req.setId(payload.id)
  if(payload.title) req.setTitle(payload.title)
  if(payload.name) req.setName(payload.name)
  if(payload.description) req.setDescription(payload.description)
  if(payload.category) req.setCategory(payload.category)
  if(payload.keywords) req.setKeywords(payload.keywords)
  if(payload.content) req.setContent(payload.content)
  if((payload.practitioner || []).length) req.setPractitionerIdsList(payload.practitioner)

  // return await ContentPromiseClient.createLandingPage(req, {}).then((res) => {
  //   res = res.toObject()
  //   console.log("Create Landing Page: ", res)
  //   return Promise.resolve(res)
  // })
}

export async function deleteContentByID(payload) {
  let req = new DeleteLandingPageRequest();
  console.log('req', req)
  if(payload) req.setId(payload)
  return await ContentPromiseClient.deleteLandingPage(req, {}).then((res) => {
    res = res.toObject()
    console.log("DeleteLandingPage: ", res)
    return Promise.resolve(res)
  })
}
