<template>
  <div class="">
    <practitioner-profile></practitioner-profile>
  </div>
  <!-- <div class="bg-gray-100" v-if="profile">
    <div class="container mx-auto my-5 p-5">
      <div class="md:flex no-wrap md:-mx-2">
        <div class="w-full md:w-3/12 md:mx-2">
          <div class="bg-white p-3 border-t-4 border-green-400">
            <div class="image overflow-hidden">
              <img
                class="h-auto max-h-96 w-full mx-auto object-cover"
                :src="
                  profile.photoMd
                    ? profile.photoMd
                    : '@/assets/home-background.jpg'
                "
                alt=""
              />
            </div>
            <h1 class="text-gray-900 font-bold text-xl leading-8 my-1">
              {{ profile.firstName }} {{ profile.lastName }}
            </h1>
            <p
              class="text-sm text-gray-500 hover:text-gray-600 leading-6"
              v-html="profile.biography"
            ></p>
          </div>
          <div class="my-4"></div>
        </div>
        <div class="w-full md:w-9/12 mx-2 h-64">
          <div class="bg-white p-3 shadow-sm rounded-sm">
            <div
              class="flex items-center space-x-2 font-semibold text-gray-900 leading-8"
            >
              <span clas="text-green-500">
                <svg
                  class="h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  />
                </svg>
              </span>
              <span class="tracking-wide">About</span>
            </div>
            <div class="text-gray-700">
              <div class="grid md:grid-cols-2 text-sm">
                <div class="grid grid-cols-2">
                  <div class="px-4 py-2 font-semibold">First Name</div>
                  <div class="px-4 py-2">{{ profile.firstName }}</div>
                </div>
                <div class="grid grid-cols-2">
                  <div class="px-4 py-2 font-semibold">Last Name</div>
                  <div class="px-4 py-2">{{ profile.lastName }}</div>
                </div>
                <div class="grid grid-cols-2">
                  <div class="px-4 py-2 font-semibold">Gender</div>
                  <div class="px-4 py-2">{{ profile.gender }}</div>
                </div>
                <div class="grid grid-cols-2">
                  <div class="px-4 py-2 font-semibold">Contact No.</div>
                  <div class="px-4 py-2">{{ addressData.phoneNumber }}</div>
                </div>
                <div class="grid grid-cols-2">
                  <div class="px-4 py-2 font-semibold">Current Address</div>
                  <div class="px-4 py-2">{{ addressData.line1 }}, {{ addressData.town }}</div>
                </div>
                <div class="grid grid-cols-2">
                  <div class="px-4 py-2 font-semibold">Birthday</div>
                  <div class="px-4 py-2">{{ formatedBirthdate }}</div>
                </div>
                <div class="grid grid-cols-2">
                  <div class="px-4 py-2 font-semibold">Email.</div>
                  <div class="px-4 py-2">
                    <a class="text-blue-800" :href="`mailto:${userProfile.email}`"
                      >{{ userProfile.email }}</a>

                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="my-4"></div>
          <div class="bg-white p-3 shadow-sm rounded-sm">
            <div class="grid grid-cols-2">
              <div>
                <div
                  class="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3"
                >
                  <span clas="text-green-500">
                    <svg
                      class="h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                      />
                    </svg>
                  </span>
                  <span class="tracking-wide">Practice Type</span>
                </div>
                <ul class="list-inside list-disc space-y-2">
                  <li v-for="(type,i) in profile.practiceTypeList" :key="i">
                    {{ type }}
                  </li>
                </ul>
              </div>
              <div>
                <div
                  class="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3"
                >
                  <span clas="text-green-500">
                    <svg
                      class="h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z" />
                      <path
                        fill="#fff"
                        d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                      />
                    </svg>
                  </span>
                  <span class="tracking-wide">Qualifictions</span>
                </div>
                <ul class="list-inside space-y-2">
                  <li>
                    <div class="text-teal-600">Masters Degree in Oxford</div>
                    <div class="text-gray-500 text-xs">March 2020 - Now</div>
                  </li>
                  <li>
                    <div class="text-teal-600">Bachelors Degreen in LPU</div>
                    <div class="text-gray-500 text-xs">March 2020 - Now</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from 'moment'

import PractitionerProfile from '@/components/pages/practitioners/profile/PractitionerProfile'

export default {
  components: {
    PractitionerProfile
  },

  computed: {
    ...mapGetters({
      profile: "practitioner/profile",
      userProfile: "practitioner/userProfile",
    }),

    onBoadingStatus() {
      if (this.profile && this.profile.lastActiveTime) {
        let currentTime = moment();
        let lastActiveTime = moment(
          this.profile.lastActiveTime.toString().slice(0, 19)
        );
        let dayDiff = currentTime.diff(lastActiveTime, "days");

        // console.log(dayDiff)

        if (dayDiff <= 1) {
          return {
            status: "Active",
            info: "active",
          };
        } else {
          return {
            status: `Inactive for ${dayDiff} days`,
            info: "inactive-days",
          };
        }
      } else {
        return {
          status: "Inactive",
          info: "inactive",
        };
      }
    },

    addressData() {
      if(!this.profile || !this.profile.address) return 'N/A'
      return this.profile.address
    },
    formatedBirthdate() {
      if(!this.profile || !this.profile.birthDate) return 'N/A'
      return moment(this.profile.birthDate).format('MMM DD, YYYY')
    }
  },
  methods: {
    ...mapActions({
      getSinglePractitioner: "practitioner/GetPractitionerBySlug",
    }),

    async getPractitioner() {
      await this.getSinglePractitioner(this.$route.params.slug);
    },


  },

  mounted() {
    // this.getPractitioner();
  },
};
</script>

<style></style>
