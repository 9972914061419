<template>
  <div class="px-4 mt-6 sm:px-6 lg:px-8">
    <practitioners></practitioners>
  </div>
</template>

<script>
import Practitioners from '@/components/pages/practitioners/Practitioners'

export default {
  name: "PractitionersIndex",
  components: {Practitioners}
}
</script>
