<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" static class="fixed z-10 inset-0 overflow-y-auto" @close="$emit('close')" :open="open">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div>
              <div class="mt-3 text-left sm:mt-0">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900"> Delete User </DialogTitle>
                <div class="mt-2 text-left">
                  Are tou sure you want to delete <b>{{ user.name }}</b
                  >?
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button type="button" class="btn-primary w-full sm:col-start-2 sm:text-sm" :loading="loading" @click="deleteAdminPermissions">Delete</button>
              <button type="button" class="btn-white mt-3 w-full sm:mt-0 sm:col-start-1 sm:text-sm" @click="$emit('close')" ref="cancelButtonRef">Cancel</button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue';
import { mapActions } from 'vuex';
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { changeRoles } from '@/api/user';

export default {
  name: 'DeleteUserModal',
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false
    },
    user: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    loading: false
  }),

  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },
  emits: ['update-list'],
  methods: {
    ...mapActions({
      deleteUser: 'user/DeleteUser',
    }),
    async deleteAdminPermissions() {
      try {
        this.loading = true;
        await this.deleteUser(this.user.id);
      } catch (err) {
        console.log("🚀 ~ file: UserDeleteModal.vue:74 ~ deleteAdminPermissions ~ err", err)
      }finally {
        this.loading = false;
        this.$emit('close');
      }

    },
  },
};
</script>
