<template>
  <div class="flex flex-col mt-0 sm:px-8">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <appointments-table-header></appointments-table-header>
            <appointments-table-items :items="appointments"  @open="open" @getAppointments="$emit('getAppointmentsList')"></appointments-table-items>
          </table>
        </div>
      </div>
    </div>
    <table-pagination @to-page="changePageTo" v-if="$route.name !== 'Overview'"></table-pagination>
  </div>
</template>

<script>
import AppointmentsTableHeader from "@/components/tables/appointments/AppointmentsTableHeader";
import AppointmentsTableItems from "@/components/tables/appointments/AppointmentsTableItems";
import TablePagination from "@/components/tables/TablePagination";

export default {
  name: "AppointmentsTable",
  components: {TablePagination, AppointmentsTableItems, AppointmentsTableHeader},
  emits: ["to-page", "openSlide", "getAppointmentsList"],
  props: {
    appointments: {
      type: Array,
      default: () => []
    },
  },

  mounted() {
    console.log('this.$route', this.$route)
  },
  methods: {
    changePageTo(page) {
      this.$emit("to-page", page)
    },

    open(data) {
      this.$emit('openSlide', data)
    }
  }
}
</script>
