<template>
  <div class="flex flex-col mt-0 sm:px-8">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <practitioners-table-header></practitioners-table-header>
            <practitioners-table-items :items="practitioners"
              @getPractitioners='getPractitionersTable'
              @openSlider="openProfileSlider"></practitioners-table-items>
          </table>
        </div>
      </div>
    </div>
    <table-pagination @to-page="changePageTo" v-if="$route.name !== 'Overview'"></table-pagination>
  </div>
</template>

<script>

import PractitionersTableHeader from "@/components/tables/practitioners/PractitionersTableHeader";
import PractitionersTableItems from "@/components/tables/practitioners/PractitionersTableItems";
import TablePagination from "@/components/tables/TablePagination";

export default {
  name: "PractitionersTable",
  components: {
    TablePagination,
    PractitionersTableItems,
    PractitionersTableHeader
  },
  emits: ["to-page", "getPractitioners", 'openSlider'],
  props: {
    practitioners: {
      type: Array,
      default: function() {
        return []
      }
    },
  },
  methods: {
    changePageTo(page) {
      this.$emit("to-page", page)
    },
    getPractitionersTable() {
      console.log('Running')
      this.$emit('getPractitioners')
    },
    openProfileSlider(data) {
      this.$emit('openSlider', data)
    }
  }
}
</script>
