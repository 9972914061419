<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="">
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="fixed inset-0 overflow-hidden" @close="closeSlider">
        <div class="absolute inset-0 overflow-hidden">
          <DialogOverlay class="absolute inset-0" />

          <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <div class="w-screen max-w-2xl">
                <div class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div class="px-4 py-6 sm:px-6">
                    <div class="flex items-start justify-between">
                      <h2 id="slide-over-heading" class="text-lg font-medium text-gray-900">Profile</h2>
                      <div class="ml-3 h-7 flex items-center">
                        <button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500" @click="closeSlider">
                          <span class="sr-only">Close panel</span>
                          <XIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- Main -->
                  <div>
                    <div class="pb-1 sm:pb-6">
                      <div>
                        <div class="relative h-40 sm:h-56">
                          <img class="absolute h-full w-full object-cover" :src="profileImg ? profileImg : 'https://images.pexels.com/photos/1090972/pexels-photo-1090972.jpeg?cs=srgb&dl=pexels-irina-iriser-1090972.jpg&fm=jpg'" alt="" />

                        </div>
                        <div class="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6">
                          <div class="sm:flex-1">
                            <div class="flex justify-between">
                              <div class="flex items-center">
                                <h3 class="font-bold text-xl text-gray-900 sm:text-2xl">{{ name }}</h3>
                              </div>
                              <div class="">
                                <span class="ml-3 inline-flex sm:ml-0">
                                  <Menu as="div" class="relative inline-block text-left">
                                    <MenuButton class="inline-flex items-center p-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-400 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                      <span class="sr-only">Open options menu</span>
                                      <DotsVerticalIcon class="h-5 w-5" aria-hidden="true" />
                                    </MenuButton>
                                    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                                      <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div class="py-1">
                                          <MenuItem>
                                            <p :class="['text-gray-700', 'block px-4 py-2 text-sm cursor-pointer']" @click="openEditModal(practitioner)">{{ profileImg ? 'Change Profile Picture' : 'Upload Profile Picture' }}</p>
                                          </MenuItem>
                                          <MenuItem v-if="!isAdmin">
                                            <p :class="['text-gray-700', 'block px-4 py-2 text-sm cursor-pointer']" @click="openApproveModal(user)">Made admin</p>
                                          </MenuItem>
                                          <MenuItem v-else>
                                            <p :class="['text-gray-700', 'block px-4 py-2 text-sm cursor-pointer']" @click="openRemoveAdminModal(user)">Remove admin</p>
                                          </MenuItem>
                                          <MenuItem>
                                            <p :class="['text-gray-700', 'block px-4 py-2 text-sm cursor-pointer']" @click="impersonateUser(user)">Impersonate User</p>
                                          </MenuItem>
                                          <MenuItem v-if="isClinician">
                                            <p :class="['text-gray-700', 'block px-4 py-2 text-sm cursor-pointer']" @click="openSembleGroupModal">Update Semble Access Group</p>
                                          </MenuItem>
                                          <MenuItem>
                                            <p :class="['text-red-600', 'block px-4 py-2 text-sm cursor-pointer']" @click="deleteAnyUser(user)">Delete User</p>
                                          </MenuItem>
                                        </div>
                                      </MenuItems>
                                    </transition>
                                  </Menu>
                                </span>
                              </div>
                            </div>
                            <div class="mt-3 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">
                              <span class="ml-3 inline-flex sm:ml-0"> </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
                      <dl class="space-y-8 px-4 mb-5 sm:px-6 sm:space-y-6">
                        <div>
                          <dt class="text-sm font-medium text-gray-500 sm:flex-shrink-0">Upcoming Appointments</dt>
                          <dd class="mt-3 text-sm text-gray-900 sm:col-span-2">
                            <div class="bg-white shadow overflow-hidden sm:rounded-md">
                              <ul role="list" class="divide-y divide-gray-200" v-if="(upcoming || []).length">
                                <li v-for="(item, i) in upcoming" :key="i">
                                  <a href="#" class="block hover:bg-gray-50">
                                    <div class="px-4 py-4 sm:px-6">
                                      <div class="flex items-center justify-between">
                                        <p class="text-sm font-medium text-indigo-600 truncate" v-if="item.groupService && item.groupService.title">
                                          {{ item.groupService.title }}
                                        </p>
                                      </div>
                                      <div class="mt-2 sm:flex sm:justify-between">
                                        <div class="sm:flex">
                                          <p class="flex items-center text-sm text-gray-500">
                                            <ClockIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            {{ formatDate(item.appointment.startTime) }}
                                          </p>
                                          <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                                            <UserIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            {{ item.client.id }}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              </ul>
                              <p v-else class="text-sm text-gray-400 px-4 py-4 sm:px-6">There is no upcoming appointments</p>
                            </div>
                          </dd>
                        </div>
                      </dl>

                      <dl class="space-y-8 px-4 sm:px-6 sm:space-y-6">
                        <div>
                          <dt class="text-sm font-medium text-gray-500 sm:flex-shrink-0">Services Created</dt>
                          <dd class="mt-3 text-sm text-gray-900 sm:col-span-2">
                            <div class="bg-white shadow overflow-hidden sm:rounded-md">
                              <ul role="list" class="divide-y divide-gray-200" v-if="(servicesCreated || []).length">
                                <li v-for="(item, i) in servicesCreated" :key="i">
                                  <a href="#" class="block hover:bg-gray-50">
                                    <div class="px-4 py-4 sm:px-6">
                                      <div class="flex items-center justify-between">
                                        <p class="text-sm font-medium text-indigo-600 truncate" v-if="item.title">
                                          {{ item.title }}
                                        </p>
                                        <div class="ml-2 flex-shrink-0 flex">
                                          <p class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                            {{ item.priceFormatted }}
                                          </p>
                                        </div>
                                      </div>
                                      <div class="mt-1 sm:flex sm:justify-between">
                                        <div class="sm:flex">
                                          <p class="flex items-center text-sm text-gray-500">
                                            {{ item.category ? 'Group' : 'Individual' }}
                                          </p>
                                          <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6" v-if="item.eventsList">
                                            <CalendarIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            {{ item.eventsList.length ? item.eventsList.length : 0 }}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              </ul>
                              <p v-else class="text-sm text-gray-400 px-4 py-4 sm:px-6">There is no services created</p>
                            </div>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
// import { ref } from 'vue'
import moment from 'moment';
import { mapActions } from 'vuex';

import { Dialog, DialogOverlay, TransitionChild, TransitionRoot, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';
import { ClockIcon, UserIcon, CalendarIcon, DotsVerticalIcon } from '@heroicons/vue/solid';

export default {
  name: 'PractitionerSlider',
  props: ['open', 'practitioner', 'upcoming', 'close', 'user'],
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    XIcon,
    ClockIcon,
    UserIcon,
    CalendarIcon,
    DotsVerticalIcon,
  },

  methods: {
    ...mapActions({
      getToken: 'practitioner/GetImpersonateToken',

    }),
    closeSlider() {
      this.$emit('close');
    },
    formatDate(date) {
      return moment(date).format('Do-MMMM, h:mm:ss a');
    },
    openEditModal(item) {
      this.$emit('openModal', item);
    },
    openApproveModal(data) {
      this.$emit('openApprove', data);
    },

    openRemoveAdminModal() {
      this.$emit('openRemoveAdmin');
    },

    impersonateUser(data) {
      this.getToken(data.id).then((res) => {
        if (res.authToken) {
          // console.log("🚀 ~ file: PractitionersSlide.vue:220 ~ this.getToken ~ res.authToken", res.authToken)
          // window.open(`http://localhost:3000/login?impersonate=${res.authToken}`, '_blank');
          window.open(`${process.env.VUE_APP_API_URL}/login?impersonate=${res.authToken}`, '_blank');
        }
      });
    },

    openSembleGroupModal() {
      this.$emit('openSembleModal');
    },

    deleteAnyUser(data) {
      this.$emit('openDeleteModal', data)
      // this.deleteUser(data.id).then((res) => {
      //   console.log('🚀 ~ file: PractitionersSlide.vue:227 ~ this.getToken ~ res', res);
      // });
    },
  },

  computed: {
    name() {
      return this.practitioner.firstName + ' ' + this.practitioner.lastName;
    },
    profileImg() {
      return this.practitioner.photoMd;
    },
    servicesCreated() {
      let groupService = this.practitioner.groupServicesList;
      let individualService = this.practitioner.servicesList;

      let totalServices = groupService.concat(individualService);

      return totalServices;
    },
    isAdmin() {
      if (!this.user && !(this.user.roleList.length || []).length) return false;
      return this.user.rolesList.includes('ADMIN');
    },

    isClinician() {
      return this.practitioner.isClinician
    }, 
  },
};
</script>
