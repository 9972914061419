import {createApp} from 'vue'
import App from './App.vue'
import './styles/index.css'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import moment from 'moment'


import './utils/permission'
import './plugins/grpc'
import './plugins/axios'

const app = createApp(App)

app.use(store)
app.use(router)
app.use(VueAxios, axios)
app.use(require('moment'))
app.use(require('moment-timezone'))
app.provide('axios', app.config.globalProperties.axios)
app.component('v-select', vSelect)

app.mount('#app')
