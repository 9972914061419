<template>
  <div class="pb-5">
    <h3 class="text-lg leading-6 font-medium text-gray-900 mb-4">
      Practitioner Not Responded
    </h3>
    <Table :columns="columns">
      <PractitionerNotRespondedItems :items="appointments" />
      <!-- <practitioners-table-items
        :items="practitioners"
        @getPractitioners="getPractitioners"
        @openSlider="openProfileSlider"
      ></practitioners-table-items> -->
    </Table>
  </div>
</template>

<script>
import Table from "@/components/shared/Table";
import PractitionerNotRespondedItems from "@/components/tables/practitioners/PractitionerNotRespondedItems";

export default {
  name: "PractitionerSignupsToday",
  components: { Table, PractitionerNotRespondedItems },
  data: () => ({
   columns: [
      "Practitioner",
      "Client ID",
      "When",
      "Price",
      "Status",
      "Rescheduled",
      "Appointments/Capacity",
      "More",
    ],
  }),
  props: {
    appointments: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
