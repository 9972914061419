<template>
  <div class="">
    <div class="mb-5 grid grid-cols-2 sm:grid-cols-4 gap-4 items-center">
      <h3 class="col-span-1 sm:col-span-3 text-lg leading-6 font-medium text-gray-900">Terms & Condition</h3>
      <div class="text-right">
        <button type="button" class="btn-primary" @click.prevent="openForm(null)">
          <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Add Terms & Condition
        </button>
      </div>
    </div>
    <div class="">
      <Table :columns="columns">
        <TermsTableItems :terms="allTCList" @openEdit="openForm" @deleteContent="openDeleteModal" />
      </Table>
    </div>
    <TermsForm :open="openSlide" :activeQuery="activeQuery" @close="closeSlide"></TermsForm>

    <!-- <delete-content-modal :open="deleteModal" :content="content" @deleteContent="deleteContent" @close="deleteModal = false"></delete-content-modal>
      <content-form :open="openSlide" :activeQuery="activeQuery" @close="openSlide = false"></content-form> -->
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { ref, reactive, onMounted, computed } from 'vue';

import { PlusIcon } from '@heroicons/vue/outline';
import TermsTableItems from '@/components/tables/terms/TermsTableItems';
import TermsForm from './TermsForm';
import Table from '@/components/shared/Table';
//   import DeleteContentModal from './DeleteContentModal.vue';
//   import ContentForm from './ContentForm.vue'

const store = useStore();

const columns = ['ID', 'Text', 'Kind', 'Consent', 'Published', 'More'];
const deleteModal = ref(false);
const openSlide = ref(false);
const content = ref({});
// const activeQuery = reactive({});
const activeQuery = ref({});


const allTCList = computed(() => store.getters['user/allTCList']);

const fetchTCList = async () => {
  try {
    await store.dispatch('user/GetUserTcList');
  } catch (err) {
    console.log('err', err);
  }
};

const openDeleteModal = (data) => {
  deleteModal.value = true;
  content.value = data;
};

const openForm = (data) => {
  console.log('data', data);
  if (data) activeQuery.value = data;
  openSlide.value = true;
};

const closeSlide = () => {
	openSlide.value = false;
	activeQuery.value = {};
};

const deleteContent = async (data) => {
  try {
    await store.dispatch('content/DeleteLandingContent', data);
    await fetchTCList();
    deleteModal.value = false;
  } catch (err) {
    console.log('err', err);
  }
};

onMounted(async () => {
  await fetchTCList();
});
</script>
