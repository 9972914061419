<template>
  <div class="pdetails">
    <div class="pdetails__card">
      <!-- <div class="pdetails__card__head flex items-center justify-between p-5 bg-gray-50 border-b-2 border-gray-400">
        <h1 class="text-base font-medium">Practitioner Profile</h1> -->
      <!-- <SwitchGroup as="div" class="flex items-center">
          <SwitchLabel as="span" class="mr-3">
            <span class="text-sm font-medium text-gray-900">Practitioner</span>
          </SwitchLabel>
          <Switch v-model="enabled" :class="[enabled ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
            <span aria-hidden="true" :class="[enabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
          </Switch>
          <SwitchLabel as="span" class="ml-3">
            <span class="text-sm font-medium text-gray-900">Client</span>
          </SwitchLabel>
        </SwitchGroup> -->
      <!-- </div> -->

      <div class="pdetails__card__body bg-white p-5">
        <div class="flex justify-between">
          <div class="image overflow-hidden flex">
            <img class="h-20 w-20 object-cover rounded-full" :src="profile.photo ? profile.photo : require('@/assets/default-user.png')" alt="" />
            <div class="ml-5">
              <h1 class="text-gray-900 font-medium text-lg leading-8 mb-1">{{ profile.firstName }} {{ profile.lastName }}</h1>
              <div class="text-gray-400 text-sm capitalize mb-1">{{ profile.gender }}</div>
              <div class="text-gray-400 text-sm">{{ profile.birthDate }}</div>
            </div>
          </div>
          <SwitchGroup as="div" class="flex items-center" v-if="showSwitch">
            <SwitchLabel as="span" class="mr-3">
              <span class="text-sm font-medium text-gray-900">Practitioner</span>
            </SwitchLabel>
            <Switch v-model="enabled" :class="[enabled ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
              <span aria-hidden="true" :class="[enabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
            </Switch>
            <SwitchLabel as="span" class="ml-3">
              <span class="text-sm font-medium text-gray-900">Client</span>
            </SwitchLabel>
          </SwitchGroup>
        </div>

        <div class="about mt-5" v-if="profile.biography">
          <h1 class="text-medium font-medium pb-3">About</h1>
          <hr />
          <p class="text-sm text-gray-500 hover:text-gray-600 leading-6 mt-5" v-html="profile.biography"></p>
        </div>

        <div class="qual mt-5" v-if="(profile.practiceTypeList || []).length">
          <h1 class="text-medium font-medium pb-3">Practise Area</h1>
          <hr />
          <ul class="list-inside list-disc space-y-2 mt-5 col-count-2">
            <li v-for="(item, i) in profile.practiceTypeList" :key="i">{{ item }}</li>
          </ul>
        </div>
        <div class="qual mt-5" v-if="(profile.associationsList || []).length">
          <h1 class="text-medium font-medium pb-3">Assosiations</h1>
          <hr />
          <ul class="list-inside list-disc space-y-2 mt-5 col-count-2">
            <li v-for="(asso, i) in profile.associationsList" :key="i">
              {{ asso }}
            </li>
          </ul>
        </div>

        <div class="qual mt-5" v-if="(profile.qualificationsList || []).length">
          <h1 class="text-medium font-medium pb-3">Qualitification</h1>
          <hr />
          <ul class="list-inside list-disc space-y-2 mt-5 col-count-2">
            <li v-for="(qual, i) in profile.qualificationsList" :key="i">{{ qual.qualification }} ({{ qual.institution }})</li>
          </ul>
        </div>

        <div class="qual mt-5" v-if="(profile.languageList || []).length">
          <h1 class="text-medium font-medium pb-3">Languages</h1>
          <hr />
          <ul class="list-inside list-disc space-y-2 mt-5 col-count-2">
            <li v-for="(lang, i) in profile.languageList" :key="i">
              {{ lang }}
            </li>
          </ul>
        </div>

        <div class="qual mt-5" v-if="(profile.insuranceList || []).length">
          <h1 class="text-medium font-medium pb-3">Insurance</h1>
          <hr />
          <ul class="list-inside list-disc space-y-2 mt-5 col-count-2">
            <li v-for="(ins, i) in profile.insuranceList" :key="i" class="capitalize">
              {{ ins }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, watch, onMounted } from 'vue';
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';
import { useRoute } from 'vue-router';

const props = defineProps({
  profile: {
    type: Object,
    default: () => ({}),
  },
  showSwitch: {
    type: Boolean,
    default: false,
  }
});

const emit = defineEmits(['switch'])

const route = useRoute();
const enabled = ref(false);

const userProfile = computed(() => {
  console.log('props', props);
  return props.profile;
});
const userName = () => props.profile.firstName + ' ' + props.profile.lastName;

onMounted(() => {
  if (route.params.slug === 'client') enabled.value = true;
  else enabled.value = false;
});

watch(enabled, function (v) {
  emit('switch', v)
})
</script>

<style>
.col-count-2 {
  columns: 2;
}
</style>
