import router from '../router'
import store from '../store'
import {getToken} from './auth'

function hasPermission(roles, permissionRoles) {
  if (roles.indexOf('ADMIN') >= 0) return true
  if (!permissionRoles) return true
  return roles.some(role => permissionRoles.indexOf(role) >= 0)
}

router.beforeEach((to, from, next) => {
  if (getToken()) {
    if (['Login'].indexOf(to.name) !== -1) {
      next({path: '/'})
    } else {
      if (store.getters["user/roles"].length === 0) {
        store.dispatch('user/GetCurrentUser').then(() => {
          next()
        }).catch(() => {
          store.dispatch('FedLogOut').then(() => {
            next({path: '/'})
          })
        })
      } else {
        if (hasPermission(store.getters["user/roles"], to.meta.roles)) {
          next()
        } else {
          next({path: '/'})
        }
      }
    }
  } else {
    if (['Login'].indexOf(to.name) !== -1) {
      next()
    } else {
      next('/login')
    }
  }
})
