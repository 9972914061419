import {AuthPromiseClient as authPromiseClient} from '@mercury-labs1/augmentive-proto/auth.service_grpc_web_pb'
import {UsersPromiseClient as usersPromiseClient} from '@mercury-labs1/augmentive-proto/users.service_grpc_web_pb'
import {AdminsPromiseClient as adminsPromiseClient} from '@mercury-labs1/augmentive-proto/admins.service_grpc_web_pb'
import {AppointmentsPromiseClient as appointmentsPromiseClient} from '@mercury-labs1/augmentive-proto/appointments.service_grpc_web_pb'
import {PractitionersPromiseClient as practitionersPromiseClient} from '@mercury-labs1/augmentive-proto/practitioners.service_grpc_web_pb'
import { ClientsPromiseClient as clientsPromiseClient } from '@mercury-labs1/augmentive-proto/clients.service_grpc_web_pb'
import { ContentPromiseClient as contentPromiseClient } from '@mercury-labs1/augmentive-proto/content.service_grpc_web_pb'

import {getToken, setToken} from "@/utils/auth";
import {VueAppApiUrl as API_URL} from "../../config";

const AuthUnaryInterceptor = function () {
}
const StreamResponseInterceptor = function () {
}

StreamResponseInterceptor.prototype.intercept = function (request, invoker) {
  const metadata = request.getMetadata()
  let token = getToken()
  metadata.authorization = token ? token : ''
  metadata['x-account-type'] = "admin"

  return invoker(request)
}

AuthUnaryInterceptor.prototype.intercept = function (request, invoker) {
  const metadata = request.getMetadata()
  let token = getToken()
  metadata.authorization = token ? token : ''
  metadata['x-account-type'] = "admin"

  return invoker(request).then((response) => {
    const md = response.getMetadata()
    if (md.authorization && md.authorization !== metadata.authorization) {
      setToken(md.authorization)
    }
    return response
  })
}

const opts = {
  unaryInterceptors: [new AuthUnaryInterceptor()],
  streamInterceptors: [new StreamResponseInterceptor()]
}

export const AuthPromiseClient = new authPromiseClient(API_URL, null, opts)
export const UsersPromiseClient = new usersPromiseClient(API_URL, null, opts)
export const AdminsPromiseClient = new adminsPromiseClient(API_URL, null, opts)
export const AppointmentsPromiseClient = new appointmentsPromiseClient(API_URL, null, opts)
export const PractitionersPromiseClient = new practitionersPromiseClient(API_URL, null, opts)
export const ClientsPromiseClient = new clientsPromiseClient(API_URL, null, opts)
export const ContentPromiseClient = new contentPromiseClient(API_URL, null, opts)
