<template>
  <tbody class="bg-white divide-y divide-gray-200">
    <tr v-for="(item, i) in items" :key="i">
      <td v-if="item.practitioner" class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {{ item.practitioner.id }}
      </td>
      <td class="px-6 py-4 whitespace-nowrap">
        <a href="#" v-if="item.practitioner" @click.prevent="$router.push({ name: 'ShowPractitioner', params: { id: item.practitioner.id } })" class="flex items-center">
          <div class="flex-shrink-0 h-10 w-10 mr-4">
            <div class="h-10 w-10 rounded-full user-img-custom" :style="item.practitioner && item.practitioner.photoSm ? `background-image: url('${item.practitioner.photoSm}')` : ''"></div>
          </div>
          <div v-if="item.practitioner" @click="open(item)">
            <div class="text-sm font-medium text-gray-900">{{ item.practitioner.firstName }} {{ item.practitioner.lastName }}</div>
            <div v-if="item.user" class="text-sm text-gray-500" v-text="item.user.email"></div>
          </div>
          <div class="ml-4" v-else>-</div>
        </a>
      </td>
      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <button type="button" @click.prevent="approve(item.practitioner)" :class="[item.practitioner.verified ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']" role="switch" aria-checked="false">
          <span class="sr-only">Use setting</span>
          <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
          <span aria-hidden="true" :class="[item.practitioner.verified ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"></span>
        </button>
      </td>
      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <button type="button" @click.prevent="makePractitionerPsychiatrist(item.practitioner)" :class="[item.practitioner.isPsychiatrist ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']" role="switch" aria-checked="false">
          <span class="sr-only">Use setting</span>
          <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
          <span aria-hidden="true" :class="[item.practitioner.isPsychiatrist ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"></span>
        </button>
      </td>

      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <button type="button" @click.prevent="makePractitionerClinician(item.practitioner)" :class="[item.practitioner.isClinician ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']" role="switch" aria-checked="false">
          <span class="sr-only">Use setting</span>
          <span aria-hidden="true" :class="[item.practitioner.isClinician ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"></span>
        </button>
      </td>

      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <button type="button" @click.prevent="hidePractitioner(item.practitioner)" :class="[!item.practitioner.hidden ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']" role="switch" aria-checked="false">
          <span class="sr-only">Use setting</span>
          <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
          <span aria-hidden="true" :class="[!item.practitioner.hidden ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"></span>
        </button>
      </td>

      
      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <span v-if="item.practitioner.sembleAccessGroup">
          {{ item.practitioner.sembleAccessGroup }}
        </span>
        <span v-else>-</span>
      </td>
      <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
        <div class="" v-if="active !== i">
          <div class="flex items-center">
            <span v-if="item.recommendations">{{ item.recommendations }}</span>
            <span v-else>0</span>
            <PencilIcon class="w-5 ml-3 cursor-pointer" @click.prevent="showNumberInput(i)" />
          </div>
        </div>
        <div class="" v-else>
          <form action="#" class="relative flex items-center" @submit.prevent="postRecommendation(item)">
            <div class="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
              <input type="number" :value="item.recommendations" class="block w-full py-2 border-0 resize-none focus:ring-0 sm:text-sm" placeholder="Add your recomendation" :id="`practitioner-${item.practitioner.id}`" />
            </div>
            <div class="ml-2">
              <button type="submit" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Post</button>
            </div>
          </form>
        </div>
      </td>
      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <span v-if="item.practitioner.priceFrom">
          {{ item.practitioner.priceFrom }}
        </span>
        <span v-else>-</span>
      </td>
      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <span v-if="item.practitioner.timezone">
          {{ item.practitioner.timezone }}
        </span>
        <span v-else>-</span>
      </td>
      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <span v-if="item.practitioner.verified" class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800">
          {{ item.practitioner.verified }}
        </span>
        <span v-else class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-pink-100 text-pink-800"> No </span>
      </td>
      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <span v-if="item.practitioner.payoutsAvailable" class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800">
          {{ item.practitioner.payoutsAvailable }}
        </span>
        <span v-else class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-pink-100 text-pink-800"> No </span>
      </td>
      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <span v-if="item.practitioner.appointmentsList.length > 0" class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800">
          {{ item.practitioner.appointmentsList }}
        </span>
        <span v-else class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-pink-100 text-pink-800"> No </span>
      </td>
      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <span v-if="item.practitioner.servicesList.length > 0" class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800"> Live </span>
        <span v-else class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-pink-100 text-pink-800"> Not Set </span>
      </td>
      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <span v-if="item.practitioner.groupServicesList.length > 0" class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800"> Live </span>
        <span v-else class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-pink-100 text-pink-800"> Not Set </span>
      </td>
      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <span v-if="item.nextAvailable" class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800">
          {{ item.nextAvailable }}
        </span>
        <span v-else class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-pink-100 text-pink-800"> Not Set </span>
      </td>
      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <Menu as="div" class="relative inline-block text-right">
          <div>
            <MenuButton class="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
              <span class="sr-only">Open options</span>
              <DotsVerticalIcon class="h-5 w-5" aria-hidden="true" />
            </MenuButton>
          </div>

          <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a @click="copy_json(item.practitioner)" href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Copy as JSON</a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a @click="copy_profile(item.practitioner.slug)" href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Copy Profile Link</a>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </td>
    </tr>
  </tbody>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { approvePractitioner, updatePractitionerRole, makePractitionerHidden } from '@/api/practitioner';
import DateTimeFilterMixin from '@/mixins/DateTimeFilter';

import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';

import { DotsVerticalIcon, PencilIcon } from '@heroicons/vue/solid';

export default {
  name: 'PractitionersTableItems',
  mixins: [DateTimeFilterMixin],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    active: null,
  }),
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    DotsVerticalIcon,
    PencilIcon
  },
  methods: {
    ...mapActions({
      addRecommend: "practitioner/AddRecommendation",
    }),
    async copy_json(s) {
      const t = JSON.stringify(s);
      await navigator.clipboard.writeText(t);
    },
    async copy_profile(s) {
      const t = window.location.host + '/specialists/' + s;
      await navigator.clipboard.writeText(t);
    },
    async makePractitionerPsychiatrist(p) {
      p.isPsychiatrist = !p.isPsychiatrist;
      let req = {
        practitionerId: p.id,
        isPsychiatrist: p.isPsychiatrist,
        isClinician: p.isClinician,
      };

      updatePractitionerRole(req)
        .then((res) => {
          console.log(res);
          // this.$store.dispatch("app/validateError", res, { root: true })
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch('app/validateError', err, { root: true });
        });
    },

    async makePractitionerClinician(p) {
      p.isClinician = !p.isClinician;
      let req = {
        practitionerId: p.id,
        isClinician: p.isClinician,
        isPsychiatrist: p.isPsychiatrist,
      };

      updatePractitionerRole(req)
        .then((res) => {
          console.log(res);
          // this.$store.dispatch("app/validateError", res, { root: true })
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch('app/validateError', err, { root: true });
        });
    },

    async hidePractitioner(p) {
      p.hidden = !p.hidden;
      let req = {
        practitionerId: p.id,
        hidden: p.hidden,
      };

      makePractitionerHidden(req)
        .then((res) => {
          console.log(res);
          // this.$store.dispatch("app/validateError", res, { root: true })
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch('app/validateError', err, { root: true });
        });
    },
    async approve(p) {
      console.log(p);
      p.verified = !p.verified;
      let req = {
        practitionerId: p.id,
        decision: p.verified,
      };

      approvePractitioner(req)
        .then((res) => {
          console.log(res);
          // this.$store.dispatch("app/validateError", res, { root: true })
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch('app/validateError', err, { root: true });
        });
    },

    open(item) {
      this.$emit('openSlider', item);
      console.log('Working');
    },
    showNumberInput(index) {
      this.active = index;
    },

    async postRecommendation(pract) {
      try {
        let practInputValue = document.getElementById(`practitioner-${pract.practitioner.id}`).value;
        let req = {
          practitionerId: pract.practitioner.id,
          amount: parseInt(practInputValue) - parseInt(pract.recommendations)
        };
        console.log("🚀 ~ file: PractitionersTableItems.vue:252 ~ postRecommendation ~ req:", req)
        await this.addRecommend(req)
        await this.$emit('getPractitioners');
        this.active = null;
      } catch (err) {
        this.$store.dispatch('app/validateError', err, { root: true });
      }
    }
  },
  mounted() {
    console.log('this.items', this.items);
  },
};
</script>
