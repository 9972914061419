<template>
  <div class="flex flex-col mt-0 sm:px-8">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <practitioners-table-header></practitioners-table-header>
            <practitioners-table-items :items="practitioners"></practitioners-table-items>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import PractitionersTableHeader from "@/components/tables/ranking/PractitionersTableHeader";
import PractitionersTableItems from "@/components/tables/ranking/PractitionersTableItems";

export default {
  name: "PractitionersTable",
  components: {PractitionersTableItems, PractitionersTableHeader},
  props: {
    practitioners: {
      type: Array,
      default: function() {
        return []
      }
    },
  },
  methods: {}
}
</script>
