import { AdminsPromiseClient } from '@/plugins/grpc';
import { StatisticsRequest, InvitationListRequest, UpdateClientInfoRequest, UsersSearchRequest, AddAnonymousRecommendationsRequest, ImpersonateRequest, FullUserDeleteRequest, MarkAppointmentAsPaidRequest, CreateTCRequest, GetTCListRequest, UpdateTCRequest } from '@mercury-labs1/augmentive-proto/admins.messages_pb';
import { preparePagination } from '@/utils/preparePagination';
import { TC } from "@mercury-labs1/augmentive-proto/tc_pb";

export async function getStatistic(payload) {
  let req = new StatisticsRequest();
  console.log('StatisticsRequest: ', payload);

  return await AdminsPromiseClient.statistics(req, {}).then((res) => {
    res = res.toObject();
    console.log('StatisticsResponse: ', res);
    return Promise.resolve(res);
  });
}

export async function getInviteList(payload) {
  let req = new InvitationListRequest();
  req.setGroupByPractitioner(payload.groupBy);

  return await AdminsPromiseClient.invitationList(req, {}).then((res) => {
    res = res.toObject();
    console.log('--------------------------: ', res);
    return Promise.resolve(res);
  });
}

export async function addAnonymousRecomendation(payload) {
  let req = new AddAnonymousRecommendationsRequest();
  console.log('req', payload)
  req.setPractitionerId(payload.practitionerId);
  req.setAmount(payload.amount);

  return await AdminsPromiseClient.addAnonymousRecommendations(req, {}).then((res) => {
    res = res.toObject();
    console.log('--------------------------: ', res);
    return Promise.resolve(res);
  });
}

export async function updateClientInfo(payload) {
  let req = new UpdateClientInfoRequest();
  console.log('req', req);
  console.log('payload', payload);

  if (payload.id) req.setClientId(payload.id);
  if (payload.publicNotes) req.setPublicNotes(payload.publicNotes);
  if (payload.receivedCallback) req.setReceivedCallback(payload.receivedCallback);

  return await AdminsPromiseClient.updateClientInfo(req, {}).then((res) => {
    res = res.toObject();
    console.log('UpdateClientRes: ', res);
    return Promise.resolve(res);
  });
}

export async function getUserList(payload) {
  console.log("🚀 ~ getUserList ~ payload:", payload)
  let req = new UsersSearchRequest();
  console.log('req', req);
  let pagination = preparePagination(payload);

  if (pagination) {
    req.setPagination(pagination);
  }

  if (payload.query) req.setQuery(payload.query);

  if (payload.type) {
    switch (payload.type) {
      case 'all':
        req.setType(0);
        break;
      case 'practitioner':
        req.setType(2);
        break;
      case 'client':
        req.setType(1);
        break;
      case 'admin':
        req.setType(3);
        break;
      default:
        return;
    }
  }

  if (payload.sort) {
    switch (payload.sort) {
      case 'desc':
        req.setOrderSort(1);
        break;
      default:
        req.setOrderSort(0);
    }
  }

  if (payload.orderBy) {
    switch (payload.orderBy) {
      case 'id':
        req.setOrderBy(0);
        break;
      case 'created_at':
        req.setOrderBy(1);
        break;
      default:
        return
    }
  }
  // req.setGroupByPractitioner(payload.groupBy)

  return await AdminsPromiseClient.usersSearch(req, {}).then((res) => {
    res = res.toObject();
    console.log('--------------------------: ', res);
    return Promise.resolve(res);
  });
}


export async function getImpersonateToken(payload) {
  let req = new ImpersonateRequest();
  req.setUserId(payload);

  return await AdminsPromiseClient.impersonate(req, {}).then((res) => {
    res = res.toObject();
    return Promise.resolve(res);
  });
}


export async function deleteFullUser(payload) {
  let req = new FullUserDeleteRequest();
  req.setUserId(payload);

  return await AdminsPromiseClient.fullUserDelete(req, {}).then((res) => {
    res = res.toObject();
    return Promise.resolve(res);
  });
}


export async function createTC(payload) {
  console.log("🚀 ~ createTC ~ payload:", payload)
  let req = new CreateTCRequest();
  console.log("🚀 ~ createTC ~ req:", req)
  req.setKind(payload.kind);
  req.setText(payload.text);

  return await AdminsPromiseClient.createTC(req, {}).then((res) => {
    res = res.toObject();
    return Promise.resolve(res);
  }).catch((err) => {
    console.log("🚀 ~ returnawaitAdminsPromiseClient.createTC ~ err:", err)
    return Promise.reject(err)
  });
}

export async function updateTC(payload) {
  console.log("🚀 ~ updateTC ~ payload:", payload)
  let req = new UpdateTCRequest();
  const tc = new TC();
  tc.setId(payload.id);
  tc.setKind(payload.kind);
  tc.setText(payload.text);
  tc.setPublished(payload.published);

  req.setTc(tc);

  return await AdminsPromiseClient.updateTC(req, {}).then((res) => {
    res = res.toObject();
    return Promise.resolve(res);
  }).catch((err) => {
    console.log("🚀 ~ returnawaitAdminsPromiseClient.createTC ~ err:", err)
    return Promise.reject(err)
  });
}

export async function getTC(payload) {
  console.log("🚀 ~ getTC ~ payload:", payload)
  let req = new GetTCListRequest();
  return await AdminsPromiseClient.getTCList(req, {}).then((res) => {
    console.log("🚀 ~ returnawaitAdminsPromiseClient.getTCList ~ res:", res)
    res = res.toObject();
    return Promise.resolve(res);
  });
}


export async function markAppointmentPaid(payload) {
  console.log("🚀 ~ file: admin.js:141 ~ markAppointmentPaid ~ payload:", payload)
  let req = new MarkAppointmentAsPaidRequest();
  console.log("🚀 ~ file: admin.js:133 ~ deleteFullUser ~ req", req)
  req.setAppointmentId(payload);

  return await AdminsPromiseClient.markAppointmentAsPaid(req, {}).then((res) => {
    res = res.toObject();
    return Promise.resolve(res);
  });
}
