<template>
  <div class="pb-5">
    <h3 class="text-lg leading-6 font-medium text-gray-900 mb-5">
      Practitioners
    </h3>
    <div class="pb-5 sm:mt-0">
      <nav class="-mb-px flex space-x-8">
        <a
          v-for="tab in filters"
          :key="tab.name"
          @click.prevent="activeTab = tab.slug"
          :class="[
            activeTab === tab.slug
              ? 'border-indigo-500 text-indigo-600'
              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
            'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm',
          ]"
          :aria-current="tab.current ? 'page' : undefined"
        >
          {{ tab.name }}
        </a>
      </nav>
    </div>
    <div class="pb-5">
      <div class="pb-5 sm:mt-0 max-w-sm">
        <label for="search" class="block text-sm font-medium text-gray-700"
          >Quick search</label
        >
        <div class="mt-1 relative flex items-center">
          <input
            v-model=search_term
            @keyup.enter="performSearch"
            type="text"
            name="search"
            id="search"
            class="
              shadow-sm
              focus:ring-indigo-500
              focus:border-indigo-500
              block
              w-full
              pr-12
              sm:text-sm
              border-gray-300
              rounded-md
            "
          />
          <div class="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
            <kbd
              class="
                inline-flex
                items-center
                border border-gray-200
                rounded
                px-2
                text-sm
                font-sans font-medium
                text-gray-400
              "
            >
              ⌘K
            </kbd>
          </div>
        </div>
      </div>
    </div>
    <practitioners-table
      :practitioners="practitioners.practitionersList"
      @getPractitioners="getPractitioners"
      @to-page="changePageTo"
      @openSlider="openSlider"
    ></practitioners-table>

    <practitioners-slide
      :open="openProfile"
      :practitioner="singlePractitioner"
      :user="user"
      :upcoming="appointments"
      @close="closeSlider"
      @openModal="openModal"
      @openDeleteModal="openDeleteModal"
      @openApprove="openApprove"
      @openRemoveAdmin="openRemoveAdminModal"
      @openSembleModal="() => {
        sembleModal = true;
        closeSlider();
      }"
    >
    </practitioners-slide>

    <practitioner-modal
      :modalShow="modalShow"
      :practitioner="singlePractitioner"
      @closeModal="closeModal"
      @savePhoto="saveProfilePhoto"
      @save="saveProfile"
    ></practitioner-modal>

    <practitioner-semble-modal
      :modalShow="sembleModal"
      :practitioner="singlePractitioner"
      @closeModal="sembleModal = false"
      @saveData="updatePractitionerInfo"
    ></practitioner-semble-modal>

    <PractionerMakeAdminModal
      :modalApprove="modalApprove"
      :user="user"
      @getPractitioners="getPractitioners"
      @close="closeApprove"
    ></PractionerMakeAdminModal>

    <PractitionerDeleteAdminModal
      :modalRemove="modalRemove"
      :user="user"
      @getPractitioners="getPractitioners"
      @close="closeRemove"
    ></PractitionerDeleteAdminModal>
    <UserDeleteModal
      :open="userDeleteModal"
      :user="user"
      @getPractitioners="getPractitioners"
      @close="closeDeleteModal"
    ></UserDeleteModal>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import moment from "moment";
import { getPractitionerList, getPractitionerByID, setPractitionerProfile, updatePractitionerRole } from "@/api/practitioner";
import {getAppointmentsList} from "@/api/appointment";
import PractitionersTable from "@/components/tables/practitioners/PractitionersTable";
import PractitionersSlide from "@/components/pages/practitioners/PractitionersSlide";
import PractitionerModal from '@/components/pages/practitioners/PractitionerModal';
import UserDeleteModal from '@/components/pages/users/UserDeleteModal';
import PractionerMakeAdminModal from '@/components/pages/practitioners/PractionerMakeAdminModal';
import PractitionerDeleteAdminModal from '@/components/pages/practitioners/PractitionerDeleteAdminModal';
import PractitionerSembleModal from './PractitionerSembleModal.vue';

const filters = [
  {
    name: "All",
    slug: "all",
    params: {
      show_all: true,
    },
  },
  {
    name: "New",
    slug: "new",
    params: {
      createdAt: moment().format(moment.HTML5_FMT.DATE),
      show_all: true,
    },
  }
];

export default {
  name: "PractitionersList",
  components: {
    PractitionersTable,
    PractitionersSlide,
    PractitionerModal,
    PractionerMakeAdminModal,
    PractitionerDeleteAdminModal,
    UserDeleteModal,
    PractitionerSembleModal
  },
  setup() {
    const modalShow = ref(false)
    const userDeleteModal = ref(false)
    const user = ref([])
    const modalApprove = ref(false)
    const modalRemove = ref(false)
    const sembleModal = ref(false)
    return {
      filters,
      modalShow,
      user,
      modalApprove,
      modalRemove,
      sembleModal,
    };
  },
  data: () => {
    return {
      page: 1,
      activeTab: "all",
      practitioners: [],
      search_term: "",
      openProfile: false,
      singlePractitioner: null,
      appointmentFilter: {
        page: 1,
        category: 'upcoming',
        practitionerId: '',
        orderBy: 'start_time',
        orderSort: 'asc',
      },
      appointments: [],
      practitioner: null,

    };
  },
  provide() {
    return {
      currentPage: computed(() => this.page),
      total: computed(() => this.practitioners.total ? this.practitioners.total : 10),
    };
  },
  mounted() {
    this.getPractitioners();
    console.log(moment.HTML5_FMT.DATE)
  },
  methods: {
    getPractitioners() {
      console.log('Running')
      for (let i = 0; i < this.filters.length; i++) {
        if (this.filters[i].slug === this.activeTab) {
          this.filters[i].params.page = this.page;
          this.filters[i].params.searchFilter = this.search_term;
          getPractitionerList(this.filters[i].params)
            .then((res) => {
              this.practitioners = res;
            })
            .catch(() => {
              this.practitioners = [];
            });
        }
      }
    },
    openSlider(data) {
      this.openProfile = true
      console.log(data.practitioner)
      this.singlePractitioner = data.practitioner
      this.user = data.user
      this.appointmentFilter.practitionerId = data.practitioner.id
      console.log(data)
      getAppointmentsList(this.appointmentFilter)
        .then((res) => {
          this.appointments = res.appointmentsList
          console.log(this.appointments)
        })
        .catch(() => {
          this.appointments = []
        })
    },
    closeSlider() {
      this.openProfile = false
      this.$router.push('/practitioners')
      this.appointments = []
    },
    performSearch() {
      console.log(this.search_term)
      this.getPractitioners();
    },
    changePageTo(page) {
      this.page = page;
      this.getPractitioners();
    },
    openModal() {
      // console.log(data)
      this.modalShow = true
      this.closeSlider()
      getPractitionerByID(this.singlePractitioner)
        .then(res => {
          this.practitioner = res
          console.log(this.practitioner)
        })


    },
    closeModal() {
      this.modalShow = false
      this.getPractitioners();
    },


    openDeleteModal(data) {
      this.userDeleteModal = true
      this.closeSlider()
      getPractitionerByID(this.singlePractitioner)
        .then(res => {
          this.practitioner = res
        })


    },

    updatePractitionerInfo(data, p) {
      let req = {
        practitionerId: p.id,
        isClinician: p.isClinician,
        isPsychiatrist: p.isPsychiatrist,
        ...data
      }

      updatePractitionerRole(req)
        .then(res => {
          console.log(res)
          this.getPractitioners();
          this.sembleModal = false
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch('app/validateError', err, { root: true });
        });

    },

    closeDeleteModal() {
      this.getPractitioners();
      this.userDeleteModal = false
      //
    },

    saveProfilePhoto(data) {
      this.singlePractitioner.photo = data
    },

    async saveProfile(data) {
      console.log('data', data)
      await setPractitionerProfile(data)
        .then(res => {
          console.log(res)
        })

      this.closeModal()
    },

    openApprove(data) {
      console.log(data)
      this.modalApprove = true
      this.closeSlider()
    },

    closeApprove() {
      this.modalApprove = false
    },
    openRemoveAdminModal() {
      this.modalRemove = true
      this.closeSlider()
    },
    closeRemove() {
      this.modalRemove = false
    },
  },
  watch: {
    activeTab: function () {
      this.page = 1;
      this.getPractitioners();
    },
  },
};
</script>
