<template>
  <div class="pb-5">
    <h3 class="text-lg leading-6 font-medium text-gray-900 mb-5">Practitioner Ranking</h3>
    <practitioners-table
      :practitioners="practitionersList"
    ></practitioners-table>
  </div>
</template>

<script>
import {getPractitionerList} from "@/api/practitioner";
import PractitionersTable from "@/components/tables/ranking/PractitionersTable";

export default {
  name: "PractitionerRanking",
  components: {PractitionersTable},
  data: () => {
    return {
      searchQuery: "",
      practitioners: [],
      total: 0,
    }
  },
  mounted() {
    this.getPractitioners()
  },
  methods: {
    getPractitioners() {
      let req = {
        // searchFilter: this.searchQuery,
        perPage: -1,
      }

      getPractitionerList(req).then((res) => {
        console.log("getPractitionerList: ", res)
        this.practitioners = res
        this.total = res.total
      })
    },
  },
  computed: {
    practitionersList: function () {
      let result = {}

      result = this.practitioners.practitionersList

      if(result) {
        result.sort((a, b) => {
          return a.practitioner.id - b.practitioner.id
        })

        result.sort((a, b) => {
          if (a.practitioner.sortIdx == 0 && b.practitioner.sortIdx != 0) {
            return true
          }
          return a.practitioner.sortIdx - b.practitioner.sortIdx
        })
      }

      // Sort logic by customOrder of practitioner object

      return result
    }
  },
}
</script>
