<template>
  <tbody class="bg-white divide-y divide-gray-200">
    <template v-if="(content || []).length">
      <tr v-for="(item, i) in content" :key="i">
        <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
          {{ item.id }}
        </td>
        <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
          {{ item.title }}
        </td>
        <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
          {{ item.category }}
        </td>
        <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
          {{ item.keyword ? item.keyword : 'No Data' }}
        </td>
        <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 flex">
          <PencilAltIcon class="w-5 mr-4 cursor-pointer text-indigo-600" @click="openFormEdit(item)" />
          <TrashIcon class="w-5 cursor-pointer text-primary text-indigo-600" @click="deleteLandingPage(item)" />
        </td>
      </tr>
    </template>
    <template v-else>
      <tr>
        <td colspan="5" class="px-6 py-2 whitespace-nowrap">
          <div class="h-48 flex flex-col justify-center items-center py-8">
            <InboxIcon class="w-12 text-gray-400" />
            <p class="text-gray-400 mt-3">Sorry there are currently no data in the table.</p>
          </div>
        </td>
      </tr>
    </template>
  </tbody>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

import { PencilAltIcon, TrashIcon, InboxIcon  } from '@heroicons/vue/outline';

defineProps({
  content: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['openEdit', 'deleteContent']);

const deleteLandingPage = (data) => {
  emit('deleteContent', data);
};

const openFormEdit = (data) => {
  emit('openEdit', {
    name: data.name,
    category: data.category
  })
}
</script>

<style></style>
