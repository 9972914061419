<template>
  <div class="pb-5">
    <h3 class="text-lg leading-6 font-medium text-gray-900 mb-5">Client Registrations</h3>
    <Table :columns="columns">
      <clients-table-items :clients="clients"></clients-table-items>
    </Table>
  </div>
</template>

<script>
// import ClientsTable from "@/components/tables/clients/ClientsTable";
import Table from '@/components/shared/Table';
import ClientsTableItems from '@/components/tables/clients/ClientsTableItems';

export default {
  name: 'PractitionerSignupsToday',
  components: { Table, ClientsTableItems },
  props: {
    clients: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    columns: ['Client Initials', 'Client ID', 'Invitation Id', 'Received callback', 'Public Notes', 'More'],
  }),
};
</script>
