import {Pagination as PaginationRequest} from "@mercury-labs1/augmentive-proto/pagination_pb";

const defaultPerPage = 10

export function preparePagination({page, perPage}) {
  let pagination = new PaginationRequest();
  pagination.setPerPage(perPage ? perPage : defaultPerPage)
  if (page) {
    pagination.setPage(page)
  }

  return pagination
}
