<template>
  <div class="px-4 my-6 sm:px-6 lg:px-8">
    <h1 class="text-lg leading-6 font-medium text-gray-900 mb-5">Profile</h1>
    <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8">
      <div class="col-span-1 xl:col-span-2">
        <!-- <practitioner-details :profile="profile"></practitioner-details> -->
        <user-profile-details :profile="profile" :showSwitch="showSwitch" @switch="switchAccount" v-if="profile" />
      </div>
      <div class="">
        <user-activity></user-activity>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { ref, computed, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import UserProfileDetails from './UserProfileDetails.vue';
import UserActivity from './UserActivity.vue';

const store = useStore();
const route = useRoute();
const router = useRouter();

const practProfile = computed(() => store.getters['user/userPractitioner']);
const clientProfile = computed(() => store.getters['user/userClients']);
const showSwitch = computed(() => practProfile.value && clientProfile.value ? true : false);
const profile = ref({})
const setProfile = () => {
  console.log('practProfile', practProfile.value)
  if(practProfile.value) {
    profile.value = practProfile.value;
  }else if(clientProfile.value) {
    profile.value = clientProfile.value;
  }else {
    profile.value = practProfile.value;
  }
};

const switchAccount = (data) => {
  profile.value = data ? clientProfile.value : practProfile.value
}

const fetchUser = async () => {
  try {
    let res = await store.dispatch('user/GetUserById', { id: +route.params.id });
  } catch (error) {
    console.log(error);
  }
};


onMounted(async () => {
  await fetchUser();
  setProfile()

});
</script>

<style></style>
