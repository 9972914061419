<template>
  <div class="flex flex-col mt-0 sm:px-8">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <group-services-table-header></group-services-table-header>
            <group-services-table-items :items="services" @open="open"></group-services-table-items>
          </table>
        </div>
      </div>
    </div>
    <table-pagination @to-page="changePageTo"></table-pagination>
  </div>
</template>

<script>
import GroupServicesTableHeader from "@/components/tables/group-services/GroupServicesTableHeader";
import GroupServicesTableItems from "@/components/tables/group-services/GroupServicesTableItems";
import TablePagination from "@/components/tables/TablePagination";

export default {
  name: "GroupServicesTable",
  components: {TablePagination, GroupServicesTableItems, GroupServicesTableHeader},
  emits: ["to-page", "openSlide"],
  props: {
    services: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    changePageTo(page) {
      this.$emit("to-page", page)
    },

    open(data) {
      this.$emit('openSlide', data)
    }
  }
}
</script>
